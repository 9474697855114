import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./ScratchProgramming.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import pic1 from './CodingCourseImages/scratch.png'
import pic2 from './CodingCourseImages/pingpong.png'
import pic3 from './CodingCourseImages/snakeGame.png'
import pic4 from './CodingCourseImages/eidCard.png'
import pic5 from './CodingCourseImages/fishGame.png'
import pic6 from './CodingCourseImages/JumpingGame.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import ScrP from './CodingCourseImages/scratchDetailBackground.png'

const ScratchProgramming = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
  return (
    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
  
       <div className="ScratchProg-Background">
          <section className="section">
            <p className="PageHead"style={{ color: "#8b0194" }}>Scratch Programming Course</p>
            <div className="PageDesc">
            <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
              Polymath-kids offer comprehensive online Scratch programming course, a block-based programming language for kids. The online coding classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.
              </p>
              <img style={{minWidth:"250px", maxWidth:"450px"}} src={ScrP}></img>
              </div>
              <div className="btn-row d-flex btn-row-resp btn-row-resp1">

                {/* <div className="btn-row-resp"> */}
                <div style={{ width: "auto", height: "50px" }}>


                  <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
                </div>
               
                <div style={{ width: "auto", height: "50px" }}>
                  <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

                </div>
                <div style={{ width: "auto", height: "50px" }}>

                  <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
                </div>
                <div style={{ width: "auto", height: "50px" }}>
                  <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


                </div>
                <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                 

                </div>


           
              </div>
            </div>

          </section>
          <div className="overlay"></div>
        </div>

          {/* Intro text */}
        <div className='scratch-background'>
        <section className="section">
            <p className="PageHead" style={{ color: "#8b0194" }}>What is Scratch Programming?</p>
            <div className="PageDesc">
              <p style={{
                  color: "#8b0194",
                  marginTop: "2vh",
                  fontSize: "4vh",
                  fontFamily: "sans serif",
                }}>
              Scratch is a block-based programming language with a simple visual interface designed primarily for children. It makes learning fun as It’s easy to use interface allows kids to easily code personalize digital stories, games, and animations. It also allows them to share their creations in the online community. Scratch coding courses for kids make learning fun and interactive.
<br />
<br />
Polymath-Kids Scratch programming for beginners, facilitate kids to learn concepts of variables, loops, if-else statements, conditions, broadcasting, game development, moving and flying animation and more. Polymath-Kids provide a personalized portal for each student to see their progress along the course, with opportunity to reinforce the learned concepts through fun and engaging puzzles, quizzes and videos.
<br />
<br />
Writing codes can be a cumbersome activity for young kids. Scratch provides an alternative to writing code; kids can easily learn how to code through creating, dragging, and dropping colorful command blocks. This colorful visual approach enables children to quickly grasp the essence of programming and create personalized projects, games and short movies. 
<br />
<br />
Coding is an essential part of digital transformation. Scratch programming provides the basics of coding for kids that supports all coding languages. Through Scratch coding kids can develop a variety of skills such as logic, structure, creative thinking, self-expression, problem solving, sequencing and analytical thinking. These skills will support them on their coding journey beyond Scratch. Being able to build real code using coding chunks also provides a sense of achievement as students can see their stories in action. 
<br />

              </p>    
            </div>
          </section>
        </div>
        
        {/* Course Structure and Syllabus */}
        <section className="section course-structure" id="course">
          <p className="PageHead" style={{ color: "#8b0194" }}>
            Course Structure and Syllabus
          </p>
          <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
              <b>
                Course Objectives
              </b>
          </h4>
          <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
            <li>Learn the foundation of Computer Science using Scratch Programming</li>
            <li>Develop strong foundation of coding to create engaging games, animation and apps </li>
            <li>Develop 21st Century Skills using Block based Programming: <br /></li>
              <p>
              {'> '}Logic application and self-expression <br />
              {'> '}Creativity and Problem-Solving Skills <br />
              {'> '}Mathematical, Computational and Analytical Thinking <br />
              {'> '}Persistence <br />
              {'> '}Creativity and Imagination <br />
              {'> '}Digital Literacy <br />
              {'> '}Career-Building Skills <br />
              {'> '}Confidence and Communication Skills <br />
              </p>
          </ul>
          
          <div>
            <p className='moduleSection' >
                <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
                Scratch Beginner Course
            </p>
          </div>

          {/* Module one */}
          <div className="modules">
            {/* round box */}
            <div className="round-box">
              <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box" style={{ color: "#249935" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  11
                </p>
              </p>

              <div
                className="small-box sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box" style={{ color: "#249935" }}>
              <p style={{ margin: "auto" }}>
              Animation Fundamentals
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Younger students can utilize their ideas, creativity, and excitement in this course to construct their own coding applications and games as a fun way to learn. In this module Student work with Scratch Jr Animation, Loops etc.
                </p>
              </p>
            </div>
          </div>

          {/* Module  two */}
          <div className="modules" style={{ marginTop: "2vh" }}>
            {/* round box */}
            <div className="round-box">
              <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box" style={{ color: "#249935" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  11
                </p>
              </p>

              <div
                className="small-box sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box" style={{ color: "#249935" }}>
              <p className="my-4" style={{ margin: "auto" }}>
              Scratch Programming Interface
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  
In Scratch Programing Interface student will interact with interface of the M-Block, Its Text Animations, Background Creation and have fun  creating cartoon characters.
                </p>
               
              </p>
            </div>
          </div>

          {/* Module  Three */}
          <div className="modules" style={{ marginTop: "2vh" }}>
            {/* round box */}
            <div className="round-box">
              <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box" style={{ color: "#249935" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  11
                </p>
              </p>

              <div
                className="small-box sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box" style={{ color: "#249935" }}>
              <p className="my-4" style={{ margin: "auto" }}>
              Animation Programming
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  This module is completely based on animation. Student will create interesting stories, learn the concept of moving objects, and create different interesting Animations.
                </p>
               
              </p>
            </div>
          </div>

          {/* Module one */}
          <div>
            <p className='moduleSection' style={{color: "#485cd9",}}>
                <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
                Scratch Advance Course
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  11
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              Game Designing
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Students will design Interesting Games using concepts of Conditions, Loops, sensing colors, sensing objects and concept of clone. 
                </p>
              </p>
            </div>
          </div>

          {/* Module two */}
          <div className="modules my-3">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  8
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    5
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              AI Fundamentals
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Students will be introduced with the fundamentals of Machine learning for kids’ platform. They will also learn the concepts of training, testing, loops and conditions in Artificial Intelligence.
                </p>
              </p>
            </div>
          </div>

          {/* Module three */}
          <div className="modules my-3">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  8
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    5
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              AI Advance
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Students will work on the advance concepts of Machine Learning and will learn how to add extensions in Machine Learning platform
                </p>
              </p>
            </div>
          </div>

          <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
              <b>
              Learn Scratch Programming with 60+ Fun Activities
              </b>
          </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
                <img src={pic2} alt="" className='ActivityImages'/>
                <p className='mx-3'>Ping Pong Game</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages'/>
              <p className='mx-3'>Snake Game</p>
            </div>
            <div class="col-lg mx-3">   
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className='mx-3'>Eid Card Game</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className='mx-3'>Fish Game</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic6} alt="" className='ActivityImages'/>
              <p className='mx-3'>Jumping Game</p>
            </div>
            
          </div>
        </div>
       
       

        </section>

        {/* Fee Structure */}
        <FeeStructure subject="Coding"/>

        {/* Testimonals */}
        <div class="  background" id="testimonal">
          <div class="row" style={{ width: "100%" }}>
            <div class="col-lg-8 mx-auto">
              <h2 className="Testimonal_Heading">Testimonials</h2>
              <div id="myCarousel" class="carousel slide" data-ride="carousel">
                {/* <!-- Carousel indicators --> */}
                <ol class="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                </ol>
                {/* <!-- Wrapper for carousel items --> */}
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="img-box">
                      <div className=" img-box1"></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I enjoyed the learning experience with EduTechSage-Abacus.
                      The teacher was very friendly. I highly recommend
                      EduTechSage-Abacus.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Mujtaba Khawaja Sahaf</b>
                    </p>
                  </div>

                  <div class="carousel-item">
                    <div class="img-box">
                      <div className="img-box2 "></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I feel so proud when my class fellows call me ’BRAINIAC ’,
                      because I can effortlessly do mental calculations much
                      faster and accurate than my class mates. Thank you
                      Polymath-Kids.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Wahaab Omer</b>, Age 9 Years
                    </p>
                  </div>
                </div>
                {/* <!-- Carousel controls --> */}
                <a
                  class="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i class="fa fa-angle-left"></i>
                </a>
                <a
                  class="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Faqs */}
        <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

          <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
            <MDBAccordion alwaysOpen initialActive={1} >
              <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
              <MDBAccordionItem collapseId={1} headerTitle="Q: What is Scratch programming?" >
                <strong>A: </strong>
                Scratch is a visual block-based programming language designed to facilitate kids. It’s simple and visual programming interface enable kids to learn to code interactive stories, games, and animations and share their masterpieces through an online community.
              </MDBAccordionItem>
              <MDBAccordionItem collapseId={2} headerTitle="Q: What is Scratch programming used for?">
                <strong>A: </strong>
                Scratch programming is used to create interactive stories, games, animations and apps. Students can create engaging and personalized content by dragging and dropping code blocks. They can also share it with the community.
              </MDBAccordionItem>


              <MDBAccordionItem collapseId={3} headerTitle="Q: Is Scratch programming easy for kids to learn?">
                <strong>A: </strong>
                Scratch uses a colorful and easy to understand interface where kids use the drag and drop method to perform actions and create projects such as games, apps and animations quickly and easily. This approach makes Scratch programming courses for kids easy to learn, engaging and productive.
                Polymath-Kids offer Scratch programming course for kids, with 6 modules (Beginner and Advance) especially designed to help develop not only the coding skills in kids but also assist students advance with logic, creative thinking, storytelling and problem solving approach.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Is Scratch an effective programming language for kids?">
                <strong>A: </strong>
                Of course it is! Scratch is one of the best and easy to learn programming languages which assists in developing not only the coding skills but also help develop fundamental logic skills. It encourages kids to apply logic to their imagination and create ingenious projects.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Why should kids learn Scratch programming?">
                <strong>A: </strong>
                Scratch provides an opportunity for kids to learn the fundamentals of coding in a fun and engaging learning environment. Coding is an integral part of digital transformation. With the exponential growth of digital products and services, the jobs requiring coding skills also continue to boom.  
                Scratch is one of the best platforms for kids to get started to learn to code. It encourages students to think creatively and develop intelligent reasoning behavior. It's easy to use interface allows kids to create animations, apps, and games. Polymath-Kids provide an excellent approach to learn Scratch programming under the supervision of trained teachers.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Is Scratch the best way for kids to learn to code?">
                <strong>A: </strong>
                Yes! Scratch is one of the best ways for kids to learn to code. It involves dragging and dropping elements instead of writing code which help children learn coding logic in an effective and easy manner.
                <br />
                Scratch offers a colorful interface which provides visual clues through predefined blocks of code, which help in engaging kids. Kids can learn the concepts such as loops, conditions, commands, controls and much more. 
                <br />
                Polymath-Kids offer the Scratch Coding Course in an exclusive manner where kids can practice the learned concepts in a variety of ways by using the Technology Portal which helps them reinforce the concepts and get hands on approach to creativity, logic and problem solving.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What are some research proven benefits of learning programming for kids?">
                <strong>A: </strong>
                Learning programming from an early age can help children grasp the foundation of coding concepts along with learning important 21st Century Skills such as problem-solving, Communication, logic building, project designing, design thinking, and much more. Polymath-Kids' Scratch Programming Course facilitate kids by learning the core coding and programming skills as well as the associated life skills. 
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What are the learning goals of the Scratch programming course for kids?">
                <strong>A: </strong>
                Polymath-Kids offer Scratch Programming Course for Ages 6 to 14, in two different streams - Scratch Beginner and Scratch Advance Course. By the end of the course, younger students would have mastered the essential principles of coding such as conditions, loops, sensing colors, sensing objects, using the concept of clone, sprites, animation and coordinates with the help of several activities and Projects. In addition to these concepts, the older students will also learn the concepts related to Machine Learning.  
                The course design facilitates the young brains by learning coding along with other important skills such as problem solving, logic, creativity and storytelling. 
              </MDBAccordionItem>

           
            </MDBAccordion>
          </MDBContainer>

        </div>

{/* Footer */}
<Footer/>

    </div>
  )
}

export default ScratchProgramming
