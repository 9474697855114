import React, { useState, useContext, useEffect } from "react";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import { AiOutlineCloudUpload, AiOutlineCheckCircle, AiOutlineFileDone } from "react-icons/ai";
import "./ActivitiesAndProjects.css";
import { Link } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom/dist/umd/react-router-dom.development';

import puzzleicon from "./ActivityIcons/AP_ModuleProjectLesson.png";
import videoicon from "./ActivityIcons/AP_VideoIcon.png";
import brainicon from "./ActivityIcons/AP_Brainicon.png";
import oralicon from "./ActivityIcons/AP_Oralicon.png";
import writtenicon from "./ActivityIcons/AP_writtenicon.png";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import { useNavigate } from "react-router-dom";
import ModuleProjects from "./ModuleProjects";
import LessonProjects from "./LessonProjects";
import { toast } from "react-toastify";

const ActivityList = ({
  title,
  projects,
  backgroundColor,
  textColor,
  iconBackground,
  image,
  courseID,
  activityID,
  uploadApiRoute,
  studentId,
  setRefresh=null

}) => {
  const shouldDisplayTime =
    title === "Mental Activities" ||
    title === "Oral Activities" ||
    title === "Written Activities";

  const [selectedFile, setSelectedFile] = useState(null);
  const handleProjectClick = (projectType, activityID1, ActivityLink = null, project) => {
    let route = "";
    // alert(ActivityLink)
    async function updateActivity(activityID1){
      const courseIDResponse = await axiosInstance.post(
          "/getActivitycompletefromStudentIF",
          {
            Activity_id: activityID1,
            StudentId: studentId
          }
        );
    }
    console.log("project :", project)
    if (projectType === "MCQ Activities") {
      // route = "/MCQActivity";
      route = "/ActivityStartPage?activityType=MCQActivity";
    } else if (projectType === "Written Activities") {
      // route = "/WrittenActivity";
      route = "/ActivityStartPage?activityType=WrittenActivity";
    } else if (projectType === "Video Activities") {
      route = "/VideoActivity";
      updateActivity(activityID1)

      setRefresh&&setRefresh((val)=>!val)

      // route = "/ActivityStartPage?activityType=VideoActivity";
    } else if (projectType === "Speed Quiz Activities") {
      // route = "/SpeedQuizActivity";
      route = "/ActivityStartPage?activityType=SpeedQuizActivity";
    } else if (projectType === "Mental Activities") {
      // route = "/MentalActivity";
      route = "/ActivityStartPage?activityType=MentalActivity";
    } else if (projectType === "Oral Activities") {
      // route = "/OralActivity";
      route = "/ActivityStartPage?activityType=OralActivity";
    }
    else if (projectType == "Game Activities") {
      updateActivity(activityID1)
      window.open(`${ActivityLink}`, "_blank");
      setRefresh&&setRefresh((val)=>!val)
    }
    window.open(`${route}&activityId=${activityID1}`, "_blank");
  };

  const handleFileSelect = (e, projectid) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log("File Selected");
    handleUpload(file, projectid)
  };

  const handleUpload = async (file, projectID) => {
    console.log("File Uploading");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("Student_id", studentid);
    formData.append("Activity_id", projectID);

    // console.log("Form Data:");
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}${uploadApiRoute}`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      toast.success("Game Activity Uploaded", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedFile(null);
    } else {
      console.error("Error uploading file");
    }
  };

  return (
    <div className="AP_ModuleProjectLesson" style={{ backgroundColor }}>
      <h2 className="AP_title" style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} />{" "}
        {title}
      </h2>
      <ul style={{ marginLeft: "0", paddingLeft: "0" }}>
        {projects.map((project, index) => (
          <li key={index} className="AP_Projects">
            <div className="AP_ProjectItem">

            {/* <div style={{maxWidth:"100%", marginBottom:"5px"}}>
              <Link
                to=""
                onClick={() =>
                  handleProjectClick(title, project?.activity?.Lesson_Activity_id,project?.activity?.ActivityLink,project)
                }
                style={{ marginRight: "7%", color: textColor }}
              >
                {project.activity.ActivityName}
              </Link>
              </div> */}
              <div style={{ maxWidth: "100%", marginBottom: "5px" }}>
              <span
                style={{
                  color: textColor,
                  backgroundColor: project?.activity?.Complete ? "lightgreen" : "lightgrey",
                  borderRadius: "7vh",
                  padding: "1px 8px",
                  marginRight: "5px"

                }}
              >
              </span>
                <Link
                  to=""
                  onClick={() =>
                    handleProjectClick(title, project.activity.Activity_id||project.activity.Lesson_Activity_id, project?.activity?.ActivityLink, project)
                  }
                  style={{ marginRight: "7%", color: textColor }}
                >
                  {project.activity.ActivityName}
                </Link>

              </div>
              
              {shouldDisplayTime ? (
                <div style={{display:"flex"}}>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                      width:"65px",
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center"

                    }}
                    className="AP_Icon"
                  >
                    <AiOutlineCheckCircle size={40} />
                  </span>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                      display:"flex",
                      flexDirection:"column",
                      width:"65px",
                       alignItems:"center"
                    }}
                    className="AP_Score"
                  >
                    {project.activity.MarksObtained}/
                    {project.activity.TotalMarks} 
                    <span style={{fontSize:"11px"}}>(
                    {project.activity.TotalMarks>0?((
                      (project.activity.MarksObtained /
                        project.activity.TotalMarks) *
                      100
                    ).toFixed(1)):"0"}
                    %)
                    </span>
                  </span>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                      display:"flex",
                      flexDirection:"column",
                      minWidth:"60px",
                      maxWidth:"70px",
                      alignItems:"center"
                    }}
                    className="AP_Time"
                  >
                    {(project.activity.TimeTaken / 60).toFixed(0)}/
                    {(project.activity.TotalTime / 60).toFixed(0)}
                    <span style={{fontSize:"11px"}}>
                     mints
                    </span>
                  </span>
                </div>
              ) : (
                <>
                  <label htmlFor={`Gfile-upload-${index}`}>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                      }}
                      className="AP_Icon"
                    >
                      <AiOutlineCloudUpload />
                    </span>
                    <input
                      id={`Gfile-upload-${index}`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileSelect(e, project.activity.Lesson_Activity_id)}
                    />
                  </label>


                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                    }}
                    className="AP_Icon"
                  >
                    <AiOutlineCheckCircle />
                  </span>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                    }}
                    className="AP_Score"
                  >
                    {project.activity.MarksObtained}/
                    {project.activity.TotalMarks}
                  </span>
                </>
              )}
            </div>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ActivitiesAndProjects = () => {
  const location = useLocation();

  const [ActivityId, setActivityId] = useState();
  const [CourseID, setCourseID] = useState();
  // const { courseName, courseId } = location.state;

  // const cid  =  location.state?.courseId ;
  const { courseId } = useParams();
  const cid = courseId;

  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  const handleCourseSelect = (course) => { };

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        response
          .json()
          .then((userInfo) => {
            setUserInfo(userInfo);
            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar;
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest, cid]);

  const determineUserRole = (usertype) => {
    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    setUserRole(userRole);
  };

  async function onLogout() {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }

  const [projects, setProjects] = useState([]);
  const [projects2, setProjects2] = useState([]);
  const [VideoActivities, setVideoActivities] = useState([]);
  const [GameActivities, setGameActivities] = useState([]);
  const [MentalActivities, setMentalActivities] = useState([]);
  const [OralActivities, setOralActivities] = useState([]);
  const [WrittenActivities, setWrittenActivities] = useState([]);
  const [SpeedQuizActivities, setSpeedQuizActivities] = useState([]);
  const [MCQActivities, setMCQActivities] = useState([]);

  const [studentid, setStudentid] = useState(100);

  const [userData, setUserData] = useState({});
  const [refresh,setRefresh] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/", {
          withCredentials: true,
        });
        setUserData(response.data);
        console.log("UInfo: ", userInfo);

        const studentIdResponse = await axiosInstance.post(
          "/getStudentIDfromUser",
          {
            UserId: userInfo.User_id,
          }
        );
        console.log("U: ", userInfo.User_id);
        const studentId = studentIdResponse.data[0].Student_id || 3;
        setStudentid(studentId);

        console.log("S: ", studentId);

        const activityIdRes = await axiosInstance.post(
          "/getActivityIDfromStudent",
          {
            StudentId: studentId,
            // CourseID:cid
          }
        );
        const activityIdResponse = activityIdRes.data

        console.log("Ac: ", activityIdResponse);

        // Check if Activity_id is null or undefined
        if (!activityIdResponse.data[0] || activityIdResponse.data[0].Activity_id == null) {
          console.warn("Activity_id is null or undefined");
          return; // Exit the function early
        }

        const activityId = activityIdResponse.data[0].Activity_id;
        setActivityId(activityId);

        const courseIDResponse = await axiosInstance.post(
          "/getCourseIDfromActivityID",
          {
            ActivityID: activityId,
            CourseID: cid
          }
        );
        const courseID = courseIDResponse.data[0].Course_id;
        setCourseID(courseID);

        const projectsResponse = await axiosInstance.post(
          "/getStudentModuleProjectAssignment",
          {
            studentId: studentId,
            CourseID: cid
          }
        );
        const projectsData = projectsResponse.data?.data;
        setProjects(projectsData);
        console.log("Module Projects", projectsData)

        const projects2Response = await axiosInstance.post(
          "/getStudentLessonProjectAssignment",
          {
            studentId: studentId,
            CourseID: cid
          }
        );
        const projects2Data = projects2Response.data;
        setProjects2(projects2Data);

        const activitiesResponse = await axiosInstance.post(
          "/getStudentLessonActivity",
          {
            studentId: studentId,
            CourseID: cid
          }
        );
        const activitiesData = activitiesResponse.data?.data;
        const activityData = {
          "Video": [],
          "Game": [],
          "Mental": [],
          "Oral": [],
          "Written": [],
          "Speed Quiz": [],
          "MCQ": [],
        };

        activitiesData.forEach((activity) => {
          const activityType = activity.ActivityType;
          if (activityType && activityData.hasOwnProperty(activityType)) {
            activityData[activityType].push({ activity });
          } else {
            console.warn(
              `Invalid or missing ActivityType for activity:`,
              activity
            );
          }
        });

        setVideoActivities(activityData["Video"]);
        setGameActivities(activityData["Game"]);
        setMentalActivities(activityData["Mental"]);
        setOralActivities(activityData["Oral"]);
        setWrittenActivities(activityData["Written"]);
        setSpeedQuizActivities(activityData["Speed Quiz"]);
        setMCQActivities(activityData["MCQ"]);

        console.log('activityData["Video"] :', activityData["Video"])
        console.log('activityData["Game"] :', activityData["Game"])

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [studentid, cid,refresh]);


  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const usertype = userInfo?.usertype;

  return (
    <>
      {username && (
        <>
          {isAdmin && (
            <AdminNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isTeacher && (
            <TeacherNav
              onCourseSelect={handleCourseSelect}
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isStudent && (
            <StudentNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
        </>
      )}
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        
        <div className="AP_container">
        <div>
          {userInfo?.firstname}
        </div>
          {Array.isArray(projects) && projects.length > 0 && (
            <ModuleProjects
              title="Module Project"
              projects={projects}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentModuleProject"
              studentid={studentid}
            />
          )}
          {Array.isArray(projects2) && projects2.length > 0 && (
            <LessonProjects
              title="Lesson Project"
              projects={projects2}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentLessonProject"
              studentid={studentid}
            />
          )}
        </div>

        <div className="AP_container">
          {GameActivities.length > 0 && (
            <ActivityList
              title="Game Activities"
              projects={GameActivities}
              textColor="#d9ce23"
              backgroundColor="#f7fbdb"
              iconBackground="#f2fcae"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentGameActivity"
              studentId={studentid}
              setRefresh={setRefresh}
              
            />
          )}
        </div>

        <div className="AP_container">
          {MentalActivities.length > 0 && (
            <ActivityList
              title="Mental Activities"
              projects={MentalActivities}
              textColor="#da831e"
              backgroundColor="#faf0d6"
              iconBackground="#fae7b4"
              image={brainicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
          {OralActivities.length > 0 && (
            <ActivityList
              title="Oral Activities"
              projects={OralActivities}
              textColor="#d83162"
              backgroundColor="#feecf2"
              iconBackground="#fccada"
              image={oralicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {WrittenActivities.length > 0 && (
            <ActivityList
              title="Written Activities"
              projects={WrittenActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={writtenicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {SpeedQuizActivities.length > 0 && (
            <ActivityList
              title="Speed Quiz Activities"
              projects={SpeedQuizActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={brainicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {MCQActivities.length > 0 && (
            <ActivityList
              title="MCQ Activities"
              projects={MCQActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={brainicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {VideoActivities.length > 0 && (
            <ActivityList
              title="Video Activities"
              projects={VideoActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={videoicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
              setRefresh={setRefresh}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ActivitiesAndProjects;
