import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './locationinfo.css';
import Footer from '../Footer';
import GuestNav from '../Navbars/GuestNav';
import instance from '../ManageContent/managecontentforms/axiosInstance';

function LocationInfo() {
  const [ipAddress, setIpAddress] = useState('');
  const [locationInfo, setLocationInfo] = useState(null);
  const [countryCurrency, setCountryCurrency] = useState('');
  const [vpnInfo, setVpnInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch IP address
        const ipResponse = await instance.get('/api/detectlocation/ip');
        setIpAddress(ipResponse.data.ip);

        // Fetch location information based on IP
        const locationResponse = await instance.get(`/api/detectlocation/location/${ipResponse.data.ip}`);
        setLocationInfo(locationResponse.data);

        // Fetch country currency based on country name
        const currencyResponse = await instance.get(`/api/detectlocation/currency/${locationResponse.data.country_name}`);
        setCountryCurrency(Object.keys(currencyResponse.data)[0]);

        // Fetch VPN detection information
        const vpnResponse = await instance.get('/api/detectlocation/detectVPN');
        setVpnInfo(vpnResponse.data);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <>
      <GuestNav />
      <div className="location-info">
        <h2>IP Address: {ipAddress}</h2>
        {vpnInfo && vpnInfo.isUsingVPN && <div className="vpn-warning">Warning: It seems you are using a VPN.</div>}
        <h2>Location Details:</h2>
        <p>Country: {locationInfo.country_name}</p>
        <p>Country Currency: {countryCurrency}</p>
        <h2>VPN Information:</h2>
        <p>VPN: {vpnInfo.isUsingVPN ? "Yes" : "No"}</p>
        <p>Proxy: {vpnInfo.isUsingProxy ? "Yes" : "No"}</p>
        <p>Tor: {vpnInfo.isUsingTor ? "Yes" : "No"}</p>
        <p>Relay: {vpnInfo.isUsingRelay ? "Yes" : "No"}</p>
        <h2>Location Coordinates:</h2>
        <p>Latitude: {locationInfo.latitude}</p>
        <p>Longitude: {locationInfo.longitude}</p>
        <h2>Network Information:</h2>
        <p>Network: {locationInfo.network}</p>
        <p>ASN: {locationInfo.asn}</p>
        <p>ASO: {locationInfo.aso}</p>
      </div>
      <Footer />
    </>
  );
}

export default LocationInfo;
