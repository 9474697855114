import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import './CreateCertificate.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';

import jsPDF from 'jspdf';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { usePDF,Resolution,Margin } from 'react-to-pdf';

import PDF from './PDF';
import Signature from "./PDF_Images/signature.png";

import LebronStretch from "./PDF_Images/Image1.jpeg";



const CreateCertificate = () => {
  const { courseId, coursename } = useParams();
  // const [courseName, setCourseName] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStudentDetails, setSelectedStudentDetails] = useState(null);
  const [certificateNumber, setCertificateNumber] = useState(1)
  const [courses,setCourses] = useState([])
  const [selectedCourse,setSelectedCourse] = useState({courseId:'',coursename:''})
  const [ refresh,setRefresh] = useState(false)

  useEffect(() => {
    
    // fetchCourseName();
    fetchStudents();
    fetchCertificatNumber()

  }, [refresh]);
  const fetchCertificatNumber = async () =>{
    // getCertificateCourses
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateCertificateNumber`).then((res) => {

      setCertificateNumber(res?.data?.certificateNumber)
    })
  }
  const fetchCourseName = async () => {
    try {
      // const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/PDFCertificate_coursename/${courseId}`);
      // setCourseName(response.data.coursename);
    } catch (error) {
      console.error('Error fetching course name:', error);
    }
  };
  const fetchStudents = async () => {
    // if (courseId) {
      try {
        // const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/students_by_course/${courseId}`);
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/student`);

        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    // } else {
    //   setStudents([]);
    // }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  // Handle student selection
  const handleStudentChange = async (event) => {
    const studentId = event.target.value;
    const selectedStudent = students.find(student => student.User_id.toString() === studentId);
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCertificateCourses/${selectedStudent.Student_id}`).then((res)=>{
      console.log("courses Data :",res.data.courses)
      setCourses(res.data.courses)
    })
    setSelectedCourse({courseId:'',coursename:''})

    setSelectedStudentDetails(selectedStudent ? {
      userId: selectedStudent.User_id,
      firstname: selectedStudent.firstname,
      lastname: selectedStudent.lastname,
      parentname: selectedStudent.parentname,
      // startDate: formatDate(selectedStudent.StartDate),
      // endDate: formatDate(selectedStudent.EndDate)
    } : null);
    // const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/PDFCertificate_coursename/${selectedCourse.courseId}`);
  };

  // const generateCertificate = () => {
  //   // Initialize jsPDF
  //   const doc = new jsPDF();

  //   // Add image to the PDF (adjust coordinates and size as needed)
  //   const img = new Image();
  //   img.src = 'Image1.jpeg';
  //   doc.addImage(img, 'JPEG', 15, 40, 180, 120);


  //   doc.setFontSize(22);
  //   doc.text(20, 20, `Certificate for ${courseName}`);

  //   // Save the PDF file with course name as filename
  //   doc.save(`${courseName}_certificate.pdf`);
  // };

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  useLayoutEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        //  console.log("Response:",response);
        response.json().then(userInfo => {
          //  console.log("userinfo:",userInfo);
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          //  console.log("Uname Admin Register", uname);
          //  console.log("Avatar in: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogin = (usertype) => {

    setUserRole(usertype);
  };
  const navigate = useNavigate();

  async function onLogout() {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    console.log("navigating");
    navigate('/');
    // console.log("navigated");
    setUserRole('guest');
  }
  const options = {
    method: 'open',

    page: {
      format: 'A2',
      orientation: 'landscape',
    }
  }
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const { toPDF, targetRef } = usePDF({
    filename: `certificate-${certificateNumber}.pdf`,
    // paperSize: [1600, 1234],
    // format: '1600x1234',
    paperSize: [1234, 1600], // Explicitly set width < height for landscape
    format: 'landscape',    // Check if the library supports an orientation property
    page: { margin: Margin.NONE,orientation:"landscape" },
    resolution:Resolution.LOW

  });
  async function generateCertificatePdf() {
    const pdf = await toPDF();
    const pdfBlob = pdf.output('blob');
    const formDataInvoice = new FormData();
    // formDataInvoice.append('pdfFile', pdfBlob);
    const data = {
      courseId:certificateType==1?selectedCourse.courseId:null,
      User_id: selectedStudentDetails?.userId,
      // start_date: selectedStudentDetails.startDate,
      // end_date: selectedStudentDetails.endDate,
      date: new Date(),
      certificateNumber
 
    }
    formDataInvoice.append('pdfFile', pdfBlob, `Certificate-${certificateNumber}.pdf`);
    formDataInvoice.append('data', JSON.stringify(data));

    // formDataInvoice.append('invoiceData', invoiceData);

    // if(certificateType==1)
    // {
    const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateCertificate`, formDataInvoice, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(() => {
      setStudents([])
      setSelectedStudentDetails(null)
      setCourses([])
      setRefresh(!refresh)
      // setCertificateNumber(Math.floor(100000 + Math.random() * 900000))
    })
  // }
  // else{
  //   const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateEventCertificate`, formDataInvoice, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     }
  //   }).then(() => {
  //     setStudents([])
  //     setSelectedStudentDetails(null)
  //     setCourses([])
  //     setRefresh(!refresh)
  //     setCertificateNumber(Math.floor(100000 + Math.random() * 900000))
  //   })
  // }

  }
  function getDate() {
    // const today = new Date();
    // const month = today.getMonth() ;
    // const year = today.getFullYear();
    // const date = today.getDate();
    const now = new Date();
    const date = now.getDate();

    const monthName = now.toLocaleString('en-US', { month: 'short' });
    const year = now.getFullYear();
    return `${date}-${monthName}-${year}`;
    // return today.toDateString().slice(0, 10)
  }
  const [currentDate, setCurrentDate] = useState(getDate());
  const [textAreaValue, setTeaxtAreaValue] = useState(`For the exceptional performance that has led to the successful completion of Polymath-Kids ${selectedCourse.coursename}  conducted between December 2022 and March 2023`)
  const [certificateType,setCertificateType] = useState(1)
  function chageCertificateType(e){
    setCertificateType(e.target.value)
    if(e.target.value ==1){
      setTeaxtAreaValue(`For the exceptional performance that has led to the successful completion of Polymath-Kids ${selectedCourse.coursename}  conducted between December 2022 and March 2023`)
    }
    else if(e.target.value == 2){
      setTeaxtAreaValue(`Winner of Junior Level Islamic Knowledge Competition - PolyMatch 2024`)

    }
    else if(e.target.value == 3){
      setTeaxtAreaValue(`Participation in Junior Level Islamic Knowledge Competition - PolyMatch 2024`)

    }
  }
  function handleCourseChange(e){
    console.log("handleCourseChange",e.target[e.target.selectedIndex].label)
    setSelectedCourse({courseId:e.target.value,coursename:e.target[e.target.selectedIndex].label})
    setTeaxtAreaValue(`For the exceptional performance that has led to the successful completion of Polymath-Kids ${e.target[e.target.selectedIndex].label}  conducted between December 2022 and March 2023`)
  }
  return (
    <>

      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}
        </>
      )}

      <div className='edit-bar'>
        <h3 className='HeadingPDFcert'>
          Generate Certificate
        </h3>
        <br />
        <h2 className='coursenamePDFcert'>
          {selectedCourse.coursename}
        </h2>
      </div>


      {students.length > 0 && (
        <div className='TeacherFilterSection'>
          <div className='TeacherFilter'>
            <select id='TPcourseDropdown' onChange={handleStudentChange}>
              <option value=''>Select Student</option>
              {students.map((student) => (
                <option key={student.User_id} value={student.User_id}>
                  {student.firstname} {student.lastname}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}


      {selectedStudentDetails && (
        <div className='StudentDetails'>
          <h3>Student Details</h3>
          <table className='StudentTable'>
            <tbody>
              <tr>
                <th>First Name</th>
                <td>{selectedStudentDetails.firstname.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>{selectedStudentDetails.lastname.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Parent Name</th>
                <td>{selectedStudentDetails.parentname}</td>
              </tr>
              {/* <tr>
                <th>Start Date</th>
                <td>{selectedStudentDetails.startDate}</td>
              </tr>
              <tr>
                <th>End Date</th>
                <td>{selectedStudentDetails.endDate}</td>
              </tr> */}
              {certificateType!=1 && <tr>
                <th>Event Name</th>
                <td><textarea rows={5} style={{ width: "100%" }} defaultValue={textAreaValue} placeholder='Enter Event Name' onChange={(e) => setTeaxtAreaValue(e.target.value)} /></td>
              </tr>}
              {certificateType==1 && <tr>
                <th>Select Course</th>
                <td>
                  <select id='courseDropdown' onChange={handleCourseChange} >
                    <option value="">Select Course</option>
                    {
                      courses.map((course) => (
                        <option value={course.Course_id}>{course.coursename}</option>
                      ))
                    }
                    </select>
                </td>
              </tr>}
              <tr>
                <th>Certificate Type</th>
                <td>
                  <select defaultValue={1} onChange={chageCertificateType}> 
                    <option value={1}>
                      Course Certificate
                    </option>
                    <option value={3}>
                      Participation Certificate
                    </option>
                    <option value={2}>
                      Winning Certificate
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>


        </div>
      )}

      <div className='GenSalary'>
        {/* <button className='SalaryBtn' onClick={generateCertificate} >Generate Salary</button> */}
        {/* <PDFDownloadLink document={<PDF
              selectedStudentDetails={selectedStudentDetails}
              courseId
            />} filename="FORM" >
                {({loading}) => (loading ? <button>Loading Document...</button> : <button className='SalaryBtn'>Download Certificate</button> )}
            </PDFDownloadLink> */}
        <button onClick={() => generateCertificatePdf()}>Download Certificate </button>
      </div>

      {/* Footer */}
      <div style={{ padding: "3%", marginTop: "10px" }}>
        {selectedStudentDetails && (<div style={{  width: "1600px", height: "1120px", position: "relative" }} ref={targetRef}>
          {/* <div style={{background:`url(${LebronStretch})`,height:"100%",width:"100%",objectFit:"cover"}}>  */}
          <img src={LebronStretch} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
          {/* Badge
          <img src={Badge} style={{ height: "200px", width: "200px", position: "absolute", top: "6%", left: "80%" }} /> */}

          {/* <div style={{ position: "absolute", top: "2.3%", left: "85%", fontWeight: "bolder", fontSize: "18px", color: "purple" }}>
            {certificateNumber}
          </div> */}
          <div style={{ position: "absolute", top: "2.25%", left: "70%", fontWeight: "bolder", fontSize: "1.5rem", color: "purple" }}>
            Certificate # {certificateNumber}
          </div>

          <div style={{ position: "absolute", top: "40%", textAlign: "center", width: "100%", fontSize: "40px", color: "white", fontWeight: "bold" }}>
            {selectedStudentDetails.firstname.toUpperCase()} {selectedStudentDetails.lastname.toUpperCase()}
          </div>
          <div style={{ position: "absolute", top: "51%",padding:"0 15%", textAlign: "center", width: "100%", fontSize: "22px", color: "purple", fontWeight: "500" }}>
           {certificateType==1 ?<> S/O, D/O <b>{selectedStudentDetails?.parentname}</b> {textAreaValue}</>: <>{textAreaValue}</>} 

          </div>

          <div style={{ position: "absolute", top: "81%", left: "62%", fontWeight: "500", fontSize: "20px", color: "purple" }}>
            {currentDate}
          </div>
          <img src={Signature} style={{ height: "100px", width: "150px", position: "absolute", top: "77%", left: "27%" }} />


          {/* </div> */}
        </div>)}
      </div>
      <Footer />

    </>
  );
}

export default CreateCertificate;
