import "../AdminActions/ManageClasses.css";
import "./RegisterInCourse.css";

import React, { useState, useContext, useEffect } from "react";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import axios from "axios";

const RegisterInCourse = () => {
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);
  const [studentCourses,setStudentCourses] = useState([])

  useEffect(() => {

    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        response.json().then((userInfo) => {
          console.log("userInfo",userInfo)
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            setUserRole(userInfo.usertype);
          }
           axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/students_course_register/${userInfo?.User_id}`).then((res) => {
            console.log("userInfosssssssssssssssss",res)

            setStudentCourses(res.data)
           })
        }).catch((err) => console.log("Error in useEffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const navigate = useNavigate();
  async function onLogout() {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {/* {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>} */}

      {/* Page Content */}
      <div className="register-course-page">
        <div className="course-registration-info">
          <p>Thank you for showing interest in Polymath-Kids courses. To register in a course and get further details kindly contact Polymath-Kids team at:</p>
          <ul>
            <li>+47 47650619</li>
            <li>+92 32 666 999 07</li>
            <li>info@polymath-kids.com</li>
            <li>@polymathkidz Facebook</li>
            <li>@polymathkidz Instagram</li>
          </ul>
        </div>

        {/* Table */}
        <div className="course-table">
          <table>
            <thead>
              <tr>
                <th>Course</th>
                <th>Registration Status</th>
                <th>Instructions</th>
              </tr>
            </thead>
            <tbody>
              {/* Example rows */}
              {studentCourses?.map((item)=>(
                <tr key={item?.Course_id}>
                <td>{item?.coursename}</td>
                <td>{item?.StatusComplete?"Complete":"Incomplete"}</td>
                <td>Kindly make the payment to complete the registration process, please check your email for details.</td>
              </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RegisterInCourse;
