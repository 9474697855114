import React, { useRef, useContext, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import './ContactUs.css';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; // Import react-select
import countryList from 'react-select-country-list'; // Ensure this import is active

const ContactUs = ({ selectedCourse }) => {
  const form = useRef();
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  // State to handle country selection
  const [countries, setCountries] = useState([]); // Country options
  const [selectedCountry, setSelectedCountry] = useState(null); // Selected country

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        response.json().then(userInfo => {
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
        }).catch(err => console.log("Error in useEffect:", err));
      });
    };
    fetchData();

    // Set country list on component mount
    const options = countryList().getData();
    console.log("Country options: ", options); // Debugging to check country options
    setCountries(options);
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogout = () => {
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
  };

  const handleContactInquiry = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_p478mti', 'template_za7k209', form.current, 'P0xsNsNTFQBlZTnEa')
      .then((result) => {
        console.log(result.text);
        toast.success('Email sent successfully!', {
          position: 'top-center',
          autoClose: 3000,
        });
      }, (error) => {
        console.log(error.text);
        toast.error('Email not sent!', {
          position: 'top-center',
          autoClose: 3000,
        });
      });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption); // Set selected country
  };

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav />}
        </>
      )}

      <form action="" onSubmit={handleContactInquiry} ref={form}>
        <div className="contactUs">
          <div className="leftDiv">
            {/* Contact Details */}
            <h2 className="contactHeading">GET IN TOUCH</h2>
            <i className="fa-sharp fa-solid fa-phone" style={{ color: "blue", marginLeft: "4%" }}></i>
            <div style={{ marginLeft: "12%" }}>
              <h5>Phone/Whatsapp</h5>
              <p>+4747650619</p>
              <p>+923266699907</p>
              <p>+923244466677</p>
            </div>

            <i className="fa-sharp fa-solid fa-envelope" style={{ color: "blue", marginLeft: "4%" }}></i>
            <div style={{ marginLeft: "12%" }}>
              <h5>Email</h5>
              <p>info@polymath-kids.com</p>
            </div>

            {/* Social Media Links */}
            <div className="social-icons">
              <a href="https://www.facebook.com/polymathkidz" target="_blank" rel="noopener noreferrer" className="fa-brands fa-square-facebook" style={{ fontSize: "6vh", marginRight: "2%" }}></a>
              <a href="https://twitter.com/polymathkidz" target="_blank" rel="noopener noreferrer" className="fa-brands fa-twitter" style={{ fontSize: "6vh", marginRight: "2%" }}></a>
              <a href="https://www.youtube.com/@polymath-kids" target="_blank" rel="noopener noreferrer" className="fa-brands fa-youtube" style={{ fontSize: "6vh", marginRight: "2%" }}></a>
              <a href="https://www.instagram.com/polymathkidz/" target="_blank" rel="noopener noreferrer" className="fa-brands fa-instagram" style={{ fontSize: "6vh", marginRight: "2%" }}></a>
              <a href="https://www.pinterest.com/polymathkidz/" target="_blank" rel="noopener noreferrer" className="fa-brands fa-pinterest" style={{ fontSize: "6vh", marginRight: "2%" }}></a>
            </div>
          </div>

          <div className="rightDiv">
            <h2 className="contactHeading">SEND A MESSAGE</h2>
            <input className="Input" name="user_name" required type="text" placeholder="Name" />
            <input className="Input" name="age_child" required type="number" placeholder="Age of child" />
            <input className="Input" name="user_number" required type="text" onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
              }} placeholder="WhatsApp Number" />
            <input className="Input" name="user_email" required type="email" placeholder="Email" />
            {/* Country Dropdown */}
            <Select
  options={countries}
  value={selectedCountry}
  onChange={handleCountryChange}
  placeholder="Choose your Country"
  required
  styles={{
    control: (baseStyles) => ({
      ...baseStyles,
      width: '90%',
      border: '1px solid rgb(175, 175, 175)',
      borderRadius: '1vh',
      padding: '1%',
      margin: '2%',
      height: '20px',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: 'grey', // Optional: You can customize the placeholder color too
    }),
  }}
/>

            <input className="Input" name="message" required type="text" placeholder="Message" />
            <button className="btnSend">Send Message</button>
          </div>
        </div>
      </form>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default ContactUs;
