import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Astronomy.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import Astronomyy from './SpaceSciencesImages/AstronomyBackground.png'


import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./SpaceSciencesImages/Astronomy.png";
import pic2 from "./SpaceSciencesImages/Astrolable.png";
import pic3 from "./SpaceSciencesImages/Telescope.png";
import pic4 from "./SpaceSciencesImages/Planisphere.png";
import pic5 from "./SpaceSciencesImages/stellarium.png";
import pic6 from "./SpaceSciencesImages/SkyGazing.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import astrom from './SpaceSciencesImages/AstronomyBackground.png'

const Astronomy = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;


    return (
        <div >
          
         {/* Navbar */}
         {username && (
       <>
       {/* {avatar && <img src={avatar} alt="User Avatar" />} */}
            {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
   
          <div className="Astronomy-Background">
            <section className="section">
            <p className="PageHead" style={{ color: "#8b0194" }}>Astronomy</p>
              <div className="PageDesc">
              <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
                Exploring the wonders of the universe is a fascinating and exciting journey that ignites the imagination of children. At Polymath-Kids, we believe that studying astronomy not only enhances a child's knowledge of the universe, but also helps them to develop critical thinking skills, problem-solving abilities, and a deep appreciation for the beauty of the cosmos. Our Astronomy program offers a comprehensive curriculum that covers topics such as the solar system, stars and galaxies, and the history of space exploration. 
                </p>
                <img style={{minWidth:"250px", maxWidth:"450px"}} src={astrom}></img>
                </div>
                <div className="btn-row d-flex btn-row-resp btn-row-resp1">
                  {/* <div className="btn-row-resp"> */}
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className="btn-row-resp button btn-color-course"
                      href="#course"
                    >
                      Course Structure
                    </a>
                  </div>
    
                  <div style={{ width: "auto", height: "50px" }}>
                    <a className=" btn-row-resp button btn-color-fee" href="#fee">
                      Fee Structure
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className=" btn-row-resp button btn-color-Testimonal"
                      href="#testimonal"
                    >
                      Testimonals
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                      Faqs
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className=" btn-row-resp button btn-color-registered"
                      href="#"
                    >
                      Get Registered
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <div className="overlay"></div>
          </div>
    
          {/* Intro text */}
          <div className="scratch-background">
            <section className="section">
              <p className="PageHead" style={{ color: "#8b0194" }}>
              What is Astronomy?
              </p>
              <div className="PageDesc">
                <p
                  style={{
                    color: "#8b0194",
                    marginTop: "2vh",
                    fontSize: "4vh",
                    fontFamily: "sans serif",
                  }}
                >
                Astronomy is one of the oldest natural sciences. The early civilizations in recorded history made methodical observations of the night sky. These include the Babylonians, Greeks, Indians, Egyptians, Chinese, Maya, and many ancient indigenous peoples of the Americas. In the past, astronomy included disciplines as diverse as astrometry, celestial navigation, observational astronomy, and the making of calendars. Nowadays, professional astronomy is often said to be the same as astrophysics.
                  <br />
                Professional astronomy is split into observational and theoretical branches. Observational astronomy is focused on acquiring data from observations of astronomical objects. This data is then analyzed using basic principles of physics. Theoretical astronomy is oriented toward the development of computer or analytical models to describe astronomical objects and phenomena. These two fields complement each other. Theoretical astronomy seeks to explain observational results and observations are used to confirm theoretical results.        </p>
              </div>
            </section>
          </div>
    
          {/* Course Structure and Syllabus */}
          <section className="section course-structure" id="course">
            <p className="PageHead" style={{ color: "#8b0194" }}>
              Course Structure and Syllabus
            </p>
            <h4 className='H4_Heading' style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
              <b>Course Objectives</b>
            </h4>
            <p style={{ color: "#8b0194", fontFamily: "sans-serif",padding: "0 19% 0 25%" }}>

            1.	Understand the history of Astronomy, including the discoveries and contributions made by ancient civilizations and scientists.<br />
            2.	Identify the different components of our Solar System, including the planets, moons, and other celestial bodies.<br />
            3.	Explain the unique characteristics of Earth, including its atmosphere, rotation, and seasons.<br />
            4.	Understand the concept of seasons and time, including the causes and effects of the Earth's tilt and revolution.<br />
            5.	Learn how to use an Astrolabe, a historical astronomical instrument used for determining the position of stars and planets.<br />
            6.	Identify and explain the different constellations visible in the night sky.<br />
            7.	Learn how to use a Planisphere, a rotating star chart that helps identify the position of stars and constellations in the night sky.<br />
            8.	Understand the characteristics of Asteroids, Comets, and Meteorites, including their formation and impact on Earth.<br />
            9.	Learn how to build a Telescope, including the various components and how they work together to enhance viewing capabilities.<br />
            10.	Explore the concept of Exoplanets and the Oort Cloud, including the discoveries and scientific research related to these areas.<br />
            11.	Learn how to use Stellarium, a planetarium software that allows users to view the night sky from any location on Earth.
            <br />
            By the end of the course, students should have a solid understanding of the history of astronomy, the components of our Solar System, the unique characteristics of Earth, and how to use astronomical tools to view the night sky. Additionally, they will have gained an appreciation for the beauty of the universe and the role of science in understanding it.
            </p>
    
            <div>
              <p
                style={{
                  color: "#249935",
                  marginTop: "3vh",
                  fontSize: "2em",
                  fontFamily: "VanillaMilk",
                }}
              >
                <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
                Astronomy Beginner Course (Suitable for Grade 6 to 8)

              </p>
            </div>
    
            {/* Module one */}
            <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                  {" "}
                  Module
                  <br />
                  1
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                </p>
    
                <div
                  className="small-box sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Our Solar System
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                        This module introduces the students with the history of Astronomy including Ptolemy's Model, Copernican Revolution, Kepler's Laws. They will learn in detail about our solar system and our planet Earth including the concept of season and time.
                  </p>
                </p>
              </div>
            </div>

              {/* Module Two */}
              <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  2
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                </p>
    
                <div
                  className="small-box sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Astronomical Instruments
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                This module introduces the students to the astronomical instruments including Astrolabe, Planisphere and Telescope. Students will learn to make their own telescope.
                  </p>
                </p>
              </div>
            </div>
       {/* Module Three */}
             <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  3
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                </p>
    
                <div
                  className="small-box sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Outer Space
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                    Students will explore outer space and learn about Constellations, Asteroids, Comets, Meteorites, Exoplanets and Oort Cloud. 
                  </p>
                </p>
              </div>
            </div>
        {/* Module Four */}
        <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  4
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                </p>
    
                <div
                  className="small-box sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Astronomy Software
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                  In this module, students will learn astronomy software Stellarium and will do a project.
                  </p>
                </p>
              </div>
            </div>
           
    
            {/* Module one */}
            <div>
              <p
                style={{
                  color: "#485cd9",
                  marginTop: "3vh",
                  fontSize: "2em",
                  fontFamily: "VanillaMilk",
                }}
              >
                <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
                Astronomy Intermediate Course (Suitable for Grade 9 to 10)
              </p>
            </div>
    
            <div className="modules">
              {/* round box */}
              <div className="round-box round-box1 ">
                <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                  {" "}
                  Module
                  <br />
                  1
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                </p>
    
                <div
                  className="small-box small-box1 sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                Our Solar System
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                    This module introduces the students with the history of Astronomy including Ptolemy's Model, Copernican Revolution, Kepler's Laws. They will learn in detail about our solar system and our planet Earth including the concept of season and time.
                  </p>
                </p>
              </div>
            </div>
    
            {/* Module two */}
            <div className="modules my-3">
              {/* round box */}
              <div className="round-box round-box1 ">
                <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                  {" "}
                  Module
                  <br />
                  2
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                </p>
    
                <div
                  className="small-box small-box1 sm-box"
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                Astronomical Instruments
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                This module introduces the students to the astronomical instruments including Astrolabe, Planisphere and Telescope. Students will learn to make their own telescope.
                  </p>
                </p>
              </div>
            </div>
    
            {/* Module three */}
            <div className="modules my-3">
              {/* round box */}
              <div className="round-box round-box1 ">
                <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                  {" "}
                  Module
                  <br />
                  3
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
                </p>
    
                <div
                  className="small-box small-box1 sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                Outer Space
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                    Students will explore outer space and learn about Constellations, Asteroids, Comets, Meteorites, Exoplanets and Oort Cloud. 
                  </p>
                </p>
              </div>
            </div>
    
              {/* Module Four */}
            <div className="modules my-3">
              {/* round box */}
              <div className="round-box round-box1 ">
                <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                  {" "}
                  Module
                  <br />
                  4
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
                </p>
    
                <div
                  className="small-box small-box1 sm-box"
                  
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                <p style={{ margin: "auto" }}>
                Astronomy Software
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                    In this module, students will learn astronomy software Stellarium and will do a project.
                  </p>
                </p>
              </div>
            </div>       
            {/* Module one*/}
            <div>
            <p
              style={{
                color: "#999924",
                marginTop: "3vh",
                fontSize: "2em",
                fontFamily: "VanillaMilk",
              }}
            >
                <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
                Astronomy Advance Course (Suitable for Grade 11 to 12)
            </p>
          </div>
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
                
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Our Solar System
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                This module introduces the students with the history of Astronomy including Ptolemy's Model, Copernican Revolution, Kepler's Laws. They will learn in detail about our solar system and our planet Earth including the concept of season and time.
                </p>
              </p>
            </div>
          </div>
          
          {/* Module two*/}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
                
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Astronomical Instruments
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                This module introduces the students to the astronomical instruments including Astrolabe, Planisphere and Telescope. Students will learn to make their own telescope.
                </p>
              </p>
            </div>
          </div>
   
        {/* Module three*/}
        <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
                
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Outer Space
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    Students will explore outer space and learn about Constellations, Asteroids, Comets, Meteorites, Exoplanets and Oort Cloud. 
                </p>
              </p>
            </div>
          </div>
        {/* Module Four*/}
        <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                4
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
                
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Astronomy Software
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    In this module, students will learn astronomy software Stellarium and will do a project.
                </p>
              </p>
            </div>
          </div>


            <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
              <b>Learn Astronomy with 40+ Fun Activities & Projects</b>
            </h6>
    
            <div className="activityImagesContainer">
              <div className="row">
                <div class="col-lg mx-3">
                  <img src={pic2} alt="" className='ActivityImages'/>
                  <p className="mx-3">Astrolabe</p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic3} alt="" className='ActivityImages' />
                  <p className="mx-3">Make a Telescope</p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic4} alt="" className='ActivityImages'/>
                  <p className="mx-3">Planisphere</p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic5} alt="" className='ActivityImages'/>
                  <p className="mx-3">Stellarium Software </p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic6} alt="" className='ActivityImages'/>
                  <p className="mx-3">Sky Gazing</p>
                </div>
    
              </div>
            </div>
          </section>
    
          {/* Fee Structure */}
         
          <FeeStructure subject="Astronomy"/>

          {/* Testimonals */}
          <div class="  background" id="testimonal">
            <div class="row" style={{ width: "100%" }}>
              <div class="col-lg-8 mx-auto">
                <h2 className="Testimonal_Heading">Testimonials</h2>
                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                  {/* <!-- Carousel indicators --> */}
                  <ol class="carousel-indicators">
                    <li
                      data-target="#myCarousel"
                      data-slide-to="0"
                      class="active"
                    ></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                  </ol>
                  {/* <!-- Wrapper for carousel items --> */}
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="img-box">
                        <div className=" img-box1"></div>
                      </div>
                      <p
                        class="testimonial"
                        style={{ color: "black", fontSize: "3vh" }}
                      >
                        I enjoyed the learning experience with EduTechSage-Abacus.
                        The teacher was very friendly. I highly recommend
                        EduTechSage-Abacus.
                      </p>
                      <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                        <b>Mujtaba Khawaja Sahaf</b>
                      </p>
                    </div>
    
                    <div class="carousel-item">
                      <div class="img-box">
                        <div className="img-box2 "></div>
                      </div>
                      <p
                        class="testimonial"
                        style={{ color: "black", fontSize: "3vh" }}
                      >
                        I feel so proud when my class fellows call me ’BRAINIAC ’,
                        because I can effortlessly do mental calculations much
                        faster and accurate than my class mates. Thank you
                        Polymath-Kids.
                      </p>
                      <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                        <b>Wahaab Omer</b>, Age 9 Years
                      </p>
                    </div>
                  </div>
                  {/* <!-- Carousel controls --> */}
                  <a
                    class="carousel-control-prev"
                    href="#myCarousel"
                    data-slide="prev"
                  >
                    <i class="fa fa-angle-left"></i>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#myCarousel"
                    data-slide="next"
                  >
                    <i class="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
    
          
        {/* Faqs */}
        <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">
  
  <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
    <MDBAccordion alwaysOpen initialActive={1} >
      <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
      <MDBAccordionItem collapseId={1} headerTitle="Q: What is Astronomy?" >
        <strong>A: </strong>
        Astronomy is the study of celestial objects and phenomena that originate outside the Earth's atmosphere. It includes the study of planets, stars, galaxies, and other objects in the universe.
       </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle="Q: What topics are covered in Polymath-Kids' Astronomy course?">
        <strong>A: </strong>
        Polymath-Kids' Astronomy course covers a wide range of topics such as the solar system, stars and galaxies, the universe, constellations, and space exploration.
      </MDBAccordionItem>


      <MDBAccordionItem collapseId={3} headerTitle="Q: What age group is suitable for learning Astronomy?">
        <strong>A: </strong>
        Our Astronomy course is suitable for kids aged 8 to 18 years old. We have designed the curriculum to cater to the different age groups, with customized materials and teaching methods for each level.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={3} headerTitle="Q: Who will be teaching the Astronomy course?">
        <strong>A: </strong>
        Our Astronomy course is taught by experienced instructors who have a background in Astronomy or related fields. All our instructors undergo a rigorous selection process and are experts in their subject areas.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={3} headerTitle="Q: How is the Astronomy course structured?">
        <strong>A: </strong>
        Our Astronomy course is structured into modules, with each module covering a specific topic in Astronomy. Each module includes videos, interactive quizzes, and hands-on activities to help students better understand the concepts. At the end of each module, students are given a project to apply what they have learned.
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle="Q: How long is the Astronomy course?">
        <strong>A: </strong>
        A: The duration of the Astronomy course depends on the level of the course. The course for younger kids is shorter(12 weeks) and simpler, while the course for older kids is more comprehensive and longer. 
      </MDBAccordionItem>
   </MDBAccordion>
 </MDBContainer>

        </div>
          
    {/* Footer */}
    <Footer/>
    
        </div>
      )
}

export default Astronomy
