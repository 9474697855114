import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './managecontentstyle/ManageLessonProject.css';
import {toast} from 'react-toastify';
import './managecontentstyle/buttonstyle.css';

const ManageLessonProject = ({ lessonid }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [updatedmaterialurl,setUpdatedMaterialURL] = useState("");
  const [updatedprojecturl,setUpdatedProjectURL] = useState("");
  const [updatedprojectmarks,setUpdatedProjectMarks] = useState("");

  useEffect(() => {
    fetchLessonProject();
  }, []);

  const fetchLessonProject = () => {
    axiosInstance
      .post('/api/managecourse/getLessonProjectNameDescription', { lessonid })
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = (projectId) => {
    const updatedProjects = projects.filter((project) => project.Lesson_Project_id !== projectId);

    const payload = { Lesson_Project_id: projectId };

    axiosInstance
      .post('/api/managecourse/deleteLessonProjectAssignment', payload)
      .then(() => {
        toast.success("Lesson Project Deleted",{
          position:toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
  };


  const handleUpdate = (projectId) => {
    setSelectedProjectId(projectId);
    const selectedProject = projects.find((project) => project.Lesson_Project_id === projectId);
    setUpdatedName(selectedProject.projectassignmentname);
    setUpdatedDescription(selectedProject.projectassignmentdescription);
    setUpdatedMaterialURL(selectedProject.helpingmaterialurl);
    setUpdatedProjectURL(selectedProject.projectassignmenturl);
    setUpdatedProjectMarks(selectedProject.projectmarks);
    fetchLessonProject()
  };

  const handleSave = (projectId) => {
    const updatedProjects = projects.map((project) => {
      if (project.Lesson_Project_id === projectId) {
        return {
          ...project,
          editing: false,
          projectassignmentdescription: updatedDescription,
          projectassignmentname: updatedName,
          projectassignmenturl:updatedprojecturl,
          helpingmaterialurl:updatedmaterialurl,
          projectmarks:updatedprojectmarks
        };
      }
      return project;
    });

    const selectedProject = updatedProjects.find((project) => project.Lesson_Project_id === projectId);

    const payload ={
      Lesson_Project_id: selectedProject.Lesson_Project_id,
      projectassignmentname:selectedProject.projectassignmentname,
      projectassignmentdescription:selectedProject.projectassignmentdescription,
      helpingmaterialurl:selectedProject.helpingmaterialurl,
      projectassignmenturl:selectedProject.projectassignmenturl,
      projectmarks:selectedProject.projectmarks
    }

    axiosInstance.post('/api/managecourse/updateLessonProjectNameDescription', payload)
      .then(() => {
        toast.success("Lesson Project Updated",{
          position:toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
    setSelectedProjectId(null);
  };

  return (
    <div className="manage-lesson-project">
      <h2>Manage Lesson Project</h2>
      <div className="project-list">
        {projects.map((project) => (
          <div className="project-card" key={project.Lesson_Project_id}>
            {selectedProjectId === project.Lesson_Project_id ? (
              <div className="edit-form">
                <input type="text" placeholder="Enter Name" value={updatedName} onChange={(e) => setUpdatedName(e.target.value)} />
                <textarea placeholder="Enter Description" value={updatedDescription} onChange={(e) => setUpdatedDescription(e.target.value)} />
                <textarea placeholder="Enter Project URL" value={updatedprojecturl} onChange={(e) => setUpdatedProjectURL(e.target.value)} />
                <textarea placeholder="Enter Helping Material URL" value={updatedmaterialurl} onChange={(e) => setUpdatedMaterialURL(e.target.value)} />
                <input type="number" min="0" placeholder="Enter Marks" value={updatedprojectmarks} onChange={(e) => setUpdatedProjectMarks(e.target.value)} />
                <button className="inz-save-button" onClick={() => handleSave(project.Lesson_Project_id)}>Save</button>
              </div>
            ) : (
              <>
                <h3>{project.projectassignmentname}</h3>
                <p>{project.projectassignmentdescription}</p>
                <div className="inz-button-row">
                  <button className="inz-update-button" onClick={() => handleUpdate(project.Lesson_Project_id)}>Update</button>
                  <button className="inz-deletesp-button" onClick={() => handleDelete(project.Lesson_Project_id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );

};

export default ManageLessonProject;
