import React,{useState,useContext,useEffect} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import './CourseRegistration.css'
import Footer from '../Footer';

import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaTimesCircle } from 'react-icons/fa'; 

const CourseRegistration = () => {
    
    const [discount, setDiscount] = useState(['10% Off','20% Off','30% Off']);
    const [assignedTeacherNames, setAssignedTeacherNames] = useState(new Set());
    
    function Registration({RegistrationCourse,setRegistrationCourses,classIDs}) {
      // console.log('classIDs:',classIDs);
      const [formValues, setFormValues] = useState(RegistrationCourse);
      // console.log('RegistrationCourse:',RegistrationCourse)
      const filteredClassIDs = classIDs.filter((CID) => RegistrationCourse.Course_id === CID.Course_id);
      const uniqueClassIDsSet = new Set();
      const [selectedClass, setSelectedClass] = useState(null);
      const [teacherInfo, setTeacherInfo] = useState([]);
      // console.log('filteredClassIDs:',filteredClassIDs)

      const handleRegister = () => {
          // Create a new object to store all the information
          const requestData = {
              ...RegistrationCourse, // Spread the properties from RegistrationCourse
              Class_id: selectedClass// Placeholder for the selected class item
          };
    
          // Send the requestData to the API
          axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentCourse`, requestData)
              .then(response => {
                  // Handle the response if needed
                  fetchRegistrationCourses();
                  // console.log(response.data);
                  toast.success('Student has been registered Successfully', {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 2000,
                  });
              })
              .catch(error => {
                  // Handle any errors that occurred during the request
                  console.log('Error saving student course:', error);
              });
      };
    
      // Function to handle class change
      const handleClassChange = (event) => {
        const classId = event.target.value;
        setSelectedClass(classId);
        // console.log('classIDs:',classIDs)
        // Find the corresponding teacher information based on the selected class ID
        const selectedClassInfo = classIDs.filter((CID) => CID.Class_id == classId);
        // console.log('selectedClassInfo:',selectedClassInfo)
        if (selectedClassInfo.length > 0) {
          // console.log("selectedClassInfo:",selectedClassInfo)
          // Assuming teacherInfo contains an array of teacher's first name and last name
          const teachers = selectedClassInfo.map(info => ({ firstName: info.firstname, lastName: info.lastname }));
          // console.log("teachers:",teachers)
          setTeacherInfo(teachers);
        } else {
          // Reset teacher info if no class is selected
          setTeacherInfo([]);
        }
      };

      const handleClassTypeChange = (event) => {
        const newRegistrationCourse = {
          ...RegistrationCourse,
          ClassType: event.target.value,
        };
        axios.put(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateStdClassType`, newRegistrationCourse)
          .then(response => {
            console.log('API response:', response.data);
            // setRegistrationCourses(response.data);

            setFormValues((prevValues) => ({
              ...prevValues,
              ClassType : event.target.value
            }));
            toast.success('Successfully Updated Class Type.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          })
          .catch(error => {
            console.error('Error making API call:', error);
          });
      };

      const handlePaymentTypeChange = (event) => {
        const newPaymentType = {
          ...RegistrationCourse,
          PaymentType: event.target.value,
        };
        axios.put(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateStdPaymentType`, newPaymentType)
          .then(response => {
            console.log('API response:', response.data);
            setFormValues((prevValues) => ({
              ...prevValues,
              PaymentType : event.target.value
            }));
            toast.success('Successfully Updated Payment Type.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          })
          .catch(error => {
            console.error('Error making API call:', error);
          });
      };
      const setClass=(uniqueClassIDsSet)=>{
        // console.log('uniqueClassIDsSet:',uniqueClassIDsSet)
        const firstElement = [...uniqueClassIDsSet][0];
        // console.log('firstElement:',firstElement);
        setSelectedClass(firstElement)
            const selectedClassInfo = classIDs.filter((CID) => CID.Class_id == firstElement);
          // console.log('selectedClassInfo:',selectedClassInfo)
          if (selectedClassInfo.length > 0) {
            // Assuming teacherInfo contains an array of teacher's first name and last name
            const teachers = selectedClassInfo.map(info => ({ firstName: info.firstname, lastName: info.lastname }));
            setTeacherInfo(teachers);
          } else {
            // Reset teacher info if no class is selected
            setTeacherInfo([]);
          }
      }
        useEffect(() => {
          // Check if there is only one unique class and set it
          if (uniqueClassIDsSet.size === 1){
            setClass(uniqueClassIDsSet);
          }
        }, [uniqueClassIDsSet]);
        
        return (
             <>
             {
              formValues.StatusComplete == 0 &&
            <tbody id="table-rows">
                <tr className='CourseRegistrationTable-tr'>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.firstname} {RegistrationCourse.lastname}</td>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.coursename}</td>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.RegistrationDate}</td>
                    {/* <td className='CourseRegistrationTable-td'>ABC</td> */}
                    <td className='CourseRegistrationTable-td'> <b>PKR</b> 20000</td>
                    <td className='CourseRegistrationTable-td'>
                        <select name="discount" id="discount-code" className='CourseRegistrationSelect'>
                        <option value="" className='CourseRegistrationOption'>Select Discount</option>
                       
                        {discount.map((discount) => (
                                    <option key={discount} value={discount}>
                                    {discount}
                                    </option>
                                ))}
                        </select>
                    </td>               
                    <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio"
                            value="Monthly" 
                            id={`monthly-fee-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique id based on Student_id
                            name={`fee-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique name based on Student_id
                            checked={formValues.PaymentType === "Monthly"} 
                            onChange={handlePaymentTypeChange}
                            />
                            <label for="monthly-fee" className='CourseRegistrationLabel'>Monthly</label>
                        </div>
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio"
                             value="Upfront" 
                             id={`one-time-fee-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique id based on Student_id
                             name={`fee-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique name based on Student_id
                             checked={formValues.PaymentType === "Upfront"} 
                             onChange={handlePaymentTypeChange}
                             />
                            <label for="one-time-fee" className='CourseRegistrationLabel'>One Time</label>
                        </div>
                    </td>
                    <td className="CourseRegistrationRadioType CourseRegistrationTable-td">
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" 
                            id={`group-class-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique id based on Student_id
                            name={`ClassRadio-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique name based on Student_id
                            value="Group" 
                            placeholder='Ctype' 
                             checked={formValues.ClassType === "Group"}
                             onChange={handleClassTypeChange}
                             />
                            <label for="group-class" className='CourseRegistrationLabel'>Group</label>
                        </div>
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" 
                            value="One-on-One"
                            placeholder='Ctype' 
                            id={`one-on-one-class-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`}  // Unique id based on Student_id
                            name={`ClassRadio-${RegistrationCourse.Student_id}-${RegistrationCourse.Course_id}`} // Unique name based on Student_id
                            checked={formValues.ClassType === "One-on-One"}
                            onChange={handleClassTypeChange}
                             />
                            <label for="one-on-one-class" className='CourseRegistrationLabel'>One on One</label>
                        </div>
                    </td>
                    
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.SchedulePreferenceDay} {RegistrationCourse.SchedulePreferenceTime}</td>
                    <td className='CourseRegistrationTable-td'>
                      
                    <select 
                      name="discount" 
                      id="discount-code" 
                      className='CourseRegistrationSelect'
                      value={RegistrationCourse.Class_id }
                      style={{width:"15vw",fontSize:"2vh"}}
                      onChange={handleClassChange}
                    >
                      {filteredClassIDs && filteredClassIDs.length > 0 ? (
                            <>
                              {filteredClassIDs.map((CID) => {
                                // Check if the Class_id is already added to the Set
                                if (!uniqueClassIDsSet.has(CID.Class_id)) {
                                  // Add the Class_id to the Set
                                  uniqueClassIDsSet.add(CID.Class_id);
                                  // Render the option for this Class_id
                                  return (
                                    <option key={CID.Class_id} value={CID.Class_id}>
                                      {CID.coursename} {CID.ClassID} {CID.ClassDay} {CID.ClassTime}
                                    </option>
                                  );
                                }
                                return null; // Render nothing if the Class_id is already added
                              })}
                              {/* {uniqueClassIDsSet.size === 1 && setClass(uniqueClassIDsSet)} */}
                            </>
                          ) : (
                            <option>{filteredClassIDs ? 'No Class for this Course' : 'Loading...'}</option>
                          )}
                    </select>
                    </td>
                      
                    <td className='CourseRegistrationTable-td' key={filteredClassIDs?.ClassID} value={filteredClassIDs?.ClassID}>
                      {teacherInfo.map((teacher, index) => (
                        <p key={index}> {teacher.firstName} {teacher.lastName} </p>
                      ))}
                    </td>

                    {/* <td className='CourseRegistrationTable-td'>{studentRegistration.classSchedule}</td> */}
                    <td className='CourseRegistrationTable-td'>
                        <button 
                            className={`CourseRegistrationButton${!selectedClass ? ' disabled' : ''}`} 
                            onClick={handleRegister} 
                            disabled={!selectedClass} 
                            title={!selectedClass ? "Select a class" : ""}
                        >
                            {/* {!selectedClass ? 'Select Class' : 'Register'} */}
                            Register
                        </button>
                    </td>


                </tr>
            </tbody> 
             }
            </>
        );
    };
    const [RegistrationCourses, setRegistrationCourses] = useState([]);
    const [CourseIDs, setCourseIDs] = useState([]);
    const [ClassIds, setClassIDs] = useState([]);

    useEffect(() => {
        fetchRegistrationCourses(); 
    }, []);
    useEffect(() => {
     
      fetchClassIDs(CourseIDs);
      // console.log(RegistrationCourses)
      // fetchRegistrationCourses(); 

  }, [RegistrationCourses]);
 
    const fetchRegistrationCourses = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getRegistrationCourses`);
          const data = response.data;
          const formattedData = data.registrationCourses.map(courseData => ({
            ...courseData,
            RegistrationDate: new Date(courseData.RegistrationDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
          }));
          console.log('formattedData:',formattedData)
        setRegistrationCourses(formattedData);
        setCourseIDs(data.uniqueCourseIDs);
        } catch (error) {
          console.log(error);
        }
      };
    const fetchClassIDs = async (courseIds) => {
      try {
        // console.log("in fetchClassIDs")
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getClassIDs/${courseIds}`);
        const data = response.data;
        // console.log("data.classIDs:",data.classIDs)
        setClassIDs(data.classIDs);
      } catch (error) {
        console.log(error);
      }
    };
     
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
   
            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname in user.js: ", uname);
        
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
          
    const onLogin = (usertype) => {
      
      setUserRole(usertype);
    };
    const navigate=useNavigate();
    async function onLogout() {
        console.log("inside Onlogout.");
      await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
          credentials: 'include',
          method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
      }
   
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
      
    return (
        <>
          {/* Navbar */}
          {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
        <div className='edit-bar' >
        <h3 className='student-EditHeading' style={{marginLeft:'0',textAlign:"center"}}>
           Course Registration
        </h3>
      </div>
        <table className='CourseRegistrationTable'>
            <thead>
                <tr className='CourseRegistrationTable-tr'>
                    <th className='CourseRegistrationTable-th'>Student</th>
                    <th className='CourseRegistrationTable-th'>Course</th>
                    <th className='CourseRegistrationTable-th'>Registration Date</th>
                    {/* <th className='CourseRegistrationTable-th'>School</th> */}
                    <th className='CourseRegistrationTable-th'>Fee</th>
                    <th className='CourseRegistrationTable-th'>Discount</th>
                    <th className='CourseRegistrationTable-th'>Fee Type</th>
                    <th className='CourseRegistrationTable-th'>Class Type</th>
                    <th className='CourseRegistrationTable-th'>Schedule Preference</th>
                    <th className='CourseRegistrationTable-th'>Class ID</th>
                    <th className='CourseRegistrationTable-th'>Teacher</th>
                    {/* <th className='CourseRegistrationTable-th'>Class Schedule</th> */}
                    <th className='CourseRegistrationTable-th'>Register Student</th>
                </tr>
            </thead>
        {RegistrationCourses.map((RegistrationCourse, index) => (
            <Registration key={index}
            RegistrationCourse={RegistrationCourse} 
            setRegistrationCourses={setRegistrationCourses}
            classIDs={ClassIds}  />
            ))}

            </table>
            <Footer/>
            
        </>
    );
};

export default CourseRegistration
