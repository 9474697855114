import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { toast } from 'react-toastify';
import './managecontentstyle/EditLesson.css';
import './managecontentstyle/buttonstyle.css';

const EditLesson = (props) => {
  const [lesson, setLesson] = useState({
    lessonname: 'lesson',
    lessondescription: 'desc',
    lessonno: 'no',
    learningobjective: 'obj',
    materialandpreparation: 'mat',
    keyterms: 'key'
  });

  const Lesson_id = props.lessonid;

  const [allLessons, setAllLessons] = useState(null);

  useEffect(() => {
    fetchLesson();
  }, [Lesson_id]);

  const fetchLesson = () => {
    axiosInstance
      .post('/api/managecourse/getSpecificLesson', { Lesson_id })
      .then(response => {
        setAllLessons(response.data);
        const specificLesson = response.data.find(l => l.Lesson_id === Lesson_id);
        setLesson(specificLesson);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setLesson(prevLesson => ({
      ...prevLesson,
      [name]: value
    }));
  };

  const handleSave = e => {
    e.preventDefault();

    // Make an API call to save the lesson changes
    axiosInstance
      .post('/api/managecourse/saveLessonChanges', lesson)
      .then(response => {
        toast.success('Lesson Edited Sucessfully', {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log('Error saving lesson changes:', error);
      });
  };

  if (!lesson) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-lesson">
      <h2>Edit Lesson</h2>
      <form>
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            required
            name="lessonname"
            value={lesson.lessonname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="lessondescription"
            
            value={lesson.lessondescription}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Lesson No:</label>
          <input
            type="text"
            name="lessonno"
            required
            value={lesson.lessonno}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Lesson Objective:</label>
          <input
            type="text"
            name="learningobjective"
            
            value={lesson.learningobjective}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Material and Preparation:</label>
          <input
            type="text"
            name="materialandpreparation"
            
            value={lesson.materialandpreparation}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Key Terms:</label>
          <input
            type="text"
            name="keyterms"
            
            value={lesson.keyterms}
            onChange={handleChange}
          />
        </div>
        <div className='inz-button'>
        <button type="button" className='inz-save-button' onClick={handleSave}>
          Save
        </button>
        </div>
      </form>
    </div>
  );
};

export default EditLesson;
