import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router,Switch,Route,Navigate} from 'react-router-dom';
import { Routes  } from 'react-router-dom';
import { useEffect } from "react";
import './App.css';
import Home from './MyComponents/Home';
import MathCourseDetails from './MyComponents/MathPortal/MathCourseDetails';
import BenefitDetail from './MyComponents/MathPortal/BenefitDetail';
import HighlightsDetail from './MyComponents/MathPortal/HighlightsDetail';
import CodingCourseDetails from './MyComponents/CodingPortal/CodingCourseDetails';
import ScratchProgramming from './MyComponents/CodingPortal/ScratchProgramming';
import AppDev from './MyComponents/CodingPortal/AppDev';
import WebDev from './MyComponents/CodingPortal/WebDev';
import GameDev from './MyComponents/CodingPortal/GameDev';
import PHPDev from './MyComponents/CodingPortal/PHPDev';
import BusinessCourseDetails from './MyComponents/BusinessPortal/BusinessCourseDetails';
import KidPreneur from './MyComponents/BusinessPortal/KidPreneur';
import QuranCourseDetails from './MyComponents/QuranPortal/QuranCourseDetails';
import GraphicCourseDetails from './MyComponents/GraphicDesignPortal/GraphicCourseDetails';
import AdobeXD from './MyComponents/GraphicDesignPortal/AdobeXD';
import CorelDraw from './MyComponents/GraphicDesignPortal/CorelDraw';
import PhotoShop from './MyComponents/GraphicDesignPortal/PhotoShop';
import Canva from './MyComponents/GraphicDesignPortal/Canva';
import Illustrator from './MyComponents/GraphicDesignPortal/Illustrator';
import Vision from './MyComponents/FooterPages/OurVision';
import PolymathKidSchool from './MyComponents/FooterPages/PolymathKidSchool';
import AboutUs from './MyComponents/FooterPages/AboutUs';
import TermsOfUse from './MyComponents/FooterPages/TermsOfUse';
import Cancellation from './MyComponents/FooterPages/Cancellation';
import Policy from './MyComponents/FooterPages/Policy';
import ContactUs from './MyComponents/FooterPages/ContactUs';
import EngineeringCourseDetails from './MyComponents/EngineeringPortal/EngineeringCourseDetails';
import CivilEngineering from './MyComponents/EngineeringPortal/CivilEngineering';

// import { UserProvider } from './MyComponents/UserContext';

import UserContextProvider from './MyComponents/UserContext';
import CourseContext from './MyComponents/CourseContext';

import ArchitectureCourseDetails from './MyComponents/ArchitecturePortal/ArchitectureCourseDetails';
import SpaceSciencesCourseDetails from './MyComponents/SpaceSciencePortal/SpaceSciencesCourseDetails';
import Astronomy from './MyComponents/SpaceSciencePortal/Astronomy';


import RegisterInCourse from './MyComponents/Actions/RegisterInCourse';
import AttendClass from './MyComponents/Actions/AttendClass';
import MyProgress from './MyComponents/Actions/MyProgress';
import TeacherAttendClass from './MyComponents/Actions/TeacherAttendClass';
import { useState } from 'react';
import ManageClasses from './MyComponents/AdminActions/ManageClasses';
import User from './MyComponents/ManageUser/User';
import CourseRegistration from './MyComponents/AdminActions/CourseRegistration';


import WrittenActivity from './MyComponents/Activities/WrittenActivity/WrittenActivity';
import MCQActivity from './MyComponents/Activities/MCQActivity/MCQActivity';
import SpeedQuizActivity from './MyComponents/Activities/SpeedQuizActivity/SpeedQuizActivity';
import MentalActivity from './MyComponents/Activities/MentalActivity/MentalActivity';
import OralActivity from './MyComponents/Activities/OralActivity/OralActivity';
import VideoActivity from './MyComponents/Activities/VideoActivity/VideoActivity';

import AdminRegisterInCourse from './MyComponents/AdminActions/AdminRegisterInCourse';
import ActivitiesAndProjects from './MyComponents/Actions/ActivitiesAndProjects';
import IslamicKnowledgeCourse from './MyComponents/QuranPortal/IslamicKnowledgeCourse';
import ProtectedRoute from './MyComponents/ProtectedRoute';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Password from './MyComponents/Actions/password';
import AdminEdit from './MyComponents/Actions/AdminEdit';
import EditProfile from './MyComponents/Actions/EditProfile';
import Teacher from './MyComponents/Actions/Teacher';
import Managecontent from './MyComponents/ManageContent/managecontent';
import Faqs from './MyComponents/FooterPages/Faqs';
import AIDataScience from './MyComponents/CodingPortal/AIDataScience';
import Python from './MyComponents/CodingPortal/Python';
import CPP from './MyComponents/CodingPortal/CPP';
import PromptEngineering from './MyComponents/CodingPortal/PromptEngineering';
import Android from './MyComponents/CodingPortal/Android';
import Unity from './MyComponents/CodingPortal/Unity';
import ML from './MyComponents/CodingPortal/ML';
import CommunicationCourseDetails from './MyComponents/CommunicationPortal/CommunicationCourseDetails';
import CPS from './MyComponents/CommunicationPortal/CPS';
import ScrollToTop from './MyComponents/components/ScrollToTop';
import ExamActivity from './MyComponents/Activities/ExamActivity/ExamActivity';
import ChangeTeachersAndClass from './MyComponents/AdminActions/ChangeTeachersAndClass';
import TeacherProfile from './MyComponents/AdminActions/TeacherProfile';
import TeacherRating from './MyComponents/AdminActions/TeacherRating';
import TeacherSalary from './MyComponents/AdminActions/TeacherSalary';
import MyCalender from './MyComponents/CalenderView/MyCalender';
import MyRewards from './MyComponents/MyRewards/MyRewards';
import LocationInfo from './MyComponents/LocationInfo/LocationInfo';
import ActivityStartPage from './MyComponents/Activities/ActivityStart/ActivityStartPage';
import StudentDashboard from './MyComponents/Actions/StudentDashboard';
import GradeProjectPage from './MyComponents/Actions/GradeProjectPage';
import ActivityResult from './MyComponents/Activities/ActivityResult/ActivityResult';
import UnAuthorized from './MyComponents/UnAuth/UnAuthorized';
import ClipLoader from 'react-spinners/ClipLoader';

import GenerateMeetingLink from './MyComponents/AdminActions/GenerateMeetingLink'
import Polymatch from './MyComponents/PromotionalPages/Polymatch';
import PolymatchReg from './MyComponents/PromotionalPages/PolymatchReg';
import PolymatchResults from './MyComponents/PromotionalPages/PolymatchResults';
import PolymatchDetail from './MyComponents/PromotionalPages/PolymatchDetail';
import SummerCamp from './MyComponents/PromotionalPages/SummerCamp';
import PayPalPayment from './MyComponents/PaymentIntegration/PayPalPayment';
import InvoiceTable from './MyComponents/StudentInvoices/InvoiceTable';
import MakePaymentPage from './MyComponents/StudentInvoices/MakePaymentPage';
import CreateCertificate from './MyComponents/CertificateGeneration/CreateCertificate';
import PDF from './MyComponents/CertificateGeneration/PDF';
import { PDFDownloadLink } from "@react-pdf/renderer";
import AB from './MyComponents/CertificateGeneration/AB';
import FeeConfig from './MyComponents/MyFees/FeeConfig';
import StudentFee from './MyComponents/MyFees/StudentFee';
import StudentProgress from './MyComponents/Actions/StudentProgress';
import PasswordForget from './MyComponents/Actions/passwordForget';
import CreateCertificate2 from './MyComponents/CertificateGeneration/CreateCertificate2';
function App() {
  const [Course, setCourse] = useState('');
  const handleCourseSelect = (course) => {
    // Do something with the selected course
    setCourse(course);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      });
      const userInfo = await response.json();
      const uType = userInfo?.usertype;
      return uType;
    } catch (error) {
      console.log('Error in fetchData:', error);
      return null;
    }
  };


  const ProtectedRoute = ({ path, allowedRoles, component }) => {
    const [userType, setUserType] = useState(null);

    useEffect(() => {
      fetchData().then((type) => {
        setUserType(type);
      });
    }, []);


    // if (userType === null)
    // {
    //   return <div style={{textAlign:"center"}}>Loading...</div>;
    // }
    // // console.log('userType1:', userType);
    if (userType === null) {
      return (
        <div className="loading-container">
          <ClipLoader size={50} color={"#123abc"} loading={true} />
          <div className="loading-text">Loading...</div>
        </div>
      );
    }
    const isAllowed = allowedRoles.includes(userType);

    console.log(isAllowed);
    // console.log('userType:', userType);
    console.log(allowedRoles);
    // console.log("Component:",component)
    return isAllowed ? (
      <>
      {/* console.log({component}); */}
      <div>{component}</div>

      </>
    ) : (
      // <Navigate to="/unauthorized" replace />
      <UnAuthorized />
    );
  };

  return (
    <>
       <ToastContainer position="top-center"
       autoClose={3000}
       pauseOnFocusLoss={false}
       draggable
       pauseOnHover={false}
      //  transition= 'Bounce'
       />
    <Router >
       <UserContextProvider>
       <ScrollToTop />
        <Routes>
    <Route path="/" element={<Home   selectedCourse={handleCourseSelect}/>}/>

    <Route
      path="/MyProgress"
      element={<ProtectedRoute allowedRoles={['Student']} component={<MyProgress/>}/>}
    />
    <Route
      path="/StudentProgress"
      element={<ProtectedRoute allowedRoles={['Admin' , 'Teacher']} component={<StudentProgress/>}/>}
    />
    

    <Route
      path="/GenerateMeetingLink"
      element={<ProtectedRoute allowedRoles={['Admin', 'Teacher']} component={<GenerateMeetingLink/>}/>}
    />
    <Route
      path="/ActivitiesAndProjects/:courseId"
      element={<ProtectedRoute allowedRoles={['Student']} component={<ActivitiesAndProjects/>}/>}
    />
    <Route
      path="/ActivityStartPage"
      element={<ProtectedRoute allowedRoles={['Student']} component={<ActivityStartPage/>}/>}
    />

    <Route
      path="/RegisterInCourse"
      element={<ProtectedRoute allowedRoles={['Student']} component={<RegisterInCourse/>}/>}
    />
    <Route
      path="/AttendClass"
      element={<ProtectedRoute allowedRoles={['Student']} component={<AttendClass/>}/>}
    />

    {/* Admin & Teacher Actions */}
    {/* <Route path='/unauthorized' element={<UnAuthorized/>}/> */}

    <Route
      path="/StudentDashboard"
      element={<ProtectedRoute allowedRoles={['Teacher']} component={<StudentDashboard/>}/>}
    />
    <Route
      path="/GradeProjectPage"
      element={<ProtectedRoute allowedRoles={['Teacher']} component={<GradeProjectPage/>}/>}
    />

     <Route
      path="/ActivityResult"
      element={<ProtectedRoute allowedRoles={['Admin', 'Teacher']} component={<ActivityResult/>}/>}
    />

    <Route
      path="/TeacherAttendClass"
      element={<ProtectedRoute allowedRoles={['Admin','Teacher']} component={<TeacherAttendClass/>}/>}
    />
    <Route
      path="/admin_levels_coding"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<Managecontent/>}/>}
    />

    <Route
      path="/admin_manage_classes/:courseId/:coursename"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<ManageClasses/>}/>}
    />
    <Route
      path="/ManageUser"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<User/>}/>}
    />
    <Route
      path="/CourseRegistration"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<CourseRegistration/>}/>}
    />
    <Route
      path="/ChangePassword"
      element={<ProtectedRoute   allowedRoles={['Admin','Student','Teacher']} component={<Password/>}/>}
    />
    <Route
      path="/ChangeForgetPassword"
      element={<PasswordForget/>}
    />
    <Route
      path="/AdminEdit"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<AdminEdit/>}/>}
    />

    <Route
      path="/gen_certificate/:courseId/:coursename"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<CreateCertificate/>}/>}
    />
    <Route
      path="/gen_certificate2"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<CreateCertificate2/>}/>}
    />
   <Route
      path="/AB"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<AB/>}/>}
    />
    <Route
   path="/FeeConfig"
   element={<ProtectedRoute allowedRoles={['Admin']} component={<FeeConfig />} />}
    />
  <Route
   path="/StudentFee"
   element={<ProtectedRoute allowedRoles={['Admin']} component={<StudentFee />} />}
    />

  {/* <Route
      path="/pdf"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<PDF/>}/>}
    /> */}
    <Route
      path="/EditProfile"
      element={<ProtectedRoute   allowedRoles={['Student']} component={<EditProfile/>}/>}
    />
    <Route
      path="/TeacherEditProfile"
      element={<ProtectedRoute   allowedRoles={['Teacher']} component={<Teacher/>}/>}
    />
    <Route
      path="/AdminRegisterInCourse"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<AdminRegisterInCourse/>}/>}
    />
    <Route
      path="/CourseRegistration"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<CourseRegistration/>}/>}
    />
    <Route
      path="/TeacherProfile"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<TeacherProfile/>}/>}
    />
    <Route
      path="/TeacherRating"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<TeacherRating/>}/>}
    />
    <Route
      path="/TeacherSalary"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<TeacherSalary/>}/>}
    />
    {/* Promotional content*/}
    <Route path="/polymatch" element={<Polymatch/>}/>
    <Route path="/PolymatchReg" element={<PolymatchReg/>}/>
    <Route path="/PolymatchResults" element={<PolymatchResults/>}/>
    <Route path="/PolymatchDetail" element={<PolymatchDetail/>}/>
    <Route path="/summer_camp" element={<SummerCamp/>}/>


    {/* Math  Portal */}
    <Route path="/abacus_home" element={<MathCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/abacus_benefits" element={<BenefitDetail selectedCourse={handleCourseSelect}/>}/>
    <Route path="/abacus_learning_portal" element={<HighlightsDetail selectedCourse={handleCourseSelect}/>}/>

    {/* Coding Portal */}
    <Route path="/coding_home" element={<CodingCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_scratch" element={<ScratchProgramming selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_app_development" element={<AppDev  selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_web_development" element={<WebDev  selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_game_development" element={<GameDev selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_php" element={<PHPDev selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_python" element={<Python selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_cplus" element={<CPP selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_game_dev_unity" element={<Unity selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_machine_learning" element={<ML selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_app_dev_androidstu" element={<Android selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_ai_promptengineer" element={<PromptEngineering selectedCourse={handleCourseSelect}/>}/>
    <Route path="/coding_ai_datascience" element={<AIDataScience selectedCourse={handleCourseSelect}/>}/>

    {/* Business Portal */}
    <Route path="/business_portal" element={<BusinessCourseDetails />}/>
    <Route path="/business_kidpreneur" element={<KidPreneur selectedCourse={handleCourseSelect}/>}/>

    {/* Quran Portal */}
    <Route path="/quran_portal" element={<QuranCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/quran_islamic_knowledge" element={<IslamicKnowledgeCourse selectedCourse={handleCourseSelect}/>}/>

    {/* Graphic Designing Portal */}
    <Route path="/graphic_designing_portal" element={<GraphicCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/graphic_designing_adobexd" element={<AdobeXD selectedCourse={handleCourseSelect}/>}/>
    <Route path="/graphic_designing_corel" element={<CorelDraw selectedCourse={handleCourseSelect}/>}/>
    <Route path="/graphic_designing_photosh" element={<PhotoShop selectedCourse={handleCourseSelect}/>}/>
    <Route path="/graphic_designing_canva" element={<Canva selectedCourse={handleCourseSelect}/>}/>
    <Route path="/graphic_designing_illustr" element={<Illustrator selectedCourse={handleCourseSelect}/>}/>

    {/* Engineering Portal */}
    <Route path="/engineering_portal" element={<EngineeringCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/engineering_civil" element={<CivilEngineering selectedCourse={handleCourseSelect}/>}/>


    {/* Architecture Portal */}
    <Route path="/arch_interior_design" element={<ArchitectureCourseDetails selectedCourse={handleCourseSelect}/>}/>


    {/* Space Science Portal */}
    <Route path="/space_sc_portal" element={<SpaceSciencesCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/space_sc_atronomy" element={<Astronomy selectedCourse={handleCourseSelect}/>}/>


    {/* communication Portal */}
    <Route path="/comm_skills_portal" element={<CommunicationCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/comm_comm_skills" element={<CPS selectedCourse={handleCourseSelect}/>}/>

    {/*Footer Pages  */}

    <Route path="/our_vision" element={<Vision selectedCourse={handleCourseSelect}/>}/>
    <Route path="/polymathkids_at_schools" element={<PolymathKidSchool selectedCourse={handleCourseSelect}/>}/>
    <Route path="/our_team" element={<AboutUs selectedCourse={handleCourseSelect}/>}/>
    <Route path="/terms_of_use" element={<TermsOfUse selectedCourse={handleCourseSelect}/>}/>
    <Route path="/cancelation_refund_policy" element={<Cancellation selectedCourse={handleCourseSelect}/>}/>
    <Route path="/privacy_policy" element={<Policy selectedCourse={handleCourseSelect}/>}/>
    <Route path="/contact_us" element={<ContactUs selectedCourse={handleCourseSelect}/>}/>


    <Route path="/faqs" element={<Faqs selectedCourse={handleCourseSelect}/>}/>
    


    {/* Mohsin Routes */}
    <Route path="/WrittenActivity" element={<WrittenActivity />}/>
      <Route path="/MCQActivity" element={<MCQActivity activityId={4}/>}/>
      <Route path="/SpeedQuizActivity" element={<SpeedQuizActivity activityId={4}/>}/>
      <Route path="/MentalActivity" element={<MentalActivity activityId={4}/>}/>
      <Route path="/OralActivity" element={<OralActivity activityId={2}/>}/>
      <Route path="/VideoActivity" element={<VideoActivity activityId={1}/>}/>
      <Route path="/ExamActivity" element={<ExamActivity activityId={1}/>}/>


      <Route path="/ChangeTeacherAndClass" element={<ChangeTeachersAndClass />}/>
      <Route path="/PaypalPayment" element={<PayPalPayment />}/>
      <Route path="/studentInvoices" element={<InvoiceTable />}/>
      <Route path="/MakePaymentPage" element={<MakePaymentPage />}/>

      {/* Inzamam Routes */}

      <Route path="/calenderview" element={<ProtectedRoute   allowedRoles={['Admin','Student','Teacher']} component={<MyCalender/>}/>}/>
      <Route path="/locationinfo" element={<LocationInfo/>}/>
      {/* <Route path='mycalender' element={<MyCalender/>}/>
      <Route path='myfees' element={<MyFees/>}/> */}
      <Route path="/myrewards" element={<ProtectedRoute   allowedRoles={['Student']} component={<MyRewards/>}/>}/>
      </Routes>
    </UserContextProvider>
  </Router>

    </>
  );
}

export default App;
