import React, { useContext, useEffect, useState } from 'react'
import "./PolymatchResults.css";
import Flag from 'react-world-flags';
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import { Link } from "react-router-dom";
import Footer from "../Footer";
import owner from "../CourseImages/ownerImg.png";
import TeacherNav from '../Navbars/TeacherNav';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import polymatchImage from './Polymatch.png';
import "@fontsource/belanosima"; // Defaults to weight 400
import "@fontsource/belanosima/400.css"; // Specify weight
//import { useFonts, Baumans_400Regular } from '@expo-google-fonts/baumans';
//import "@fontsource/belanosima/400-italic.css"; // Specify weight and style

import winner1 from './WinnerImages/winner1.png';
import winner2 from './WinnerImages/winner2.png';
import winner3 from './WinnerImages/winner3.png';
import winner4 from './WinnerImages/winner4.png';
import winner5 from './WinnerImages/winner5.png';
import winner6 from './WinnerImages/winner6.png';
import winner7 from './WinnerImages/winner7.png';
import winner8 from './WinnerImages/winner8.jpeg';
import winner9 from './WinnerImages/winner9.jpeg';
import winner10 from './WinnerImages/winner10.jpeg';
import axios from 'axios';

const Polymatch = () => {
  const flagCodes = [
    'PK', // Pakistan
    'GB', // United Kingdom
    'NO', // Norway
    'AE', // UAE
    'SA', // Saudi Arabia
    'US', // United States
    'CA', // Canada
    'AU', // Australia
    'QA', // Qatar
    'ZA', // South Africa
    'SE', // Sweden
    'OM'  // Oman
  ];
  const winners_abacus = [
    winner1,
    winner2,
    winner3,
    winner4,
    winner5,
  ];
  const winners_islamic = [
    winner6,
    winner7,
  ];
  const winners_abacus2 = [
    winner8,
    winner9,
    winner10,

  ];
  const data = [
    { rank: 1, name: 'Ayesha Basit', parentName: 'Fatima basit', country: 'Pakistan', totalScore: '65,321', correctAnswers: 70, incorrectAnswers: 0 },
    { rank: 2, name: 'Irtaaz Haider Kszmi', parentName: 'Masroor haider kazmi', country: 'Pakistan', totalScore: '63,471', correctAnswers: 67, incorrectAnswers: 3 },
    { rank: 3, name: 'Haadi F Majeed', parentName: 'Yumna Fawwad', country: 'Pakistan', totalScore: '63,065', correctAnswers: 67, incorrectAnswers: 3 },
    // Add more entries here as needed...
  ];
  const [aAgesResults, setAgesResults] = useState([])
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {

    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        console.log("Response:", response);
        response.json().then(userInfo => {
          console.log("userinfo:", userInfo);
          setUserInfo(userInfo);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
      axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/students_results`).then((res) => {
        console.log("userResult", res)

        setAgesResults(res.data?.data)
      })
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
    console.log("In home.js:", usertype)
    if (usertype === 'Student')
      return "Student";
    else if (usertype === 'Teacher')
      return "Teacher";
    else if (usertype === 'Admin')
      return "Admin";
    else
      return "guest";
  };


  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
    console.log("inside Onlogout.");
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
    navigate("/");

  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}

        </>
      )}
      <div className='PolymatchBg'>
        <div className='PolymatchSection_1'>
          <img src={polymatchImage} alt="image" />
          <div className="PolymatchWords">
            <p className="word1">Mastery</p>
            <p className="word2">Achieve</p>
            <p className="word3">Thrive</p>
            <p className="word4">Compete</p>
            <p className="word5">Hone</p>
          </div>


          <p>Congratulations to all the participants and winners of PolyMatch 2024 - Global Mental Math Competition held on 9 November 2024. You can view the results of the competition below.</p>


          <div className='PolymatchSection_2'>
            <div className='Polymatch_Age5'>
              <a href='#Five'>Results of Category Age 5 to 7</a>
            </div>


            <div className='Polymatch_Age8'>

              <a href='#Eight'>Results of Category Age 8 to 10</a>
            </div>

            <div className='Polymatch_Age11'>

              <a href='#Eleven'>Results of Category Age 11 to 13</a>
            </div>
          </div> <br></br>
          {aAgesResults?.map((data, index) => (
            <div key={index}>
              <div className='PolymatchResultsText' id='Five'>
                PolyMatch 2024 <br />
                Global Mental Math Competition Results {data?.displayName}
              </div> <br></br>
              <div className="results-table-container">
                <table className="results-table">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Parent Name</th>
                      <th>Country</th>
                      <th>Total Score</th>
                      <th>Correct Answers</th>
                      <th>Incorrect / Not Attempted Answers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.participants?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.rank}</td>
                        <td>{item?.firstName}</td>
                        <td>{item?.parentName}</td>
                        <td>{item?.country}</td>
                        <td>{item?.total}</td>
                        <td>{item?.correctAnswers}</td>
                        <td>{item?.incorrectAnswers}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> <br></br>
            </div>
          ))}





          {/* <div className='PolymatchResultsText' id='Eight'>
            PolyMatch 2024 <br />
            Global Mental Math Competition Results Age 8 to 10
          </div> <br></br>
          <div className="results-table-container">
            <table className="results-table">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Parent Name</th>
                  <th>Country</th>
                  <th>Total Score</th>
                  <th>Correct Answers</th>
                  <th>Incorrect / Not Attempted Answers</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.rank}</td>
                    <td>{item.name}</td>
                    <td>{item.parentName}</td>
                    <td>{item.country}</td>
                    <td>{item.totalScore}</td>
                    <td>{item.correctAnswers}</td>
                    <td>{item.incorrectAnswers}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> <br></br>
          <div className='PolymatchResultsText' id='Eleven'>
            PolyMatch 2024 <br />
            Global Mental Math Competition Results Age 11 to 13
          </div>
          <div className="results-table-container">
            <table className="results-table">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Parent Name</th>
                  <th>Country</th>
                  <th>Total Score</th>
                  <th>Correct Answers</th>
                  <th>Incorrect / Not Attempted Answers</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.rank}</td>
                    <td>{item.name}</td>
                    <td>{item.parentName}</td>
                    <td>{item.country}</td>
                    <td>{item.totalScore}</td>
                    <td>{item.correctAnswers}</td>
                    <td>{item.incorrectAnswers}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> <br></br> */}




        </div>
      </div> <br></br>
      <h1><center>STAY TUNED FOR MORE COMPETITIONS</center></h1>
      {/* Footer */}
      <Footer />
    </>
  )
}

export default Polymatch
