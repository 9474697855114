import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./Unity.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

import pic1 from './CodingCourseImages/unitylogo.png'
import pic2 from './CodingCourseImages/billcalculator.png'
import pic3 from './CodingCourseImages/cricketstats.png'
import pic4 from './CodingCourseImages/banktransaction.png'
import pic5 from './CodingCourseImages/gradecalculator.png'
import pic6 from './CodingCourseImages/filehandlingCpp.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import unt from './CodingCourseImages/unitybackground.png'
const Unity = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }  
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
  return (
    <div >
    {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
 
      <div className="Unity-Background">
         <section className="section">
           <p className="PageHead"style={{ color: "#8b0194" }}>Game Development (Unity) Course</p>
           <div className="PageDesc">
           <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
             Kids love to play interactive games and its more fun to develop your own imaginative game. Polymath-kids offer a comprehensive online course i.e. Game Development using Unity platform for kids aged 12 - 18 years.
             </p>
             <img style={{minWidth:"250px", maxWidth:"450px"}} src={unt}></img>

             </div>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">

               {/* <div className="btn-row-resp"> */}
               <div style={{ width: "auto", height: "50px" }}>


                 <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
               </div>
              
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

               </div>
               <div style={{ width: "auto", height: "50px" }}>

                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                

               </div>


          
             </div>
           </div>

         </section>
         <div className="overlay"></div>
       </div>

        {/* Intro text */}
       <div className='scratch-background'>
       <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>What Polymath-Kids Game Development offers?</p>
           <div className="PageDesc">
             <p style={{
                 color: "#8b0194",
                 marginTop: "2vh",
                 fontSize: "4vh",
                 fontFamily: "sans serif",
               }}>
              Unity is a 2D/3D game engine. Unity allows you to create games using a combination of visual components and code and then export it to every major mobile platform.

                Unity is a real-time 3D tool for building 2D and 3D Apps., like games and simulations, using coding languages i.e .NET and C#. Unity can target 25+ platforms across mobile, desktop, console, TV, VR, AR, and the web. It is estimated that 50% of games that are published are developed using Unity platform. Visual Studio offers Unity powerful development tools.
             </p>    
           </div>
         </section>
       </div>
       
       {/* Course Structure and Syllabus */}
       <section className="section course-structure" id="course">
         <p className="PageHead" style={{ color: "#8b0194" }}>
           Course Structure and Syllabus
         </p>
         <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
               Course Objectives
             </b>
         </h4>
         <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
            <li>Learn C#, a powerful modern language, from scratch. No prior programming experience is necessary.</li>
            <li>Understand Unity Platform</li>
            <li> Learn the syntax to code the game</li>
            <li>Learn the importance of variables and data types in coding</li>
            <li>Learn about functions and their purpose in coding</li>
            <li>Learn about conditional, arithmetic, and comparison operators</li>
            <li>Learn to move objects using keyboard input by writing the script</li>
            <li>Learn to place obstacles in the game and set their positions</li>
            <li>Learn to add repeating backgrounds in a game</li>
            <li>Learn to add sound and effects in a game</li>
         </ul>
         
         <div>
           <p  className='moduleSection' >
               <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
               Game Development (Unity) Course
           </p>
         </div>

         {/* Module one */}
         <div className="modules">
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               1
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
             Getting Started with Unity
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                Students will get familiar with the Unity platform. They will learn about conditional and mathematical operators to create their own interactive game. They will be able to write a script to select and control the object and also understand the importance of variables and data types in coding.
               </p>
             </p>
           </div>
         </div>

         {/* Module  two */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               2
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Basics of Game Development
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                Students will learn about the application to conditional statements and functions in coding by implementing a game in which a player controls the entity by moving left and right using a keyboard and catching the food by keeping the entity in a boundary.
               </p>
              
             </p>
           </div>
         </div>

         {/* Module  Three */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               3
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Advanced Game Development
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >          
                Students will learn about the purpose of using operators like arithmetic, boolean and comparison used in coding. They will add repeating backgrounds to make the game more interactive for the players. Moreover, they will also learn to add sound and effects in a game by developing a side scrolling runner game. 
               </p>
              
             </p>
           </div>
         </div>
         <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
             Learn Game Development with 30+ Fun Activities
             </b>
         </h6>

         <div className="activityImagesContainer">
          <div class="row">
          <div class="col-lg mx-3">
                <img src={pic2} alt="" className='ActivityImages'/>
                <p className='mx-3'>Catch The Food Game</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages'/>
              <p className='mx-3'>Runner Game</p>
            </div>
            <div class="col-lg mx-3">
                <img src={pic4} alt="" className='ActivityImages'/>
                <p className='mx-3'>Car Racing Game</p>
            </div>
            <div class="col-lg mx-3">
               <img src={pic5} alt="" className='ActivityImages'/>
               <p className='mx-3'>Falling Balls Game</p>
            </div>
            <div class="col-lg mx-3">
               <img src={pic6} alt=""  className='ActivityImages'/>
               <p className='mx-3'>Airplane Game</p>
            </div>
            
          </div>
         </div>
      
      

       </section>

        {/* Fee Structure */}
        <FeeStructure subject="Coding"/>

       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>

                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>

       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q: Is learning game development valuable for kids?" >
                <strong>A: </strong>
                Kids love to play games. Research proves that the use of games in teaching helps improve student learning and motivation. Digital games play an important part in children and adolescent digital literacy and learning. Apart from playing games, kids today have opportunities to learn how to develop games. The learning about the process of game development involves a multi-disciplinary approach. Game development is a highly useful skill for kids. It helps them cultivate not only digital skills but also other important 21st Century Skills such as creativity, problem-solving, storytelling, and narration skills. Game development is considered a lucrative career for all ages.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={2} headerTitle="Q: How can kids learn game development?" >
            <strong>A: </strong>
            Kids are naturally inclined towards play from an early age. Learning to develop codes for games can expose them to game design concepts, game development fundamentals, and creating stories/narratives for games. They also get an opportunity to learn about different phases of game development and apply the concepts for developing their own games using various platforms suitable for kids.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: Can kids design digital games?" >
            <strong>A: </strong>
            Of course! Kids are quick learners. They love to play games, and the availability of easy-to-use platforms and tools today makes it a fun learning experience for kids aged 6+. Under the supervision of trained Instructors of Polymath-kids.com, kids can easily start by learning to code existing games. There are modules appropriate for each age group to help them learn game design and development basics.
             </MDBAccordionItem>

            <MDBAccordionItem collapseId={4} headerTitle="Q: Which is the best game development course for kids?" >
            <strong>A: </strong>
            Game Development course by Polymath-kids.com is the best game development course for kids. It offers a variety of basic and advanced gaming concepts. Students are offered modules according to their age and interests. Students get the opportunity to attend live online classes with experts in the field, in a structured manner. In each class, students learn about a new gaming concept and apply it to see how it works. The liveliness, fun communication, and collaboration nurture the passion for learning in kids. The platform offers a range of quizzes, activities, and projects to enable kids to learn the fundamentals of game design and game development. Kids learn a variety of game development concepts such as conditioning, loops, variables, events, adding up scores, and concepts required for developing multi-player and multi-level games.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={5} headerTitle="Q: At what age should kids learn game development?" >
            <strong>A: </strong>
            Kids, as young as 5 years of age, can start to learn the fundamentals of game development. Popular Game development platforms involve a variety of visual components, so young kids can start with block-based platforms as it is visually easy to understand.
            </MDBAccordionItem>

           </MDBAccordion>
         </MDBContainer>

       </div>

        {/* Footer */}
        <Footer/>

   </div>
  )
}

export default Unity
