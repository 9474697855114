import React, { useEffect,useRef, useState } from 'react';
import axios from 'axios';

import {Link} from 'react-router-dom'
import Logo from '../CourseImages/Logo.png'
import './Nav.css'
import AttendClassModal from './AttendClassModal';
import { fetchImageData } from './fetchImage';
import dummy_user from "./dummy_user.png";

const AdminNav = ({username,avatar,onLogout}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const dropdownRef = useRef(null);
  const [imageData, setImageData] = useState(null);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSelectedCourse(null);
  };

  // const handleCourseSelection = (Course_id) => {
  //   setIsOpen2(!isOpen2);
  //   setSelectedCourse(Course_id);
  // };
  const handleCourseSelection = (courseId) => {
    setSelectedCourse(courseId);
    setIsOpen2(prevState => prevState !== courseId);
    // onCourseSelect(courseId); // Not sure what this function does, but I assume it handles course selection.
  };
  
  useEffect(() => {  
    fetchImageData(setImageData,avatar);

  axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
    .then(response => { 
      setCourses(response.data.courses);
    })
    .catch(error => {
      console.error('Error fetching courses:', error);
    });
}, []); 

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);
  const [courses, setCourses] = useState([]);

  return (
    <div style={{width:"100%"}} >

    <nav class="navbar navbar-expand-lg navbar-light "style={{backgroundColor:"#edffff"}} >
      <div class="container-fluid">
        <img src={Logo} alt="Logo" className="pageLogo" />

        <button style={{marginLeft:"auto"}} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse mx-3 Navbar-Right-Aligned" id="navbarSupportedContent" >
        <ul class="navbar-nav" style={{marginLeft:"10%"}}>
          <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">Home
                    <span class="sr-only">
                    (current)
                    </span>
                  </Link>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Users
            </a>
            <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
              <Link class="dropdown-item UserDropdown" to="/ManageUser">Manage Users</Link>
              <Link class="dropdown-item UserDropdown" to="/CourseRegistration">Course Registration</Link>
              <Link class="dropdown-item UserDropdown" to="">Potential Customers</Link>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Fees
            </a>
            <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
              {/* <a class="dropdown-item UserDropdown" href="#">Fee Config</a> */}
              <Link class="nav-link navLinks" to="/FeeConfig">
                    Fee Config
                  </Link>
                  <Link class="nav-link navLinks" to="/StudentFee">
                    Student Fee
                  </Link>
              {/*<a class="dropdown-item UserDropdown" href="/StudentFee">Student Fee</a>*/}
            </div>
          </li>
          <li className="dropdown-container" ref={dropdownRef}>
      <a className="nav-link dropdown-toggle navLinks" onClick={toggleDropdown}>
        Courses
      </a>
      {isOpen && (
        <li className="dropdown-menu-scrollable dropdown-menu_">
          {courses.map((course) => (
            <li key={course.Course_id}>
              <a
                className={`nav-link navLinks dropdown-item_ ${selectedCourse === course.Course_id ? 'active' : ''}`}
                onClick={() => handleCourseSelection(course.Course_id)}
                style={{ padding: "0" }}>
                <span style={{ width: "30vh", padding: "1vh" }} className="dropdown-toggle course-text">{course.coursename}</span>
              </a>
              {isOpen2 && selectedCourse === course.Course_id && (
                <li className="nested-dropdown-menu_">
                  <Link to="/admin_levels_coding" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className=" dropdown-item_ ">Manage Content</Link> <br />
                  <Link to={`/admin_manage_classes/${course.Course_id}/${course.coursename}`} style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">Manage Classes</Link><br />
                  <Link to="#" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">Student Progress</Link><br />
                  <a
                    style={{ color: "#03b4c6", paddingLeft: "4vh", cursor: "pointer" }}
                    className="dropdown-menu-scrollable dropdown-item_ "
                    onClick={handleOpenDialog}
                  >
                    Attend Class
                  </a> <br />
                  <Link to={`/gen_certificate/${course.Course_id}/${course.coursename}`} style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">Generate Certificate</Link><br />
                  <Link to={`/gen_certificate2`} style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">Generate Event Certificate</Link><br />

                </li>
              )}
            </li>
          ))}
        </li>
      )}
      {isDialogOpen && (
        <AttendClassModal isOpen={isDialogOpen} onClose={handleCloseDialog}
          classes={courses} selectedCourse={selectedCourse} />
      )}
          </li>
        <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Teachers
        </a>
        <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
          <Link class="dropdown-item UserDropdown" to="/TeacherProfile">Profile</Link>
          <Link class="dropdown-item UserDropdown" to="/TeacherRating">Rating</Link>
          <Link class="dropdown-item UserDropdown" to="/TeacherSalary">Salary</Link>
        </div>
      </li>

      <li class="nav-item ">
              <a class="nav-link navLinks" href="#">Gift Redeem</a>
      </li>

      <li class="nav-item ">
              <a class="nav-link navLinks" href="#">Communication</a>
      </li>

      
      <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/calenderview">
                    My Calender View
                  </Link>
      </li>
              

      <li class="nav-item dropdown">
      <a className="nav-link dropdown-toggle navLinks navLinksArrowHide" style={{backgroundColor:'transparent', padding:'0'}} href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {imageData ? (
                    <img
                      src={imageData}
                      alt="Avatar"
                      className="avatar-image"
                    />
                  ) : (
                    <img src={dummy_user} alt="Default Avatar" className="avatar-image" /> 
                  )}
      </a>
        <div class="dropdown-menu user-profile-dropdown" style={{left:'-13vh'}} aria-labelledby="navbarDropdownMenuLink">
          <Link class="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
          <Link class="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
          <Link class="dropdown-item UserDropdown" to="/AdminEdit" >Edit Profile</Link>

        </div>
      </li>
      </ul>

        </div>
      </div>
    </nav>

    </div>
  )
}

export default AdminNav
