import React, { useEffect, useContext } from 'react';
import './PolymatchDetail.css';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import { Link } from "react-router-dom";
import { UserContext } from '../UserContext';
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom';
import polymatchImage from './Polymatch.png'; // Polymatch Logo Image
import WebFont from 'webfontloader';

const PolymatchDetail = () => {
    const { isAdmin, isTeacher, isStudent, isGuest, userInfo, setUserRole, setUserInfo } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        WebFont.load({
            google: {
              families: ['Baumans']
            }
          });
        const fetchData = async () => {
            await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
                credentials: 'include',
            }).then(response => {
                response.json().then(userInfo => {
                    setUserInfo(userInfo);
                    if (userInfo?.usertype) {
                        setUserRole(userInfo.usertype);
                    }
                });
            }).catch(err => console.log("Error in useEffect:", err));
        };
        fetchData();
    }, [setUserInfo, setUserRole]);

    const onLogout = () => {
        fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
        });
        setUserInfo(null);
        setUserRole('guest');
        navigate('/');
    };

    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;

    return (
        <>
            {username && (
                <>
                    {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
                    {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
                    {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
                </>
            )}
            {!username && isGuest && <GuestNav />}

            <div className="PolymatchBgg">  {/* This applies the background image */}
                {/*<div className="PolymatchSection_11">*/}
                        <img src={polymatchImage} alt="Polymatch Logo" className="polymatch-image" />
                        <h1>PolyMatch 2024 Mental Math Competition</h1> <div className="event-date-circle">
                            <span>9 Nov 2024</span>
                        </div>
                        {/*</div>*/}
                        <div className="PolymatchSection_12">
                            
                            <p>
                                Get ready to showcase your mental math prowess at the global stage with our PolyMatch Mental Math Competition.
                                Mark the date: <strong>9th November 2024</strong>.
                            </p>
                            </div> 
                            
                            <br></br>
                            <Link to="/Polymatch" className="polymatch details">Click here to view our previous PolyMatch Competitions </Link>
                            
                        
                    </div>

                    <div className="detail-section">
                    <h1>General Disclaimers</h1>
                    <p>
                        <strong>Network Issues:</strong> We are not liable for any issues arising due to network problems during the event.
                        Participants are advised to ensure a stable internet connection.
                    </p>
                    <p>
                        <strong>Contestant Authentication:</strong> Contestants must attempt the competition only from their registered email
                        ID on the app. User IDs and steps to change passwords will be communicated via email.
                    </p>
                    <p>
                        <strong>Tie-Breaker Procedures:</strong> In the case of a tie in the Mandatory Challenge at the World Level, all Super Toppers with identical marks will participate in a Tie-Breaker round at the discretion of the Live Math Competitions and League organizers.
                        In the event of a tie in any Optional Challenges, a Tie-Breaker will be conducted to determine the Top 25 Super Toppers at the World Level.

                    </p>
                    <p>
                        <strong>Multiple Registrations:</strong>Parents registering a second ward are required to use a different email address for each registration.
                           </p>
                    <p>
                        <strong>Dispute Resolution:</strong> In case of any disputes or issues related to the competition, the organizers of Live Math Competitions and League reserve the right to make the final decision.

                    </p> <p>
                        <strong>Terms and Conditions:</strong>The organizers reserve the right to modify the terms and conditions of the competition at any time without prior notice.
 
                    </p>
                    <p>
                        <strong>No Refund Policy:</strong> We regret to inform you that our events operate under a strict no-refund policy. Once registration is confirmed, no refunds will be provided for any reason.

                    </p>
                    <p>
                        <strong>Cancellation and Modification:</strong> Participants are reminded that registrations, once confirmed, cannot be cancelled or modified. We appreciate your understanding in adhering to this policy.

                    </p>
                    <p>
                        <strong>Event Changes:</strong> The organizers reserve the right to make changes to event dates, formats, or any other relevant aspects. Participants will be duly notified of any such changes.

                    </p>
                    <p>
                        <strong>Force Majeure:</strong> In the event of unforeseen circumstances, including but not limited to natural disasters, pandemics, or other force majeure situations, the organizers may adjust or cancel events as deemed necessary. In such cases, no refunds will be issued.

                    </p>
                    <p>
                        <strong>Acknowledgment:</strong> By registering for our events, participants acknowledge and agree to comply with the no-refund policy outlined herein.
                        We appreciate your cooperation and understanding. If you have any concerns or require further clarification, please feel free to contact our support team.

                    </p>
                    <p>
                        <strong>Information Sources:</strong> Schools and parents are encouraged to stay updated on mathematics-related news and updates by following our Facebook Page, “Live Math Competitions and League.”

                    </p>
                    <p>
                        <strong>Online Resource:</strong> For general information and clarification about the competition, please refer to our official website: www.polymath-kids.com.

                    </p>
                    <p>
                    These disclaimers are designed to ensure a fair and smooth experience for all participants. Please review them carefully before participating in the PolyMatch Mental Math Competition 2024.
                    </p>
                    
                    
                </div> <br></br>
                <h1><center>STAY TUNED FOR MORE COMPETITIONS</center></h1>
            {/*</div>*/}

            <Footer />
        </>
    );
};

export default PolymatchDetail;
