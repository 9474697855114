import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CodingCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./GameDev.css";
import "./ScratchProgramming.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CodingCourseImages/gamedev.png";
import pic2 from "./CodingCourseImages/basketBall.png";
import pic3 from "./CodingCourseImages/dragonLand.png";
import pic4 from "./CodingCourseImages/minecraft.png";
import pic5 from "./CodingCourseImages/soccer.png";
import pic6 from "./CodingCourseImages/superJumper.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import GDC from './CodingCourseImages/game.png'

const GameDev = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
  return (

    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
   
    <div className="Gamedev-Background">
      <section className="section">
        <p className="PageHead"style={{ color: "#8b0194" }}>Game Development Course</p>
        <div className="PageDesc">
        <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
          Kids love to play games. To Nurture Brainiacs and introduce young minds to the world of coding games, Polymath-Kids offers a comprehensive Game Development Course for kids. Polymath-Kids specialized Game Development Course help a child think out of the box by boosting the child’s thinking and cognitive skills. Imagine your child creating their own exciting games! 
          </p>
          <img style={{minWidth:"250px", maxWidth:"450px"}} src={GDC}></img>
          </div>
          <div className="btn-row d-flex btn-row-resp btn-row-resp1">
            {/* <div className="btn-row-resp"> */}
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className="btn-row-resp button btn-color-course"
                href="#course"
              >
                Course Structure
              </a>
            </div>

            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-fee" href="#fee">
                Fee Structure
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-Testimonal"
                href="#testimonal"
              >
                Testimonals
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                Faqs
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-registered"
                href="#"
              >
                Get Registered
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="overlay"></div>
    </div>

    {/* Intro text */}
    <div className="scratch-background">
      <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}>
        What Polymath-Kids Game Development offers?
        </p>
        <div className="PageDesc">
          <p
            style={{
              color: "#8b0194",
              marginTop: "2vh",
              fontSize: "4vh",
              fontFamily: "sans serif",
            }}
          >
           Polymath-Kids provide online live classes for kids aged 6 to14. With the assistance of trained instructors, kids learn to develop a variety of fun games and learn to code them. Developing games is only one learning outcome of the course along with nurturing many other 21st Century skills such as problem solving, creativity, communication, storytelling, critical thinking and collaboration.
          </p>
        </div>
      </section>
    </div>

    {/* Course Structure and Syllabus */}
    <section className="section course-structure" id="course">
      <p className="PageHead" style={{ color: "#8b0194" }}>
        Course Structure and Syllabus
      </p>
      <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Course Objectives</b>
      </h4>
      <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <li>Fundamentals of coding and game development principles</li>
        <li>Fostering creativity, storytelling and narration skills</li>
        <li>Exposure to a variety of Game development platforms</li>
        <li> Game design concepts</li>
      </ul>

      <div>
        <p className='moduleSection'>
          <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
          Game Development Beginner Course
        </p>
      </div>

      {/* Module one */}
      <div className="modules">
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            1
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
          Fundamentals of Game Development 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              Younger students will get familiar with fundamentals of game development. They will learn how they can move a character and add different affects to their game. They will learn the essential theoretical knowledge of game development. 
            </p>
          </p>
        </div>
      </div>

      {/* Module  two */}
      <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            2
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box  sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Simple Games 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
             In this module students will learn how to create a simple game using templates. They will get familiar with use of conditions and loops in game development. By the end of the module learners will be able to edit the templates of a game and change it as per their own imagination.
            </p>
          </p>
        </div>
      </div>

      {/* Module  Three */}
      <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            3
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
          </p>

          <div
            className="small-box  sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Arcade Games 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
            In Module 3, students will utilize their skills to deal with multi-player games. They will learn the concepts of Physics such as motion and gravity. They will also incorporate the numerical skills and learn to code adding up scores of multiplayers.
            </p>
          </p>
        </div>
      </div>

      {/* Module one */}
      <div>
        <p className='moduleSection' style={{color: "#485cd9",}}>
          <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
          Game Development Advance Course
        </p>
      </div>

      <div className="modules">
        {/* round box */}
        <div className="round-box round-box1 ">
          <p style={{ marginTop: "5vh", color: "#d9defb" }}>
            {" "}
            Module
            <br />
            1
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box small-box1 sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>
          </div>
        </div>

        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
          Gaming Concepts 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              Module 1 will prepare students to design Interesting Games using concepts of Conditions, Loops, movement and adding up scores. They will also get familiar with how to set the sequence of the code. 
            </p>
          </p>
        </div>
      </div>

      {/* Module two */}
      <div className="modules my-3">
        {/* round box */}
        <div className="round-box round-box1 ">
          <p style={{ marginTop: "5vh", color: "#d9defb" }}>
            {" "}
            Module
            <br />
            2
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box small-box1 sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>
          </div>
        </div>

        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
          Advanced Gaming Concepts 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
Module 2 provides the exciting opportunity for students to get introduced with the concept of creating the complete game including interface and functioning. They will get familiar with the important relevant terms such as “Variable” and “Event”, and their applicability in game development.
            </p>
          </p>
        </div>
      </div>

      {/* Module three */}
      <div className="modules my-3">
        {/* round box */}
        <div className="round-box round-box1 ">
          <p style={{ marginTop: "5vh", color: "#d9defb" }}>
            {" "}
            Module
            <br />
            3
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box small-box1 sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>
          </div>
        </div>

        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
          Multi Level Games 
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              (This module is offered only to students having prior knowledge of Advanced Scratch Concepts)
              Students will work on the advance concepts of Scratch. In this module, kids will learn how to add multi-levels in game. They will learn how to set up the interface of game and they will also work on how to switch between levels.
            </p>
          </p>
        </div>
      </div>

      <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Learn Game Development with 50+ Fun Activities</b>
      </h6>

      <div className="activityImagesContainer">
        <div class="row">
          <div class="col-lg mx-3">
            <img src={pic2} alt="" className='ActivityImages' />
            <p className="mx-3" >Basket Ball Game</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic3} alt="" className='ActivityImages'/>
            <p className="mx-3">Dragon Land Game</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic4} alt="" className='ActivityImages'/>
            <p className="mx-3">MineCraft Game</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic5} alt="" className='ActivityImages'/>
            <p className="mx-3">Multiplayer Soccer Game</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic6} alt="" className='ActivityImages'/>
            <p className="mx-3">Super Jumper Game</p>
          </div>

        </div>
      </div>
    </section>

    {/* Fee Structure */}
    <FeeStructure subject="Coding"/>

    {/* Testimonals */}
    <div class="  background" id="testimonal">
      <div class="row" style={{ width: "100%" }}>
        <div class="col-lg-8 mx-auto">
          <h2 className="Testimonal_Heading">Testimonials</h2>
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            {/* <!-- Carousel indicators --> */}
            <ol class="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
            </ol>
            {/* <!-- Wrapper for carousel items --> */}
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img-box">
                  <div className="img-box1"></div>
                </div>
                <p
                  class="testimonial"
                  style={{ color: "black", fontSize: "3vh" }}
                >
                  I have learned so much from EduTechSage-Abacus. It is grreat. The teacher is awesome.
                </p>
                <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                  <b>Falah Hamad Sahaf</b>
                </p>
              </div>

             
            </div>
            {/* <!-- Carousel controls --> */}
            <a
              class="carousel-control-prev"
              href="#myCarousel"
              data-slide="prev"
            >
              <i class="fa fa-angle-left"></i>
            </a>
            <a
              class="carousel-control-next"
              href="#myCarousel"
              data-slide="next"
            >
              <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Faqs */}
    <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>
            {" "}
            FAQs
          </div>
          <MDBAccordionItem
            collapseId={1}
            headerTitle="Q: Is learning game development valuable for kids?"
          >
            <strong>A: </strong>
            Kids love to play games. Research proves that use of games in teaching help improve student learning and motivation.  Digital games play an important part in children and adolescent digital literacy and learning. Apart from playing games, kids today have opportunities to learn how to develop games. The learning about the process of game development involves multi-disciplinary approach. Game development is highly useful skill for kids. It help them cultivate not only the digital skills but also other important 21st Century Skills such as creativity, problem solving, story-telling and narration skills. Game development is considered a lucrative career for all ages.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="Q: How can kids learn game development?"
          >
            <strong>A: </strong>
            Kids are naturally inclined towards play from an early age. Learning to develop codes for games can expose them to game design concepts, game development fundamentals and creating stories/ narratives for games. They also get an opportunity to learn about different phases of game development and apply the concepts for developing their own games using various platforms suitable for kids. 
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Can kids design digital games?"
          >
            <strong>A: </strong>
            Of Course! Kids are quick learners. They love to play games and the availability of easy to use platforms and tools today, make it a fun learning experience for kids aged 6+. Under the supervision of trained Instructors of Polymath-kids.com, kids can easily start by learning to code existing games. There are Modules appropriate for each age group to help them learn game design and development basics. 
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Which is the best game development course for kids?"
          >
            <strong>A: </strong>
            Game Development course by Polymath-kids.com is the best game development course for kids. It offers a variety of basic and advance gaming concepts. Students are offered modules according to their age and interests. Students get the opportunity to attend live online classes with experts in the field, in a structured manner. In each class, students learn about a new gaming concept and apply it to see how it works. The liveliness, fun communication and collaboration nurture the passion for learning in kids. 
            <br />
            The platform offers a range of quizzes, activities and projects to enable kids to learn the fundamentals of game design and game development. Kids learn a variety of game development concepts such as conditioning, loops, variables, events, adding up scores and concepts required for developing multi-player and multi-level games.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: At what age should kids learn game development?"
          >
            <strong>A: </strong>
            Kids, as young as 5 years of age, can start to learn the fundamentals of game development. Popular Game development platforms involve a variety of visual components, so young kids can start with block-based platforms as it is visually easy to understand.

            Polymath-Kids.com is market leader in grooming children's cognitive, leadership, social and people skills through our structured courses designed by our team of subject matter experts. We help your kids become a Polymath!


          </MDBAccordionItem>

         
        </MDBAccordion>
      </MDBContainer>
    </div>
    
{/* Footer */}
<Footer/>

  </div>
  )
}

export default GameDev
