
import './changePassword.css'

import React, { useContext, useEffect, useState } from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import TeacherNav from "../Navbars/TeacherNav";
import EditProfile from './EditProfile'
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import ResetPwPopup from '../ResetPassword/ResetPwPopup';


const PasswordForget = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email,setEmail] = useState('')
  useEffect(()=>{
    const mail = sessionStorage.getItem('email')
    setEmail(mail)
  },[])
  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Make API call to check old password
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/checkOldPassword`, {
        method: 'POST',
        body: JSON.stringify({ oldPassword, User_id }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (1
        //response.ok
      ) {
        // Password matches
        if (newPassword.length > 0 && confirmPassword.length > 0) {
          if (newPassword === confirmPassword) {
            // Passwords match, call the saveNewPassword function
            await saveNewPassword(email, newPassword);

            toast.success('Password updated successfully!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            // Reset form fields
            // setOldPassword('');
            // setNewPassword('');
            // setConfirmPassword('');
          }
          else {
            // Passwords do not match
            toast.error('Password does not match with confirm password.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        }
        else {
          toast.error('Password field can not be empty.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }

      } else {
        // Handle incorrect password
        toast.error('Old password is incorrect.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Error checking old password:', error);
      toast.error('An error occurred while checking the old password. Please try again later.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };

  const saveNewPassword = async (userId, newPassword) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateForgetPassword`, {
        method: 'PUT',
        body: JSON.stringify({ newPassword, userId }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        // Handle error updating password
        console.error('Error updating password:', response.status);
        toast.error('An error occurred while updating the password. Please try again later.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('An error occurred while updating the password. Please try again later.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };


  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        console.log("Response:", response);
        response.json().then(userInfo => {
          console.log("userinfo:", userInfo);
          setUserInfo(userInfo);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const navigate = useNavigate();

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    navigate('/');
    setUserRole('guest');
  }
  const determineUserRole = (email, password) => {
    return "teacher";
  };
  const onLogin = (usertype) => {

    setUserRole(usertype);

  };

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const User_id = userInfo?.User_id;


  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}

        </>
      )}


      <div className='edit-bar' >
        <h3 className='edit-heading '>
          <i class="fa fa-gear fa-spin"></i>

          Forget Password

        </h3>
      </div>

      <div class="ChangePasswordContainer justify-content-center">
        <form action="" className='ChangePasswordForm'>
          <h3>Change Password</h3>
          {/* <div class="row" >
       
      <div class="col75">
      <input type="password" className='PasswordFields'
        id="oldpassword"
        name="oldpassword"
        placeholder="Old Password" 
        // value={oldPassword} 
        onChange={(e) => setOldPassword(e.target.value)} />
      </div>
    </div> */}
          <div class="row">
            <div class="col75">
              <input type="password" className='PasswordFields'
                id="NewPassword"
                name="NewPassword"
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)} />
            </div>
          </div>


          <div class="row">

            <div class="col75">
              <input type="password" className='PasswordFields'
                id="ConfirmNewPassword"
                name="ConfirmNewPassword"
                placeholder="Confirm New Password"
                onChange={(e) => setConfirmPassword(e.target.value)} />
            </div>
          </div>


          <div class="row">
            <input type="submit" value="Change Password" className='ChangePasswordSubmitBtn' onClick={handleChangePassword} />
            {/* <a className="forgotPassword"
              onClick={handleOpenDialog}
              style={{ color: '#03b4c6', cursor: 'pointer' }}>
              Forgot Password?
            </a> */}
            {isDialogOpen && (
              <ResetPwPopup isOpen={isDialogOpen} onClose={handleCloseDialog}
              />
            )}
          </div>
        </form>
      </div>
      {/* Footer */}
      <Footer />

    </>
  )
}

export default PasswordForget
