import React from 'react';
import './UnAuthorized.css';

const UnAuthorized = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-box">
        <h1 className="unauthorized-title">Sorry! Unauthorized</h1>
        <p className="unauthorized-message">You are not authorized to view this page. Please login with valid credentials.</p>
      </div>
    </div>
  );
};

export default UnAuthorized;
