import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import "./managecontentstyle/managecontent.css";
import { toast } from "react-toastify";

function EditLessonActivity({ activity, handleClose }) {
  const [course, setCourse] = useState("");
  const [module, setModule] = useState("");
  const [lesson, setLesson] = useState("");
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [activityName, setActivityName] = useState(activity.ActivityName);
  const [description, setDescription] = useState(activity.ActivityDescription);
  const [activityUrl, setActivityUrl] = useState(activity.ActivityLink);
  const [activityType, setActivityType] = useState(activity.ActivityType);
  const [completionDate, setCompletionDate] = useState(activity.CompletionDate);
  const [instruction, setInstruction] = useState(activity.Instructions);
  const [orderBy, setOrderBy] = useState(activity.OrderBy);
  const [totalTime, setTotalTime] = useState(activity.TotalTime);
  const [remarks, setRemarks] = useState(activity.Remarks);
  const [score, setScore] = useState(activity.Score);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/api/managecontent//getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance
      .post("/api/managecontent//getAllLesson", { moduleId })
      .then((response) => {
        const filteredLessons = response.data.filter(
          (lesson) => lesson.Module_id === parseInt(moduleId)
        );
        setLessons(filteredLessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLesson(lessonId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      Lesson_id: activity.Lesson_id,
      ActivityDescription: description,
      ActivityLink: activityUrl,
      ActivityName: activityName,
      ActivityType: activityType,
      CompletionDate: completionDate,
      Instructions: instruction,
      OrderBy: orderBy,
      TotalTime: totalTime,
      Remarks: remarks,
      Score: score,
      Lesson_Activity_id: activity.Lesson_Activity_id,
    };

    axiosInstance
      .post("/api/managecourse/updateLessonActivity", payload)
      .then((response) => {
        console.log(response.data);
        setCourse("");
        setModule("");
        setLesson("");
        setActivityName("");
        setDescription("");
        setActivityUrl("");
        setActivityType("");
        setCompletionDate("");
        setInstruction("");
        setOrderBy("");
        setTotalTime("");
        setRemarks("");
        setScore("");

        toast.success("Lesson Activity has been Updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="modalStyle">
      <h2>Edit Lesson Activity</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="activity-name-input">Activity Name</Label>
          <Input
            type="text"
            name="activityName"
            id="activity-name-input"
            placeholder="Enter activity name"
            value={activityName}
            required
            onChange={(e) => setActivityName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description-input">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description-input"
            placeholder="Enter description"
            value={description}
            
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="activity-url-input">Activity URL</Label>
          <Input
            type="text"
            name="activityUrl"
            id="activity-url-input"
            placeholder="Enter activity URL"
            value={activityUrl}
            
            onChange={(e) => setActivityUrl(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="activity-type-input">Activity Type</Label>
          <Input
            type="select"
            name="activityType"
            id="activity-type-input"
            placeholder="Select activity type"
            value={activityType}
            required
            onChange={(e) => setActivityType(e.target.value)}
          >
            <option>Select activity type</option>
            <option>Oral</option>
            <option>Video</option>
            <option>Written</option>
            <option>MCQ</option>
            <option>Speed Quiz</option>
            <option>Mental</option>
            <option>Game</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="instruction-input">Instruction</Label>
          <Input
            type="textarea"
            name="instruction"
            id="instruction-input"
            placeholder="Enter instruction"
            value={instruction}
            
            onChange={(e) => setInstruction(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="orderby-input">Order By</Label>
          <input
            type="number"
            min="0"
            name="orderby"
            id="orderby-input"
            placeholder="Enter order by"
            value={orderBy}
            
            onChange={(e) => setOrderBy(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="totaltime-input">Total Time</Label>
          <input
            type="number"
            min="0"
            name="totaltime"
            id="totaltime-input"
            placeholder="Enter total time"
            value={totalTime}
            
            onChange={(e) => setTotalTime(e.target.value)}
          />
        </FormGroup>
        <div className="my-container">
          <Button variant="primary" type="submit" className="save-button">
            Save
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
            className="save-button"
          >
            Close
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditLessonActivity;
