import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./ML.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import pic1 from './CodingCourseImages/MLlogo.png'
import pic2 from './CodingCourseImages/datacleaning.png'
import pic3 from './CodingCourseImages/clustring.png'
import pic4 from './CodingCourseImages/dataClassification.png'
import pic5 from './CodingCourseImages/svm.png'
import pic6 from './CodingCourseImages/CNN.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import MLC from './CodingCourseImages/MLbackground.png'

const ML = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }  
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
  return (
    <div >
    {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
 
      <div className="ML-Background">
         <section className="section">
           <p className="PageHead"style={{ color: "#8b0194" }}>Machine Learning Course</p>
           <div className="PageDesc">
           <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
             Polymath-kids offer comprehensive online Machine Learning course for kids aged 14 to 18 year. The online classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.
             </p>
             <img style={{minWidth:"250px", maxWidth:"450px"}} src={MLC}></img>
             </div>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">

               {/* <div className="btn-row-resp"> */}
               <div style={{ width: "auto", height: "50px" }}>


                 <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
               </div>
              
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

               </div>
               <div style={{ width: "auto", height: "50px" }}>

                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                

               </div>


          
             </div>
           </div>

         </section>
         <div className="overlay"></div>
       </div>

        {/* Intro text */}
       <div className='scratch-background'>
       <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>What is Machine Learning?</p>
           <div className="PageDesc">
             <p style={{
                 color: "#8b0194",
                 marginTop: "2vh",
                 fontSize: "4vh",
                 fontFamily: "sans serif",
               }}>
              Data Engineering, Data Science, and Artificial Intelligence are hot topics in the current digital age. These technologies have changed the way humans interpret a problem. These technologies work on data, but utilize it for different outcomes. Data Science and Artificial Intelligence are technologies that correlate with each other in many ways. Artificial Intelligence in Data Science as a function has taken over technological automation but requires Data Engineering in symphony to function properly. There are constant advancements in the fields of Data Science and Artificial Intelligence and they are said to bring the 4th revolution in the industry.<br />
              The technologies are related to each other in more ways than one. Data Engineering deals with the collection and preparation of data so that it can be used by Artificial Intelligence in Data Science applications. Data Science utilizes this data and predictively and analyzes it to gain insights. Artificial Intelligence deals with working on data by using tools to develop Intelligent systems. Data Science and Artificial Intelligence work on data to produce similar outcomes dealing with analysis.
                <br />
              The main purpose of Artificial Intelligence is to aid human capabilities and predict the far-fetched consequences that the human brain cannot process. Artificial Intelligence has the potential to reduce the hardships of human labor and make a possible pathway for the human species to thrive in a beneficial way. Artificial Intelligence in Data Science has similar purposes.<br />

              With development still ongoing in this field the scope of applications increases with every iteration. Artificial Intelligence in Data Science is often used in real-life use. A few prominent applications of Artificial Intelligence are:<br />
            * Personalized Online Shopping<br />
            * Enhanced Imaging and Surveillance <br />
            * Video Games<br />
            * Healthcare<br />
            * ChatBots<br />

              The main purpose of Data Science is to find patterns in data. It is used to analyze and gain insights using various Statistical Techniques. The present data and historical data are used to predictively analyze future outcomes. These valuable predictions and Insights provide an opportunity for businesses to thrive and adapt based on market trends.<br />

              Data Science works on the data and since the quantity of data is growing at a very fast pace, its benefits are also increasing at a good pace. Artificial Intelligence in Data Science methods works prominently on this growing data.<br />

                A few prominent applications of Data Science are:<br />
                * Banking <br />
                * Manufacturing<br />
                * Transport<br />
                * Healthcare<br />
                * E-Commerce<br />
             </p>    
           </div>
         </section>
       </div>
       
       {/* Course Structure and Syllabus */}
       <section className="section course-structure" id="course">
         <p className="PageHead" style={{ color: "#8b0194" }}>
           Course Structure and Syllabus
         </p>
         <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
               Course Objectives
             </b>
         </h4>
         <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
           <li>Learn the expertise of Machine Learning using Python Programming:</li>
             <p>          
                {'> '}Fundamentals and advanced concepts of machine learning.<br />
                {'> '}Data preparation and preprocessing.<br />
                {'> '}Supervised learning techniques for regression and classification tasks.<br />
                {'> '}Unsupervised learning methods such as clustering, dimensionality reduction, <br /> anomaly detection, and association rule learning.<br />
                {'> '}Deep learning, including convolutional neural networks (CNNs) 
                <br />
                for image and video recognition, recurrent neural networks (RNNs) for sequential data, and generative models.<br />
                {'> '}Learn basic statistics & probability concepts such as mean, median, & mode essential for analyzing data.<br />
                {'> '}Understand how these algorithms make predictions and classify data.<br />
                {'> '}Learn how to evaluate the performance of machine learning models.<br />
                {'> '}Students will be able to apply machine learning to real-world problems & make data-driven decisions.<br />

             </p>
         <li>Develop 21st Century Skills using text based Programming:<br /></li>
             <p>          
             {'> '}Logic application and self-expression<br />
             {'> '}Creativity and Problem-Solving Skills<br />
             {'> '}Mathematical, Computational and Analytical Thinking<br />
             {'> '}Persistence<br />
             {'> '}Creativity and Imagination<br />
             {'> '}Digital Literacy<br />
             {'> '}Career-Building Skills<br />
             {'> '}Confidence and Communication Skills<br />
             {'> '}STEM Education<br />
             </p>
         </ul>
         
         <div>
           <p className='moduleSection' >
               <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
               Machine Learning
           </p>
         </div>

         {/* Module one */}
         <div className="modules">
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               1
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 11
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
             Fundamentals o Machine Learning
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                Students will be introduced to the concepts of data preparation and preprocessing for machine learning, as well as supervised learning techniques for regression and classification problems. Students will also learn about unsupervised learning methods, including clustering and dimensionality reduction. Additionally, the module will provide an overview of deep learning and reinforcement learning. 
               </p>
             </p>
           </div>
         </div>

         {/* Module  two */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               2
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 11
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Supervised Learning Techniques
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                This module covers supervised learning techniques including decision trees, random forests, SVMs, linear regression & neural networks. Students will gain a strong understanding of these techniques & their applications in solving real-world problems.
               </p>
              
             </p>
           </div>
         </div>

         {/* Module  Three */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               3
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 9
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Un-Supervised Learning Techniques
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               > 
                This module teaches students unsupervised learning techniques including clustering to group similar data points, dimensionality reduction to reduce features while retaining important information, anomaly detection to identify outliers, & association rule learning to discover interesting relationships between variables in a dataset.  
               </p>
              
             </p>
           </div>
         </div>
         {/* Module  Four */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
                4
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 7
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   4
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Deep Learning
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               > 
                    In this module students will learn how to train neural networks with multiple layers to learn complex data representations. They will also learn CNNs for image and video recognition, RNNs for sequential data, & generative models that can create new data.
               </p>
              
             </p>
           </div>
         </div>
         <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
             Learn Machine Learning with 30+ Fun Activities
             </b>
         </h6>

         <div className="activityImagesContainer">
            <div class="row">
              <div class="col-lg mx-3">
                <img src={pic2} alt="" className='ActivityImages'/>
                <p className='mx-3'>Data Cleaning</p>
              </div>
              <div class="col-lg mx-3">
                <img src={pic3} alt="" className='ActivityImages'/>
                <p className='mx-3'>Clustering</p>
              </div>
              <div class="col-lg mx-3">
                <img src={pic4} alt="" className='ActivityImages'/>
                <p className='mx-3'>Data Classification</p>
              </div>
              <div class="col-lg mx-3">
                <img src={pic5} alt="" className='ActivityImages'/>
                <p className='mx-3'>SVM</p>
              </div>
              <div class="col-lg mx-3">
                <img src={pic6} alt=""  className='ActivityImages'/>
                <p className='mx-3'>CNN</p>
              </div>
              
            </div>
         </div>
      
      

       </section>

        {/* Fee Structure */}
        <FeeStructure subject="Coding"/>

       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>

                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>

       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q: What is machine learning?" >
            <strong>A: </strong>
            Machine learning is a field of study that focuses on developing algorithms and models that allow computers to learn from and make predictions or decisions based on data without being explicitly programmed.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={2} headerTitle="Q: Why should I take a machine learning course?" >
            <strong>A: </strong>
            Machine learning has become a crucial skill in various industries, including technology, finance, healthcare, and more. By taking a machine learning course, you can gain the knowledge and skills necessary to understand and apply machine learning algorithms, which can open up new career opportunities.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: What are the prerequisites for a machine learning course?" >
            <strong>A: </strong>
            A strong understanding of programming (preferably Python) is often recommended as a foundation for machine learning.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={4} headerTitle="Q: Can I apply machine learning techniques to my specific field or industry?" >
            <strong>A: </strong>
            Absolutely! Machine learning techniques have applications in various fields, including finance, healthcare, marketing, image and speech recognition, natural language processing, and more. By learning machine learning principles, you can adapt and apply them to solve problems specific to your field.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={5} headerTitle="Q: What career opportunities are available after completing a machine learning course?" >
            <strong>A: </strong>
            Completing a machine learning course can open up several career opportunities, including machine learning engineer, data scientist, research scientist, AI consultant, and more. These roles are in high demand, and organizations are seeking professionals with machine learning skills to develop innovative solutions.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={6} headerTitle="Q: How long does it take to complete the Machine Learning course?" >
            <strong>A: </strong>
            The duration of the Machine Learning course depends on the level of expertise, but generally, it takes around 20 weeks to complete.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={7} headerTitle="Q: How will the course be delivered, and what is the format?" >
            <strong>A: </strong>
            The course will be delivered through online classes, self-paced learning, or a combination of both. The format can be in the form of video lectures, quizzes, assignments, and projects.
            </MDBAccordionItem>

           </MDBAccordion>
         </MDBContainer>

       </div>

        {/* Footer */}
        <Footer/>

   </div>
  )
}

export default ML
