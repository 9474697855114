import React, { useState, useContext, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import StudentNav from "../Navbars/StudentNav";
import "./mycalender.css";
import { Tooltip } from "react-tooltip";

function MyCalendar() {
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        response
          .json()
          .then((userInfo) => {
            setUserInfo(userInfo);

            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar;
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  console.log("User Info: " , userInfo);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = '';
        if (isStudent) {
          apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/api/calenderview/getstudentcalenderview`;
        } else if (isTeacher) {
          apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/api/calenderview/getteachercalenderview`;
        } else if (isAdmin) {
          apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/api/calenderview/getadmincalenderview`;
        } else {
          return; // If not a Student, Teacher, or Admin, no need to fetch data
        }

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ User_id: userInfo.User_id }),
        });

        if (!response.ok) {
          console.error("Failed to fetch calendar data:", response.status);
          return;
        }

        const data = await response.json();

        // Function to get the date of a specific day of the week in the current week
        const getDayDate = (dayName) => {
          const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          const dayIndex = daysOfWeek.indexOf(dayName);
          const today = new Date();
          const todayIndex = today.getDay();
          const diff = (dayIndex - todayIndex + 7) % 7;
          const dayDate = new Date(today);
          dayDate.setDate(today.getDate() + diff);
          return dayDate;
        };

        // Function to parse time with AM/PM format
        const parseTime = (timeString) => {
          const [time, modifier] = timeString.split(' ');
          let [hours, minutes] = time.split(':').map(Number);

          if (modifier === 'PM' && hours < 12) {
            hours += 12;
          } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
          }

          return [hours, minutes];
        };

        // Transform data to fit FullCalendar event structure
        const transformedEvents = data.flatMap((item) => {
          const dayDate = getDayDate(item.ClassDay);
          const [hours, minutes] = parseTime(item.ClassTime);

          // Check if ClassTime is valid
          if (isNaN(hours) || isNaN(minutes)) {
            console.error("Invalid ClassTime value:", item.ClassTime);
            return [];
          }

          // Generate events for the next few weeks
          const weeksToGenerate = 4;
          const events = [];
          for (let i = 0; i < weeksToGenerate; i++) {
            const eventStart = new Date(dayDate);
            eventStart.setHours(hours, minutes);
            eventStart.setDate(dayDate.getDate() + i * 7);

            // Check if eventStart is valid
            if (isNaN(eventStart.getTime())) {
              console.error("Invalid eventStart value:", eventStart);
              continue;
            }

            const eventEnd = new Date(eventStart);
            eventEnd.setMinutes(eventStart.getMinutes() + 60);

            // Check if eventEnd is valid
            if (isNaN(eventEnd.getTime())) {
              console.error("Invalid eventEnd value:", eventEnd);
              continue;
            }

            events.push({
              title: item.CourseName,
              start: eventStart.toISOString(),
              end: eventEnd.toISOString(),
              url: item.ClassLink,
              extendedProps: {
                teacherName: item.TeacherName,
                courseName: item.CourseName,
              },
            });
          }
          return events;
        });

        setEvents(transformedEvents);

      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };




    fetchData();
  }, [isStudent, isTeacher, isAdmin, userInfo.User_id]);

  const determineUserRole = (usertype) => {

    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const [currentuserRole, setCurrentUserRole] = useState("");

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    setUserRole(userRole);
    setCurrentUserRole(userRole);
  };

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <b>{eventInfo.timeText}</b>
        <div data-tip data-for={eventInfo.event.id}>
          {eventInfo.event.title}
        </div>
        <Tooltip id={eventInfo.event.id} place="top" effect="solid">
          <div>Teacher: {eventInfo.event.extendedProps.teacherName}</div>
          <div>Course: {eventInfo.event.extendedProps.courseName}</div>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {username && (
        <>
          {isAdmin && (
            <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />
          )}
          {isTeacher && (
            <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />
          )}
          {isStudent && (
            <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />
          )}
        </>
      )}
      {!username && (
        <>{isGuest && <GuestNav onLogin={onLogin} />}</>
      )}

<div className="calendar-container">
        <div className="calendar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            weekends={true}
            events={events}
            eventContent={renderEventContent}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyCalendar;
