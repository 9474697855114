import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./IslamicKnowledgeCourse.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";

import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic2 from "./QuranCourseImages/islamicVideos.png";
import pic3 from "./QuranCourseImages/islamicQuizzes.png";
import pic4 from "./QuranCourseImages/islamicDiscussions.png";
import pic5 from "./QuranCourseImages/islamicProjects.png";
import pic1 from "./QuranCourseImages/islamicKnowledge.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import ISK from './QuranCourseImages/islamicKnowledegeBackground.png'

const IslamicKnowledgeCourse = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      
      const _avatar_ = userInfo?.avatar;
  return (
    <>
       {/* Navbar */}
       {username && (
       <>
       {/* {avatar && <img src={avatar} alt="User Avatar" />} */}
            {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
          <div className="IslamicKnowledge-Background">
            <section className="section">
              <p className="PageHead" style={{ color: "#8b0194" }}>Islamic Knowledge Course</p>
              <div className="PageDesc">
              <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
                Polymath-kids offer comprehensive online Islamic Knowledge course. With hundreds of fun activities (videos, quizes, projects and in-class discussions), the kids will have a fun learning experience to gain such important knowledge which will serve them for life and hereafter.
                </p>
                <img style={{minWidth:"250px", maxWidth:"450px"}} src={IKC}></img>
                </div>
                <div className="btn-row d-flex btn-row-resp btn-row-resp1">
                  {/* <div className="btn-row-resp"> */}
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className="btn-row-resp button btn-color-course"
                      href="#course"
                    >
                      Course Structure
                    </a>
                  </div>
    
                  <div style={{ width: "auto", height: "50px" }}>
                    <a className=" btn-row-resp button btn-color-fee" href="#fee">
                      Fee Structure
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className=" btn-row-resp button btn-color-Testimonal"
                      href="#testimonal"
                    >
                      Testimonals
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                      Faqs
                    </a>
                  </div>
                  <div style={{ width: "auto", height: "50px" }}>
                    <a
                      className=" btn-row-resp button btn-color-registered"
                      href="#"
                    >
                      Get Registered
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <div className="overlay"></div>
          </div>
    
          {/* Intro text */}
          <div className="scratch-background">
            <section className="section">
              <p className="PageHead" style={{ color: "#8b0194" }}>
              Why Islamic Knowledge?
              </p>
              <div className="PageDesc">
                <p
                  style={{
                    color: "#8b0194",
                    marginTop: "2vh",
                    fontSize: "4vh",
                    fontFamily: "sans serif",
                  }}
                >
                It's important to note that Islamic knowledge is not limited to religious matters alone but also encompasses various fields such as science, literature, art, history, and ethics. By seeking knowledge in these areas, Muslims can contribute to the advancement of society while upholding Islamic values and principles.
                <br />
                Islamic knowledge serves several important purposes for individuals and the Muslim community. Here are some reasons why Islamic knowledge is sought after and valued:
                <br />
                <b>
                Guidance for Life: 
                </b>
                 Islamic knowledge provides guidance on various aspects of life, including worship, morality, ethics, relationships, personal conduct, and societal interactions. It offers a comprehensive framework for Muslims to navigate through life's challenges and make informed decisions in accordance with Islamic teachings.
                <br />
                <b>Relationship with Allah:</b>
                 Islamic knowledge helps individuals establish and strengthen their relationship with Allah. It provides understanding of Allah's attributes, His commandments, and His mercy, enabling Muslims to develop a deeper connection with their Creator and worship Him in a manner that is pleasing to Him.
                <br />
                <b>Understanding of Islam:</b>
                 Islamic knowledge enables Muslims to have a deeper understanding of their faith. It helps clarify misconceptions, dispel doubts, and strengthen one's belief in Islam. By studying Islamic knowledge, individuals gain insights into the beliefs, practices, and principles of Islam, enabling them to live their lives in accordance with its teachings.
                <br />
                <b>Preservation of Tradition:</b>
                 Islamic knowledge plays a crucial role in preserving and passing down the teachings of Islam from one generation to the next. By studying and disseminating Islamic knowledge, individuals contribute to the preservation of the rich intellectual, spiritual, and cultural heritage of the Islamic tradition.
                <br />
                <b>Personal Development:</b>
                 Islamic knowledge fosters personal growth and development. It encourages self-reflection, self-improvement, and the cultivation of virtuous traits such as patience, gratitude, humility, and compassion. Islamic knowledge provides guidance on achieving spiritual, intellectual, and moral excellence, allowing individuals to strive for personal growth and transformation.
                <br />
                <b>Community Building:</b>
                 Islamic knowledge promotes unity, solidarity, and social cohesion within the Muslim community. It provides a shared understanding of Islamic principles and values, fostering a sense of belonging and a common identity. Islamic knowledge encourages Muslims to work together, support one another, and contribute positively to their communities.
                <br />
                <b>Source of Wisdom and Guidance:</b>
                 Islamic knowledge serves as a source of wisdom and guidance for addressing contemporary issues and challenges. It provides principles and teachings that can be applied to various contexts, allowing Muslims to navigate the complexities of the modern world while remaining true to their faith.

        </p>
              </div>
            </section>
          </div>

           {/* Course Structure and Syllabus */}
           <section className="section course-structure" id="course">
            <p className="PageHead" style={{ color: "#8b0194" }}>
              Course Structure and Syllabus
            </p>
            <h4 className='H4_Heading' style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
              <b>Course Objectives</b>
            </h4>
            <p style={{ color: "#8b0194", fontFamily: "sans-serif",padding: "0 19% 0 25%" }}>

            The purpose of this Islamic knowledge course is to guide kids in their relationship with Allah, cultivate moral excellence, develop spiritually, understand and practice the teachings of Islam, pursue intellectual growth, and contribute positively to the community and society at large: <br />
                    •	Worship and Connection with Allah <br />
                    •	Moral and Ethical Guidance  <br />
                    •	Spiritual Development <br />
                    •	Understanding the Quran and Sunnah  <br />
                    •	Intellectual and Academic Pursuits <br />
                    •	Strengthening Community and Social Cohesion <br />
            </p>
    
            <div>
              <p className='moduleSection'>
                <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
                Islamic Knowledge Course

              </p>
            </div>
    
            {/* Module one */}
            <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                  {" "}
                  Module
                  <br />
                  1
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>270</p>
                </p>
    
                <div
                  className="small-box sm-box" 
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>135</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Seera
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                       This module encompasses various aspects of Prophet Mohammad's PBUH life, including his early life, family background, upbringing, prophethood, teachings, actions, and the historical events that occurred during his lifetime. This will help the kids to gain a comprehensive understanding of Prophet Muhammad's example (sunnah) and to derive lessons and guidance for their own life.
                  </p>
                </p>
              </div>
            </div>

              {/* Module Two */}
              <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  2
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>80</p>
                </p>
    
                <div
                  className="small-box sm-box" 
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>60</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Sunnah
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                This module provides practical guidance on how to implement the teachings of the Quran in various aspects of life through Prophet Mohammad's PBUH sunnah. It covers a wide range of areas, including worship, ethics, interpersonal relationships, social conduct, & more. By the virtue of this module, the kids will try to emulate and follow Prophet Mohammad's PBUH sunnah & aim to live in accordance with the teachings of Islam, seeking blessings, spiritual growth, & guidance in their actions and interactions
                  </p>
                </p>
              </div>
            </div>
             {/* Module Three */}
             <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  3
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>50</p>
                </p>
    
                <div
                  className="small-box sm-box" 
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>40</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Islamic Values and Manners
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                    Learning Islamic values and manners enriches individuals' lives, strengthens relationships, contributes to social cohesion, and provides a framework for ethical conduct. It will help kids live in accordance with their faith, seek Allah's pleasure, and positively impact society.
                  </p>
                </p>
              </div>
            </div>
        {/* Module Four */}
        <div className="modules">
              {/* round box */}
              <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                   
                  Module
                  <br />
                  4
                </p>
              </div>
              {/* small boxes */}
              <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                  Activities
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>120</p>
                </p>
    
                <div
                  className="small-box sm-box" 
                >
                  <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>60</p>
                  </p>
                </div>
              </div>
    
              <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Stories of Prophet
                  <p
                    style={{
                      fontWeight: "lighter",
                      fontSize: "15px",
                      marginBottom: "0px",
                    }}
                  >
                  In this module the kids will be taught the stories of the prophets in the Quran which will serve as a comprehensive source of guidance, inspiration, and moral lessons. This will strengthen their faith, provide role models, offer ethical guidance, and contextualize the message of Islam. This will help them reflect upon these stories to derive wisdom and practical lessons for their own lives.
                  </p>
                </p>
              </div>
            </div>
              
          {/* Module Five */}
          <div className="modules">
                {/* round box */}
                <div className="round-box">
                  <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                    
                    Module
                    <br />
                    5
                  </p>
                </div>
                {/* small boxes */}
                <div className="small-box" style={{ color: "#249935" }}>
                  <p style={{ margin: "auto" }}>
                    Activities
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>200</p>
                  </p>
      
                  <div
                    className="small-box sm-box" 
                  >
                    <p style={{ margin: "auto" }}>
                      No.of Lessons
                      <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>135</p>
                    </p>
                  </div>
                </div>
      
                <div className="large-box" style={{ color: "#249935" }}>
                  <p style={{ margin: "auto" }}>
                  Islamic History
                    <p
                      style={{
                        fontWeight: "lighter",
                        fontSize: "15px",
                        marginBottom: "0px",
                      }}
                    >
                    In this module, the kids will learn Islamic history (including muslim scientists, muslim discoveries, wars etc) which will deepen their understanding of their faith, preserve their cultural and intellectual heritage, gain inspiration from past role models, and apply lessons from the past to navigate present-day challenges. It will help them build a strong sense of identity, fosters faith, and facilitates engagement with the broader Muslim community and the world at large.
                    </p>
                  </p>
                </div>
          </div>
          {/* Module Six */}
          <div className="modules">
                  {/* round box */}
                  <div className="round-box">
                    <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                      
                      Module
                      <br />
                      6
                    </p>
                  </div>
                  {/* small boxes */}
                  <div className="small-box" style={{ color: "#249935" }}>
                    <p style={{ margin: "auto" }}>
                      Activities
                      <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>100's</p>
                    </p>
        
                    <div
                      className="small-box sm-box" 
                    >
                      <p style={{ margin: "auto" }}>
                        No.of Lessons
                        <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>Ongoing</p>
                      </p>
                    </div>
                  </div>
        
                  <div className="large-box" style={{ color: "#249935" }}>
                    <p style={{ margin: "auto" }}>
                    Quran Memorization 

                      <p
                        style={{
                          fontWeight: "lighter",
                          fontSize: "15px",
                          marginBottom: "0px",
                        }}
                      >
                        We will help the kids to memorize Suras from Quran. They will also memorize duas.
                      </p>
                    </p>
                  </div>
          </div>
        
          
          

            <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
              <b>Learn  Islam with 40+ Activities & Projects</b>
            </h6>
    
            <div className="activityImagesContainer">
              <div className="row">
                <div class="col-lg mx-3">
                  <img src={pic2} alt="" className='ActivityImages'/>
                  <p className="mx-3">Videos</p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic3} alt="" className='ActivityImages'/>
                  <p className="mx-3">Quizzes</p>
                </div>
    
                <div class="col-lg mx-3">
                  <img src={pic4} alt="" className='ActivityImages'/>
                  <p className="mx-3">Discussions</p>
                </div>
                <div class="col-lg mx-3">
                  <img src={pic5} alt="" className='ActivityImages'/>
                  <p className="mx-3">Projects</p>
                </div>
    
                
    
              </div>
            </div>

          </section>
          
          {/* Fee Structure */}
          <section className="section fee-structure my-3" id="fee">
            <div>
              <p className="PageHead" style={{ color: "#8b0194" }}>
                Fee Structure
              </p>
              <div>
                <p
                  style={{
                    color: "#8b0194",
                    marginTop: "3vh",
                    fontSize: "2em",
                    fontFamily: "VanillaMilk",
                  }}
                >
                  Learn Quran privately or with fellow Polymaths
                </p>
              </div>
            </div>
    
            <div className="d-flex mx-4 feeTableContainer" style={{ marginLeft: "20vh" }}>
              <div className="columns" style={{ marginTop: "30vh" }}>
                <ul className="price">
                  <li>
                    <b> Class duration </b>
                  </li>
                  <li>
                    <b> Class type </b>
                  </li>
                  <li>
                    <b> Class schedule </b>
                  </li>
                  <li>
                    <b> Learning mode </b>
                  </li>
                  <li>
                    <b> Pace of learning</b>
                  </li>
                  <li>
                    <b>Activities </b>
                  </li>
                  <li>
                    <b>Assesment </b>
                  </li>
                </ul>
              </div>
              <div className="columns">
                <ul className="price">
                  <li className="header">Private 1-on-1 Class</li>
    
                  <li className="grey">
                    Our 1-on-1 Quran classes for kids are suitable for children who
                    want a full personalized and effective learning experience.
                  </li>
                  <li>20 minutes</li>
                  <li>Individual</li>
                  <li>Flexible</li>
                  <li>With teacher</li>
                  <li>Personalized</li>
                  <li>With teacher</li>
                  <li>Individual</li>
                  <li className="grey">
                    <a href="#" className="button">
                      Buy Course
                    </a>
                  </li>
                </ul>
              </div>
             
             
            </div>
          </section>
     {/* Footer */}
     <Footer/>
    </>
  )
}

export default IslamicKnowledgeCourse
