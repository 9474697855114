import React ,{useRef,useContext, useState,useEffect }from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import TeacherNav from "../Navbars/TeacherNav";
import './OTP.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const OTPModal = ({ isOpen2, onClose2,SendedOtp,_username_ }) => {
    const [otp, setOtp] = useState({ digit1: '', digit2: '', digit3: '', digit4: '' });
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    const navigate=useNavigate();
    useEffect(()=>{
      sessionStorage.setItem("email",_username_)
    },[])

    const handleChange = (e) => {
        console.log('SendedOtp:',SendedOtp);
      const { name, value } = e.target;
      setOtp({ ...otp, [name]: value });
    };

    const handleKeyDown = (e, field) => {
        if (e.key === "ArrowRight") {
          // Move to the next field if it's not the last one
          const nextField = document.querySelector(`input[name=digit${parseInt(field.charAt(field.length - 1)) + 1}]`);
          if (nextField) {
            nextField.focus();
          }
        } else if (e.key === "ArrowLeft") {
          // Move to the previous field if it's not the first one
          const prevField = document.querySelector(`input[name=digit${parseInt(field.charAt(field.length - 1)) - 1}]`);
          if (prevField) {
            prevField.focus();
          }
        }
      };

    const fetchData = async () => {
        await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
           credentials: 'include',
         }).then(response => {
            //  console.log("Response:",response);
           response.json().then(userInfo => {
            //  console.log("userinfo:",userInfo);
             setUserInfo(userInfo);
    
             if(userInfo?.usertype)
             {
               const uType=userInfo?.usertype;
               setUserRole(uType);
             }
           const uname = userInfo?.username;
           const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        //    console.log("Uname: ", uname);
        //    console.log("Avatar: ", avatar);
    
           })
           .catch(err=> console.log("Error in useeffect:",err))
         });
       }
    const handleSubmit = () => {
        // Concatenate OTP digits
        const fullOtp = Object.values(otp).join('');
        // console.log('Typed OTP:', fullOtp);
    
        // Check if both typed and sent OTPs match
        if (fullOtp === SendedOtp.toString()) {
            // If OTPs match, proceed to log in the user
            const username = _username_; // You need to pass or obtain the username
            navigate('/ChangeForgetPassword')
    
            // axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/loginUserByUsername`, { username })
            //     .then(response => {

            //         onClose2();
            //         fetchData();
            //         toast.success('Successfully Login', {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //           });
            //     })
            //     .catch(error => {
            //         toast.error('Your OTP is incorrect. Please try again.', {
            //             position: toast.POSITION.TOP_CENTER,
            //             autoClose: 2000,
            //           });
            //     });
        } else {
            // If OTPs do not match, inform the user
            // console.error("OTP verification failed. Please try again.");
            toast.error('Your OTP is incorrect. Please try again.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
        }
    };
     

  async function onLogout() {
    console.log("inside Onlogout.");
  await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    navigate('/');
    setUserRole('guest');
  }
    const onLogin = (usertype) => {
    setUserRole(usertype);
    };
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
    const User_id = userInfo?.User_id;

  
    return (
    <>
             {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  

            <div className={`modal ${isOpen2 ? 'open' : ''}`}>
                <div className="modal-overlay" onClick={onClose2}></div>
                <div className="modal-content">
                <h3>Enter OTP</h3>

                <p style={{ width: "50%", textAlign: 'center' }}>
                    We have sent you an OTP on your Email.<br/>
                    {_username_}
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    {Object.keys(otp).map((key, index) => (
                        <input
                        key={index}
                        type="text" // Change type to text
                        name={key}
                        value={otp[key]}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyDown(e, key)}
                        maxLength="1"
                        onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1)} // Ensure only digits can be entered, up to maxLength of 1
                        style={{
                            '-moz-appearance': 'textfield',
                            '&::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                            },
                            '&::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                            },
                        }}
                        className='OTPInput'
                        />
                    ))}
                    </div>

                <button onClick={handleSubmit} className='ResetButton' style={{margin:'4vh'}}>
                    Verify Account
                </button>
                </div>
            </div> 
      
    </>
    );
}

export default OTPModal