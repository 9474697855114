import React, { useState, useContext, useEffect, Children } from "react";
import "./ManageClasses.css";
import { FaTrash } from "react-icons/fa";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom/dist/umd/react-router-dom.development';
import { Button } from "reactstrap";
// import { set } from "../../../../Backend/server";

const ManageClasses = () => {
  const { courseId, coursename } = useParams();

  const [courseName, setCourseName] = useState("");
  const [selectedCourse2, setSelectedCourse2] = useState("");

  const [courseType, setCourseType] = useState("group");
  const [classLink, setClassLink] = useState("");
  const [classList, setClassList] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
    setCourseName(courseId)
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        setCourses(response.data);
        setSelectedCourse2(response.data[0]?.Course_id)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    fetchTeacher();
  }, []);

  const fetchTeacher = () => {
    axiosInstance
      .post("/api/manageclass/getAllteachers")
      .then((response) => {
        setTeacherList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    fetchClasses();
  }, [selectedCourse2]);

  const fetchClasses = () => {
    axiosInstance
      .get(`/getAllclasseswithstudent/${selectedCourse2}`)
      .then((response) => {
        setClassList(response.data);
        console.log("Classes:",response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseNameChange = (e) => {
    console.log("e.target.value",e.target.value)
    setCourseName(e.target.value);

  };

  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
  };

  const handleCreateClass = () => {
    const newClass = {
      courseName,
      courseType,
      classLink,
      teacher: "",
      schedule: "",
      numOfStudents: 0,
    };

    // const currentDate = new Date();
    // const formattedDate = `${
    //   currentDate.getMonth() + 1
    // }-${currentDate.getDate()}-${currentDate
    //   .getFullYear()
    //   .toString()
    //   .substr(-2)}`;

    // newClass.schedule = formattedDate;
    // const courseid1 = courses.find(
    //   (c) => c.coursename === courseName
    // )?.Course_id;

    const payload = {
      // Course_id: courseid1,
      Course_id :  newClass.courseName,
      ClassLink: newClass.classLink,
      ClassType: newClass.courseType,
      ClassNo:1,
    };

    axiosInstance
      .post("/api/manageclass/createclass", payload)
      .then(() => {
        fetchClasses();
      })
      .catch((error) => {
        console.log("Internal server error i", error);
      });

    setClassList([...classList, newClass]);
    setCourseName("");
    setCourseType("group");
    setClassLink("");
  };

  // const handleClassLinkChange = (e, index) => {
  //   const updatedClassList = [...classList];
  //   updatedClassList[index].classLink = e.target.value;
  //   setClassList(updatedClassList);
  // };


  function ManageClassRow({ classinfo, index, teacherList }) {
    const [assignedTeacher, setAssignedTeacher] = useState([]);
    const [showStudentsDialog, setShowStudentsDialog] = useState(false);
    const [classStudents, setClassStudents] = useState([]);

    useEffect(() => {
      fetchAssignedTeacher();
      fetchassignedSchedule();
    }, []);

    const [myclasslink,setmyClasslink] = useState(classinfo?.ClassLink);

    const handleClassLinkChange = async (e, index) => {
      // Update the state with the new value
      const newValue = e.target.value;
      setmyClasslink(newValue);
    };

    const handleClassLinkBlur = async (index) => {
      const payload = {
        classid: classinfo.Class_id,
        classlink: myclasslink,
      };

      try {
        const response = await axiosInstance.post(
          "/api/manageclass/updateclasslink",
          payload
        );
        if (response.status === 200) {
          toast.success("Class Link Updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Handle other response statuses if needed
        }
      } catch (error) {
        console.error("API request error:", error);
        toast.error("Failed to Update Class Link", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    async function fetchAssignedTeacher() {
      try {
        const response = await axiosInstance.get(
          `/getAssignedTeachers/${classinfo.Class_id}`
        );

        if (response.status === 200) {
          setAssignedTeacher(response.data.assignedTeachers);
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    }

    async function fetchassignedSchedule() {
      try {
        const response = await axiosInstance.get(
          `/getAssignedSchedule/${classinfo?.Class_id}`
        );

        if (response.status === 200) {
          const assignedSchedules = response.data.assignedSchedules;

          // Assuming you want to create a list of schedules in the format "Day - Time"
          const scheduleList = assignedSchedules.map(
            (schedule) => `${schedule.day} - ${schedule.time}`
          );

          // Set the scheduleList to the new list of schedules
          setScheduleList(scheduleList);
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    }

    async function handleAssignTeacher(){
      if (!classinfo.teacher) {
        toast.warning("Please Select a Teacher", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const payload = {
          Class_id: classinfo.Class_id,
          Teacher_id: classinfo.teacher.teacher_id,
        };

        try {
          const response = await axiosInstance.post(
            "/assignTeachertoClass",
            payload
          );
          if (response.status === 200) {
            setAssignedTeacher([
              ...assignedTeacher,
              classinfo.teacher.username,
            ]);
            if(response?.data?.link){
              setmyClasslink(response?.data?.link);
            }
            toast.success("Teacher Assigned", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Failed to Assign Teacher", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          console.error("API request error:", error);
          toast.error("Failed to Assign Teacher", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }

    const [teacherList1, setTeacherList1] = useState([]);
    const teachers = teacherList.teachers;

    useEffect(() => {
      setTeacherList1(teachers);
    }, [teachers]);

    const handleTeacherChange = (e) => {
      const selectedTeacher = teacherList1.find(
        (teacher) => teacher.teacher_id === parseInt(e.target.value, 10)
      );
      classinfo.teacher = selectedTeacher;
    };
    const [days, setDays] = useState("");
    const [time, setTime] = useState("");
    const [scheduleList, setScheduleList] = useState([]);

    const dayList = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const timeList = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hh = (hour % 12).toString().padStart(2, "0");
        const mm = minute.toString().padStart(2, "0");
        const period = hour < 12 ? " AM" : " PM";

        timeList.push(`${hh}:${mm}${period}`);
      }
    }

    const handleAddSchedule = async () => {
      if (!days || !time) {
        toast.warning("Please Select date and time", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const newSchedule = `${days} - ${time}`;

      // Create a payload to send to the server
      const payload = {
        Class_id: classinfo.Class_id, // Replace with your class info
        ClassDay: days,
        ClassTime: time,
      };

      try {
        const response = await axiosInstance.post("/addSchedule", payload);
        if (response.status === 201) {
          // Schedule added successfully
          setScheduleList([...scheduleList, newSchedule]);
          setDays("");
          setTime("");
          toast.success("Schedule added successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("API request error:", error);
        toast.error("Failed to add Schedule", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    const handleDayChange = (e) => {
      setDays(e.target.value);
    };

    const handleTimeChange = (e) => {
      setTime(e.target.value);
    };

    const handleDeleteRow = async (index) => {
      const shouldDelete = window.confirm("Are you sure you want to delete this row?");
      
      if (shouldDelete) {
        const Class_id = classList[index].Class_id;
        const payload ={
          Class_id: Class_id
        }

        try {
          const response = await axiosInstance.post("/api/manageclass/deleteclass", payload);
          if (response.status === 200) {
            const updatedClassList1 = [...classList];
            const updatedClassList2 = [...filteredClassList];
            updatedClassList1.splice(index, 1);
            updatedClassList2.splice(index, 1);
            setClassList(updatedClassList1);
            setFilteredClassList(updatedClassList2);
            toast.success("Class Deleted Sucessfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          console.error("API request error:", error);
          toast.error("Failed to delete Class", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    };

    const handleShowStudents = async () => {
      try {
        const response = await axiosInstance.get(`/classstudents/${classinfo.Class_id}`);
        setClassStudents(response.data);
        setShowStudentsDialog(true);
      } catch (error) {
        console.error('Error fetching class students:', error);
        toast.error("Failed to fetch students", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    async function deleteStudent(s_id,c_id){
      const payload = {
        s_id,
        c_id
      };
  
      axiosInstance
        .post("/deleteClass", payload)
        .then(() => {
          toast.success("Student Deleted from the Class", {
            position: toast.POSITION.TOP_RIGHT,
          });
         })
        .catch((error) => {
          toast.fail("Something Went Wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });      
        setTimeout(()=>{
          handleShowStudents()
        },[1000])
       
    }

    return (
      <>
        <tbody id="table-rows">
          <tr
            className="CourseRegistrationTable-tr"
            style={{ border: "1px solid gray" }}
          >
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              <FaTrash
                className="ManageClassDeleteIcon"
                onClick={() => handleDeleteRow(index)}
              />

              {classinfo.CourseName}
            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              {classinfo.ClassType}
            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
             <input
              type="text"
              placeholder="Enter Class Link"
              value={myclasslink}
              onChange={(e) => handleClassLinkChange(e, index)}
              onBlur={() => handleClassLinkBlur(index)}
              style={{ width: '300px', height: '40px' }}
            />

            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray", cursor: "pointer" }}
              onClick={handleShowStudents}
            >
              {classinfo.noofstudents}
            </td>

            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              <select
                className="ManageClassDropdown"
                onChange={handleTeacherChange}
              >
                <option value="">Select a teacher</option>
                {teacherList1?.map((teacher, index) => (
                  <option key={index} value={teacher.teacher_id}>
                    {teacher?.firstname} {teacher?.lastname}
                  </option>
                ))}
              </select>
              <button
                className="AssignTeacherButton"
                type="button"
                onClick={handleAssignTeacher}
              >
                Assign to class
              </button>
              <br />
              <span>{assignedTeacher}</span>
            </td>

            <td className="CourseRegistrationTable-td" style={{ border: "0" }}>
              <div>
                <select
                  className="ManageClassDropdown"
                  value={days}
                  onChange={handleDayChange}
                >
                  <option value="">Select a day</option>
                  {dayList.map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <select
                  className="ManageClassDropdown"
                  value={time}
                  onChange={handleTimeChange}
                >
                  <option value="">Select a time</option>
                  {timeList.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <button
                  className="AssignTeacherButton"
                  type="button"
                  onClick={handleAddSchedule}
                >
                  Add Schedule
                </button>
                <br />
                {scheduleList.map((schedule, index) => (
                  <span key={index}>
                    {schedule}
                    <br />
                  </span>
                ))}
              </div>
            </td>
          </tr>
        </tbody>

        {showStudentsDialog && (
          <div className="dialog-overlay">
            <div className="dialog-content">
              <h2>Students in Class</h2>
              <table className="students-table">
                <thead>
                  <tr>
                    <th>Student Name</th>
                  </tr>
                </thead>
                <tbody>
                  {classStudents?.map((student, idx) => (
                    <tr key={idx}>
                      <td>{student.firstname} {student.lastname}  <Button size="sm" onClick={()=>deleteStudent(student.Student_id,student.Class_id)}>Delete</Button></td>
                      {/* <td></td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => setShowStudentsDialog(false)}>Close</button>
            </div>
          </div>
        )}
      </>
    );
  }

  const [selectedCourse, setSelectedCourse] = useState("");

  const [selectedClassType, setSelectedClassType] = useState("");
  const [filteredClassList, setFilteredClassList] = useState([]);

  const handleCourseFilterChange = (e) => {
    setSelectedCourse2(e.target.value);
  };

  const handleClassTypeFilterChange = (e) => {
    setSelectedClassType(e.target.value);
  };
  useEffect(() => {
    renderClassList();
  }, [selectedCourse, selectedClassType]);
  const renderClassList = () => {
    let filteredList = classList;

    // Apply filters based on selected values
    if (selectedCourse2) {
      filteredList = filteredList.filter(
        (classInfo) => classInfo.courseName === selectedCourse2
      );
    }
    if (selectedClassType) {
      filteredList = filteredList.filter(
        (classInfo) => classInfo.courseType === selectedClassType
      );
    }
    setFilteredClassList(filteredList);
  };

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
     await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
          console.log("Response:",response);
        response.json().then(userInfo => {
          console.log("userinfo:",userInfo);
          setUserInfo(userInfo);

          if(userInfo?.usertype)
          {
            const uType=userInfo?.usertype;
            setUserRole(uType);
          }
        const uname = userInfo?.username;
        const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        console.log("Uname: ", uname);
        console.log("Avatar: ", avatar);

        })
        .catch(err=> console.log("Error in useeffect:",err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };

const navigate=useNavigate();
const onLogin = (usertype) => {
  const userRole = determineUserRole(usertype);
  console.log("Calling setUserRole.")
  setUserRole(userRole);
};

    async function onLogout() {
      console.log("inside Onlogout.");
    await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      console.log("navigating");
      navigate('/');
      // console.log("navigated");
      setUserRole('guest');
    }

    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;

  return (
    <div>
      {/* Navbar */}
      {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
      <h3 className="ManageClassHeading">Manage Classes</h3>
      <div className="ManageClassesFormDiv">
        <form className="ManageClassesForm">
          <select
            className="ManageClassDropdown"
            style={{ margin: "2%" }}
            value={courseName}
            onChange={handleCourseNameChange}
          >
            {/* <option>Select a course</option> */}
            <option value={courseId}>{coursename}</option>
            {courses.map((course, index) => (
              <option key={index} value={course.Course_id}>
                {course.coursename}
              </option>
            ))}
          </select>
          <select
            className="ManageClassDropdown"
            style={{ margin: "2%" }}
            value={courseType}
            onChange={handleCourseTypeChange}
          >
            <option value="group">Group</option>
            <option value="one-on-one">One-on-One</option>
          </select>

          <input
            type="text"
            placeholder="Enter Class Link"
            value={classLink}
            onChange={(e) => setClassLink(e.target.value)}
          />

          <button
            className="ManageClassCreateButton"
            style={{ marginLeft: "30%" }}
            type="button"
            onClick={handleCreateClass}
          >
            Create Class
          </button>
        </form>
      </div>

      <div className="ManageClassesFilter">
        <select value={selectedCourse2} onChange={handleCourseFilterChange}>
          {/* <option value="">All Courses</option> */}
          {courses.map((course, index) => (
            <option key={index} value={course.Course_id}>
              {course.coursename}
            </option>
          ))}
        </select>

        <select
          value={selectedClassType}
          onChange={handleClassTypeFilterChange}
        >
          <option value="">All Class Types</option>
          <option value="group">Group</option>
          <option value="one-on-one">One-on-One</option>
        </select>
      </div>

      <table
        className="CourseRegistrationTable ManageClassesTable"
        style={{ border: "0" }}
      >
        <thead>
          <tr className="CourseRegistrationTable-tr">
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Course Name
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Type
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Link
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              No. of Student
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class teacher
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Schedule
            </th>
          </tr>
        </thead>
        {filteredClassList.length === 0 &&
          classList.map((classinfo, index) => (
            <ManageClassRow
              key={index}
              classinfo={classinfo}
              teacherList={teacherList}
              index={index}
            />
          ))}
        {filteredClassList.length > 0 &&
          filteredClassList.map((classinfo, index) => (
            <ManageClassRow
              key={index}
              classinfo={classinfo}
              teacherList={teacherList}
              index={index}
            />
          ))}
      </table>
      <Footer />
    </div>
  );
};

export default ManageClasses;
