import React,{useContext,useEffect,useState} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import TeacherNav from "../Navbars/TeacherNav";
import './AttendClass.css'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';

const AttendClass = ({selectedCourse}) => {

  console.log("selectedCourse:",selectedCourse)
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);

    const handleClick = (value) => {
        console.log(value)
        setRating(value);
    };

    const handleMouseOver = (value) => {
        setHoverRating(value);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var ClassLink = JSON.parse(queryParams.get('classLink'));
  if (ClassLink.startsWith("https://")) {
    ClassLink = ClassLink.slice(8);
  }
  const coursename = queryParams.get('coursename');

  const course_id = queryParams.get('Course_id');
  const teacherIds = queryParams.get('teacherIds');
  console.log("teacherIds:",teacherIds)
  const [comment, setComment] = useState('');

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
  useEffect(() => {
    const fetchData = async () => {
     await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
          // console.log("Response:",response);
        response.json().then(userInfo => {
          // console.log("userinfo:",userInfo);
          setUserInfo(userInfo);
 
          if(userInfo?.usertype)
          {
            const uType=userInfo?.usertype;
            setUserRole(uType);
          }
        const uname = userInfo?.username;
        const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        // console.log("Uname: ", uname);
        // console.log("Avatar: ", avatar);
 
        })
        .catch(err=> console.log("Error in useeffect:",err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
 const determineUserRole = (usertype) => {
      console.log("In home.js:",usertype)
      if(usertype === 'Student')
        return "Student";
      else if(usertype === 'Teacher')
        return "Teacher";
      else if(usertype === 'Admin')
        return "Admin";
      else
        return "guest";
 };
 
 
    const navigate=useNavigate();
    const onLogin = (usertype) => {
      const userRole = determineUserRole(usertype);
    //  console.log("Calling setUserRole.")
      setUserRole(userRole);
    };
 
 async function onLogout() {
   console.log("inside Onlogout.");
 await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
     credentials: 'include',
     method: 'POST',
   });
   setUserInfo(null);
   navigate('/');
   setUserRole('guest');
 }

 const handleSubmitRating = () => { 

  // Construct the data object
  const data = {
    rating: rating,
    course_id: course_id,
    teacherIds: teacherIds,
    comment: comment
  };
 
  axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentFeedback`, data)
    .then(response => {
      // Handle success response
      console.log('Feedback submitted successfully:', response.data);
      // Show success toast
      toast.success('Feedback submitted successfully', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close the toast after 2 seconds
      });
    })
    .catch(error => {
      // Handle error
      console.error('Error submitting feedback:', error);
    });
};
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
  return (
    <>
     {/* Navbar */}
     {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}
          </>
        )}

    <div className='AttendClassJoinClass'>
    <h2 className="Testimonal_Heading" style={{color:"#03b4c6",fontSize:"25px",margin:0}}>
      {coursename}
    </h2>


    <div className='AttendClassBtn'>

    {/* <a href={ClassLink} className='AttendClassjoinBtn'>Join Class</a> */}
    <button className="AttendClassjoinBtn" 
            onClick={() => window.open(`https://${ClassLink}`, "_blank")}>Join Class
    </button>
    </div>
 

        <div className='AttendClassRate'>
            <p>Please share your feedback for this class</p>
            {[1, 2, 3, 4, 5].map((value) => (
                <FontAwesomeIcon
                    key={value}
                    style={{color:'#03b4c6',fontSize:"25px"}}
                    icon={value <= (hoverRating || rating) ? solidStar : regularStar}
                    className="mx-2" 
                    onClick={() => handleClick(value)}
                    onMouseOver={() => handleMouseOver(value)}
                    onMouseLeave={handleMouseLeave}
                />
            ))}
            <br />
            <br />
            <br /> 

            <input type="text" placeholder='Comments' onChange={(e) => setComment(e.target.value)} className='AttendClassAttendInput' />
            <button className='AttendClassSendRating' onClick={handleSubmitRating}>Submit Rating</button>
        </div>

    </div>

    
 {/* Footer */}
 <Footer/>

    </>
  )
}

export default AttendClass
