import React, { useState, useContext, useEffect } from "react";
import './MyRewards.css';
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import AdminNav from "../Navbars/AdminNav";
import GuestNav from "../Navbars/GuestNav";

function MyRewards() {
    const {
        isAdmin,
        isTeacher,
        isStudent,
        isGuest,
        setUserRole,
        setUserInfo,
        userInfo,
      } = useContext(UserContext);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: "include",
            });
            const data = await response.json();
            setUserInfo(data);
            if (data?.usertype) {
              setUserRole(data.usertype);
            }
          } catch (error) {
            console.log("Error in useEffect:", error);
          }
        };
        fetchData();
      }, [isAdmin, isTeacher, isStudent, isGuest, setUserInfo, setUserRole]);

      const determineUserRole = (usertype) => {
        if (usertype === "Student") return "Student";
        else if (usertype === "Teacher") return "Teacher";
        else if (usertype === "Admin") return "Admin";
        else return "guest";
      };

      const navigate = useNavigate();

      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        setUserRole(userRole);
      };

      const onClose = () =>{
        navigate("/");
      }

      const onLogout = () => {
        fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
          credentials: "include",
          method: "POST",
        })
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.error("Logout failed:", error);
          });
        setUserInfo(null);
        setUserRole("guest");
      };

      const username = userInfo?.username;

      const _avatar_ = userInfo?.avatar;
  return (
   <>
    {username && isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
    {!username && isGuest && <GuestNav onLogin={onLogin} />}
    <div className="popup-inz">
      <div className="popup-inner-inz">
        <div className="popup-header-inz">
        <h4>Aslam Ali Points Summary</h4>
          <span className="close-icon" onClick={onClose}>×</span>
        </div>
        <div className="popup-content-inz">
          <p>Points Redeemed: 100</p>
          <p>Available Balance: 200</p>
        </div>
        <h6>Available Points Balance: 100</h6>
        <div className="popup-actions-inz">
          <button className="redeem-btn">Redeem Gift</button>
          <button className="see-rewards-btn">View Redeem Gifts History</button>
        </div>
      </div>
    </div>
    <Footer />
   </>
  );
}

export default MyRewards;
