import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import './CreateCertificate.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';

import { PDFDownloadLink } from "@react-pdf/renderer";
import { Margin,usePDF, Resolution } from 'react-to-pdf';

import PDF from './PDF';
import Signature from "./PDF_Images/signature.png";

import cLogo from "./PDF_Images/certificate-logo.png";

import LebronStretch from "./PDF_Images/Image1.jpeg";



const CreateCertificate2 = () => {
  const { courseId, coursename } = useParams();
  // const [courseName, setCourseName] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStudentDetails, setSelectedStudentDetails] = useState(null);
  const [certificateNumber, setCertificateNumber] = useState(Math.floor(Math.random() * 9000) + 1000)
  const [courses, setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({ courseId: '', coursename: '' })
  const [refresh, setRefresh] = useState(false)
  async function getCurrentDateTime() {
    const now = new Date();
    
    // Extract date components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    
    // Extract time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    // Concatenate all components without spaces, dashes, or colons
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  useEffect(() => {

    // fetchCourseName();
    fetchStudents();
    fetchCertificatNumber()

  }, [refresh]);
  const fetchCertificatNumber = async () => {
    // getCertificateCourses
    // axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateEventCertificateNumber`).then((res) => {

    // })
    setCertificateNumber(((selectedStudentDetails?.cid).toString() ||1)+ getCurrentDateTime() )

  }
  const fetchCourseName = async () => {
    try {
    } catch (error) {
      console.error('Error fetching course name:', error);
    }
  };
  const fetchStudents = async () => {
    // if (courseId) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/student_certificate`);
      setStudents(response.data);
      setCertificateNumber(((response.data[0].cid).toString() ||1)+ getCurrentDateTime() )
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  // Handle student selection
  const handleStudentChange = async (event) => {
    const studentEmail = event.target.value;
    const selectedStudent = students.find(student => student.email === studentEmail);
    setCertificateNumber((selectedStudent?.cid).toString() +getCurrentDateTime())

    setSelectedStudentDetails(selectedStudent ? {
      // userId: selectedStudent.User_id,
      id: selectedStudent.id,
      firstname: selectedStudent.student_name,
      lastname: selectedStudent.student_name,
      parentname: selectedStudent.parent_name,
      email: selectedStudent.email,
      cid: selectedStudent.cid,
      c_name:selectedStudent.name,
      c_date: selectedStudent.c_date,
    } : null);
  };



  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  useLayoutEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        //  console.log("Response:",response);
        response.json().then(userInfo => {
          //  console.log("userinfo:",userInfo);
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable


        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogin = (usertype) => {

    setUserRole(usertype);
  };
  const navigate = useNavigate();

  async function onLogout() {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    console.log("navigating");
    navigate('/');
    // console.log("navigated");
    setUserRole('guest');
  }
  const options = {
    method: 'open',

    page: {
      format: 'A2',
      orientation: 'landscape',
    }
  }
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  // const dynamicFilename = `certificate-${selectedStudentDetails?.firstname}-${selectedStudentDetails?.id}.pdf`;

  const { toPDF, targetRef } = usePDF({
    // filename: dynamicFilename,
    // paperSize: [1600, 1234],
    // format: '1600x1234',
    // paperSize: [1234, 1600], // Swap width and height for landscape
    // format: '1234x1600',     // Ensure the format matches the landscape dimensions
    paperSize: [1234, 1600], // Explicitly set width < height for landscape
    format: 'landscape',    // Check if the library supports an orientation property
    page: { margin: Margin.NONE,orientation:"landscape" },
    resolution:Resolution.LOW

  });
  // async function generateCertificatePdf() {

  //   const pdf = await toPDF();
  //   const pdfBlob = pdf.output('blob');
  //   const formDataInvoice = new FormData();
  //   const data = {
  //     id: selectedStudentDetails.id,
  //     name: selectedStudentDetails.firstname,
  //     email: selectedStudentDetails.email,
  //     certificate_no: certificateNumber
  //   }
    
  //   formDataInvoice.append('pdfFile', pdfBlob, `Certificate-${certificateNumber}.pdf`);
  //   formDataInvoice.append('data', JSON.stringify(data));

  //   const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateCertificateEvent`, formDataInvoice, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     }
  //   }).then(() => {
  //     setStudents([])
  //     setSelectedStudentDetails(null)
  //     setCourses([])
  //     setRefresh(!refresh)
  //   })

  // }
  function getDate() {
    const now = new Date();
    const date = now.getDate();

    const monthName = now.toLocaleString('en-US', { month: 'short' });
    const year = now.getFullYear();
    return `${date}-${monthName}-${year}`;
    // return today.toDateString().slice(0, 10)
  }
  const [currentDate, setCurrentDate] = useState(getDate());
  const [textAreaValue, setTeaxtAreaValue] = useState(`for Participation in Mental Math Competition - PolyMatch 2024`)
  const [certificateType, setCertificateType] = useState(2)
  function chageCertificateType(e) {
    setCertificateType(e.target.value)
    if (e.target.value == 3) {
      setTeaxtAreaValue(`Winner of Mental Math Competition - PolyMatch 2024`)

    }
    else if (e.target.value == 2) {
      setTeaxtAreaValue(`for Participation in Mental Math Competition - PolyMatch 2024`)

    }
  }
  function handleCourseChange(e) {
    setSelectedCourse({ courseId: e.target.value, coursename: e.target[e.target.selectedIndex].label })
    setTeaxtAreaValue(`For the exceptional performance that has led to the successful completion of Polymath-Kids ${e.target[e.target.selectedIndex].label}  conducted between December 2022 and March 2023`)
  }
  async function makeCertificate(student,c_number) {
  const dynamicFilename = `certificate-${selectedStudentDetails?.firstname}-${selectedStudentDetails?.id}.pdf`;

    const pdf = await toPDF();
    const pdfBlob = pdf.output('blob');

    const formDataInvoice = new FormData();
    const data = {
      id: student.id,
      name: student.student_name,
      email: student.email,
      certificate_no: c_number

    };

    formDataInvoice.append('pdfFile', pdfBlob,dynamicFilename);
    formDataInvoice.append('data', JSON.stringify(data));

    try {
      axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateCertificateEvent`, formDataInvoice, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(`Certificate for ${student.student_name} downloaded.`);
    } catch (error) {
      console.error(`Error downloading certificate for ${student.student_name}:`, error);
    }
  }
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async function downloadAllCertificates() {
    if (students.length === 0) {
      alert("No students available to download certificates for.");
      return;
    }
    // setTimeout(() => {
    for (const student of students) {
      // const studentEmail = student.email;
      // const selectedStudent =  students.find(student => student.email === studentEmail);
      console.log("selectedStudent :", student)
      const c_number = (student?.cid).toString() +await getCurrentDateTime()
      setCertificateNumber(c_number)

      setSelectedStudentDetails(student ? {
        id: student.id,
        firstname: student.student_name,
        lastname: student.student_name,
        parentname: student.parent_name,
        email: student.email,
        cid: student.cid,
        c_name:student.name,
        c_date: student.c_date,
      } : null);
      await delay(3000);
      await makeCertificate(student,c_number)

    }
    // }, [9000])

    alert("All certificates have been downloaded!");
  }
  function emailAllCertificates() {
    try {
      axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/sentCertificateEventBulk`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error(`Error Sending Email certificates:`, error);
    }
  }
  return (
    <>

      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}
        </>
      )}

      <div className='edit-bar'>
        <h3 className='HeadingPDFcert'>
          Generate Certificate
        </h3>
        <br />
        <h2 className='coursenamePDFcert'>
          {selectedCourse.coursename}
        </h2>
      </div>


      {/* {students.length > 0 && (
        <div className='TeacherFilterSection'>
          <div className='TeacherFilter'>
            <select id='TPcourseDropdown' onChange={handleStudentChange}>
              <option value=''>Select Student</option>
              {students.map((student, index) => (
                <option key={index} value={student.email}>
                  {student.student_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )} */}

      <div className='TeacherFilterSection'>
        <div className='TeacherFilter'>
          <select id='TPcourseDropdown' >
            <option value='' disabled>Select Competition</option>
            <option value='' selected>
              Mental Math 2024
            </option>
          </select>
        </div>
      </div>


      {
        <div className='StudentDetails'>
          <h3>Student Details</h3>
          <table className='StudentTable'>
            <tbody>
              <tr>
                <th>Student Name</th>
                <td>{selectedStudentDetails?.firstname.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Parent Name</th>
                <td>{selectedStudentDetails?.parentname}</td>
              </tr>
              {certificateType != 1 && <tr>
                <th>Event Name</th>
                <td><textarea rows={5} style={{ width: "100%" }} defaultValue={textAreaValue} placeholder='Enter Event Name' onChange={(e) => setTeaxtAreaValue(e.target.value)} /></td>
              </tr>}

              <tr>
                <th>Certificate Type</th>
                <td>
                  <select defaultValue={2} onChange={chageCertificateType}>
                    {/* <option value={1}>
                      Course Certificate
                    </option> */}
                    <option value={2}>
                      Participation Certificate
                    </option>
                    <option value={3}>
                      Winning Certificate
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      {/* <div className='GenSalary'>
        <button onClick={() => generateCertificatePdf()}>Download Certificate </button>
      </div> */}
      <div className='GenSalary'>
        <button onClick={downloadAllCertificates}>Download All Certificates</button>
      </div>
      {/* Download Email */}
      <div className='GenSalary'>
        <button onClick={emailAllCertificates}>Sent mails to all </button>
      </div>

      {/* Footer */}
      <div style={{ padding: "3%" }}>
        {selectedStudentDetails && (<div style={{ width: "1600px", height: "1120px", position: "relative",margin:"0" }} ref={targetRef}>
          {/* <div style={{background:`url(${LebronStretch})`,height:"100%",width:"100%",objectFit:"cover"}}>  */}
          <img src={LebronStretch} style={{ height: "100%", width: "100%", objectFit: "cover" }} />

          <div style={{ position: "absolute", top: "2.25%", left: "70%", fontWeight: "bolder", fontSize: "1.5rem", color: "purple" }}>
            Certificate # {certificateNumber}
          </div>

          <div style={{ position: "absolute", top: "40%", textAlign: "center", width: "100%", fontSize: "40px", color: "white", fontWeight: "bold" }}>
            {selectedStudentDetails.firstname.toUpperCase()}
          </div>
          <div style={{ position: "absolute", top: "51%", padding: "0 15%", textAlign: "center", width: "100%", fontSize: "22px", color: "purple", fontWeight: "500" }}>
            {certificateType ? <> C/O <b>{selectedStudentDetails?.parentname}</b> {textAreaValue}</> : <>{textAreaValue}</>}

          </div>
          {/* <div style={{ position: "absolute", top: "60%",padding:"0 15%", textAlign: "center", width: "100%", fontSize: "22px", color: "purple", fontWeight: "500" }}>
           {certificateType==1 ?<> S/O, D/O <b>{selectedStudentDetails?.parentname}</b> {textAreaValue}</>: <>{textAreaValue}</>} 

          </div> */}
          <img src={cLogo} style={{ height: "22%", width: "20%", position: "absolute", top: "56%", left: "50%", transform: "translateX(-50%)" }} />


          <div style={{ position: "absolute", top: "81%", left: "62%", fontWeight: "500", fontSize: "24px", color: "purple" }}>
            {currentDate}
          </div>
          <img src={Signature} style={{ height: "10%", width: "15%", position: "absolute", top: "77%", left: "25%" }} />


          {/* </div> */}
        </div>)}
      </div>
      <Footer />

    </>
  );
}

export default CreateCertificate2;