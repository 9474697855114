import React,{useContext} from "react";
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "./Slider.css";
import "./MathCourseDetails.css";
import "./MathResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import TeacherNav from "../Navbars/TeacherNav";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import compassImg from './MathCourseDetailImages/Compass.png'
import FeeStructure from "../components/FeeStructure";
import Abacus from './MathCourseDetailImages/Abacus.png'

const MathCourseDetails = ({selectedCourse}) => {


  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
      const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
  return (
    <>
      <div >
          {/* Navbar */}
          {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 
        <div className="MathPortalBackground">
          <section className="section">
            <p className="PageHead" style={{ color: "#8b0194" }}>Brain Train Abacus</p>
            <div className="PageDesc">
            <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
                The Abacus is usually associated with learning arithmetic
                operations only BUT at POLYMATH-KIDS we use arithmetic and
                numbers as tools to help kids focus and concentrate. Learning to
                use Abacus from an early age helps in activating brain, tapping
                intelligence, enhancing ideation smartness and making accurate
                calculation without using calculator. The kids show high
                self-confidence while learning our Brain Train Abacus Program.
                The main purpose of the program is to help kids use their brain
                efficiently and learn at a faster pace.
                
              </p>
              <img style={{minWidth:"250px", maxWidth:"450px"}} src={Abacus}></img>
              </div>
              <div className="btn-row d-flex btn-row-resp btn-row-resp1">

                {/* <div className="btn-row-resp"> */}
                <div style={{ width: "auto", height: "50px" }}>


                  <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
                </div>
                <div style={{ width: "auto", height: "50px" }}>

                  <a className=" btn-row-resp button btn-color-portal" href="#portal">Portal Highlights</a>

                </div>

                <div style={{ width: "auto", height: "50px" }}>
                  <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

                </div>
                <div style={{ width: "auto", height: "50px" }}>

                  <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
                </div>
                <div style={{ width: "auto", height: "50px" }}>
                  <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


                </div>
                <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                 

                </div>


           
              </div>
            </div>

          </section>
          <div className="overlay"></div>
        </div>


        {/* Course Structure and Syllabus */}
        <section className="section course-structure" id="course">
          <p className="PageHead" style={{ color: "#8b0194" }}>
            Course Structure and Syllabus
          </p>
          <div className="compass-image">
            <img src={compassImg} alt="compassImage" className="compassRespImg"/>
          </div>
          <div>
            <p
              style={{
                color: "#d67fb2",
                marginTop: "3vh",
                fontSize: "2em",
                fontFamily: "VanillaMilk",
              }}
            >
              Foundation Level Course
            </p>
          </div>

          {/* Module one */}
          <div className="modules">
            {/* round box */}
            <div className="roundbox">
              <p style={{ marginTop: "5vh", color: "#fad5f1" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox" style={{ color: "#d67fb2" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox" style={{ color: "#d67fb2" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Abacus
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Rules, Addition and Subtraction of numbers up to 99
                </p>
              </p>
            </div>
          </div>

          {/* Module  two */}
          <div className="modules" style={{ marginTop: "2vh" }}>
            {/* round box */}
            <div className="roundbox">
              <p style={{ marginTop: "5vh", color: "#fad5f1" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox" style={{ color: "#d67fb2" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox" style={{ color: "#d67fb2" }}>
              <p className="my-4" style={{ margin: "auto" }}>
                Addition and Subtraction of numbers up to 999
              </p>
            </div>
          </div>

          {/* Module 3 */}
          <div>
            <p
              style={{
                color: "#5e6fab",
                marginTop: "3vh",
                fontSize: "2em",
                fontFamily: "VanillaMilk",
              }}
            >
              Beginners Level Course
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="roundbox roundbox1 ">
              <p style={{ marginTop: "5vh", color: "#c7cff5" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox1 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Multiplication
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  2 Digit * 1 Digit, Addition and Subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 4 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox1 ">
              <p style={{ marginTop: "5vh", color: "#c7cff5" }}>
                {" "}
                Module
                <br />
                4
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox1 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Division
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  2 Digit/1 Digit and 3 Digit/1 Digit, Multiplication 3 Digit *
                  1 Digit, Addition and Subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 5 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox1 ">
              <p style={{ marginTop: "5vh", color: "#c7cff5" }}>
                {" "}
                Module
                <br />
                5
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox1 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox1" style={{ color: "#5e6fab" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Decimal number
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Division 4 Digit/1 and 2 digit and 3 Digit/ 2 digit,
                  Multiplication 2 Digit * 2 Digit, Multiplication 3 Digit * 1
                  Digit, Addition and subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 6 */}
          <div>
            <p
              style={{
                color: "#eca222",
                marginTop: "3vh",
                fontSize: "2em",
                fontFamily: "VanillaMilk",
              }}
            >
              Advance Level Course
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="roundbox roundbox2">
              <p style={{ marginTop: "5vh", color: "#faf0d0" }}>
                {" "}
                Module
                <br />
                6
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox2 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Negative numbers
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Decimal number addition and subtraction, Division 3 Digit/ 2
                  digit, Multiplication 3 Digit * 2 Digit, Multiplication 4
                  Digit * 2 Digit, Addition and subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 7 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox2">
              <p style={{ marginTop: "5vh", color: "#faf0d0" }}>
                {" "}
                Module
                <br />
                7
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox2 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Percentages
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Negative numbers double digit, Decimal number addition and
                  subtraction, Division 4 Digit/ 2 digit, Multiplication 3 Digit
                  * 2 Digit, Multiplication 4 Digit * 2 Digit, Addition and
                  subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 8 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox2">
              <p style={{ marginTop: "5vh", color: "#faf0d0" }}>
                {" "}
                Module
                <br />
                8
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox2 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Introduction of Square root
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Adding Percentages to a number, Negative numbers triple
                  digits, Decimal number addition and subtraction, Division
                  reinforcement Multiplication reinforcement, Addition and
                  subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 9 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox2">
              <p style={{ marginTop: "5vh", color: "#faf0d0" }}>
                {" "}
                Module
                <br />
                9
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox2 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Introduction of GCD/ HCF
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  Practicing Square roots,Adding Percentages to a number,
                  Negative numbers triple digits, Decimal number addition and
                  subtraction, Division reinforcement Multiplication
                  reinforcement, Addition and subtraction of higher numbers
                </p>
              </p>
            </div>
          </div>

          {/* Module 10 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="roundbox roundbox2">
              <p style={{ marginTop: "5vh", color: "#faf0d0" }}>
                {" "}
                Module
                <br />
                10
              </p>
            </div>
            {/* small boxes */}
            <div className="smallbox smallbox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Duration
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3 months
                </p>
              </p>

              <div
                className="smallbox smallbox2 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Classes
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    12
                  </p>
                </p>
              </div>
            </div>

            <div className="largebox largebox2" style={{ color: "#eca222" }}>
              <p style={{ margin: "auto" }}>
                Introduction of LCM
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "14px",
                    marginBottom: "0px",
                  }}
                >
                  Practicing GCD/ HCF, Practicing, Square roots, Percentages
                  reinforcement, Negative numbers triple digits, Decimal number
                  addition and subtraction, Division reinforcement
                  Multiplication reinforcement, Addition and subtraction of
                  higher numbers
                </p>
              </p>
            </div>
          </div>
        </section>


        {/* Benefits */}
        <div style={{ backgroundColor: '#f1ffff' }} >
          <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
            How students can benefit from Abacus
          </p>

          <div className="container" >

            <MDBRow>
              <MDBCol md='8'>
                <div className="benefit-img b-img1 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">
                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>
                      <h3 className="benefit-title ">Whole Brain development</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='3'>
                <div className="benefit-img b-img2 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">
                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title " >Proficiency in Mathematics</h3>
                    </div>
                  </Link>
                </div>

              </MDBCol>
              <MDBCol md='8'>
                <div className="benefit-img b-img3 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Develops speed and accuracy</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='3'>
                <div className="benefit-img b-img4 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>
                      <h3 className="benefit-title ">Personality development</h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='8'>
                <div className="benefit-img b-img5 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">
                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Improves Concentration, Observation and Memory</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='3'>
                <div className="benefit-img b-img6 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">
                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Enriches Visual and listening skill </h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='8'>
                <div className="benefit-img b-img7 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Boosts Confidence and Problem Solving Ability</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='3'>
                <div className="benefit-img b-img8 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Enhances spatial ability and logical understanding</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='8'>
                <div className="benefit-img b-img9 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Sharpens Cognitive skills</h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='3'>
                <div className="benefit-img b-img10 col-xs-6">
                  <Link to="/abacus_benefits" className="benefitLink">

                    <div style={{ paddingTop: "1em", marginLeft: "3em" }}>

                      <h3 className="benefit-title ">Supports dyslexic / dyscalculia children </h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </div>


        </div>

        {/* Highlights */}
        <div style={{ marginTop: '2em', backgroundColor: '#f1ffff' }} id="portal">
          <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
            Our Abacus Portal Highlights
          </p>

          <div className="container" >

            <MDBRow>
              <MDBCol md='2'>
                <div className="highlight-img h-img1 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">

                      <h3 className="highlight-title ">Whole Brain development</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img2 my-3  col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">

                      <h3 className="highlight-title" >Proficiency in Mathematics</h3>
                    </div>
                  </Link>
                </div>

              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img3 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">

                      <h3 className="highlight-title">Develops speed and accuracy</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img4 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Personality development</h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img5 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Improves Concentration, Observation and Memory</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img6 my-3  col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Enriches Visual and listening skill </h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img7 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Boosts Confidence and Problem Solving Ability</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img8 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Enhances spatial ability and logical understanding</h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img9 my-3  col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Sharpens Cognitive skills</h3>
                    </div>
                  </Link>

                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img10 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Supports dyslexic / dyscalculia children </h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img11 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Supports dyslexic / dyscalculia children </h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='2'>
                <div className="highlight-img h-img12 my-3 col-xs-6">
                  <Link to="/abacus_learning_portal">
                    <div className="highlightTitleSection">
                      <h3 className="highlight-title">Supports dyslexic / dyscalculia children </h3>
                    </div>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </div>


        </div>

        {/* Fee Structure */}
        <FeeStructure subject="Abacus"/>

        {/* Testimonals */}
        <div class="  background" id="testimonal">
          <div class="row" style={{ width: "100%" }}>
            <div class="col-lg-8 mx-auto">
              <h2 className="Testimonal_Heading">Testimonials</h2>
              <div id="myCarousel" class="carousel slide" data-ride="carousel">
                {/* <!-- Carousel indicators --> */}
                <ol class="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                </ol>
                {/* <!-- Wrapper for carousel items --> */}
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="img-box">
                      <div className=" img-box1"></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I enjoyed the learning experience with EduTechSage-Abacus.
                      The teacher was very friendly. I highly recommend
                      EduTechSage-Abacus.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Mujtaba Khawaja Sahaf</b>
                    </p>
                  </div>

                  <div class="carousel-item">
                    <div class="img-box">
                      <div className="img-box2 "></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I feel so proud when my class fellows call me ’BRAINIAC ’,
                      because I can effortlessly do mental calculations much
                      faster and accurate than my class mates. Thank you
                      Polymath-Kids.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Wahaab Omer</b>, Age 9 Years
                    </p>
                  </div>
                </div>
                {/* <!-- Carousel controls --> */}
                <a
                  class="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i class="fa fa-angle-left"></i>
                </a>
                <a
                  class="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Faqs */}
        <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

          <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
            <MDBAccordion alwaysOpen initialActive={1} >
              <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
              <MDBAccordionItem collapseId={1} headerTitle="Q: How can I learn Abacus online?" >
                <strong>A: </strong>
                Polymath-Kids  has introduced a unique, interactive portal with which anyone can easily master Abacus skills and practice brain exercises to achieve full potential.
              </MDBAccordionItem>
              <MDBAccordionItem collapseId={2} headerTitle="Q: What are the advantages of learning Abacus Online?">
                <strong>A: </strong>

                The advantages of learning abacus online are that one does not need to travel to the physical classes. Students can learn from anywhere around the globe and practice at their own convenient time.
              </MDBAccordionItem>


              <MDBAccordionItem collapseId={3} headerTitle="Q: Will the outcome be same as classroom Abacus Learning?">
                <strong>A: </strong>
                Yes, the result achieved in Online learning will be same as offline learning a child does by attending classes. Rather the ability of the student to practice timed questions for every activity provides added benefits to the students.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What way is the Online Abacus Brain Training different from Offline classes?">
                <strong>A: </strong>
                The only difference in the Online way of leaning abacus is that there is no physical face-to-face interaction with the teacher. Instead there will be weekly class with trained instructors. The use of technology and interactive portal makes it easy for every student to learn in their personalized way and keep track of their activities through portal generated reports.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: How can I go through the exam process for each level?">
                <strong>A: </strong>
                After going through all the contents of a particular module, the student will be assigned the exam activity. They will appear for exam in the same manner as they have practiced activities throughout the module.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What are Anzan cards?">
                <strong>A: </strong>
                Anzan is a method where numbers flash on the screen with the speed that is appropriate for the student level. Students have to calculate the numbers as appearing on the screen and type in their answer within the given time, to get real time feedback. In order to provide correct answers, student has to focus on the numbers appearing on the screen, remember them, visualize them on their mental abacus and finally calculate the answer with speed and accuracy.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: How should my child start abacus learning process using the Online portal?">
                <strong>A: </strong>
                In Online Learning Portal the contents are arranged in a structured manner. The child would start from Module 1 going through all activities assigned for each week. They have to watch assigned videos, play games, attempt timed written questions and Listening and Mental activities each week. Learning abacus requires practice and patience.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What do you mean by structured curriculum?">
                <strong>A: </strong>
                To gain the potential benefits, Abacus has to be learned in a structured and dedicated way. Our program is divided into 10 modules considering child behavior and best learning techniques. Following a structured approach provides a smooth road to exemplary cognitive development of the child.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: How do you ship the real rod Abacus?">
                <strong>A: </strong>
                We only ship abacus for students living in Pakistan. For other countries, abacus can be purchased through Amazon or any other available option. Every student need to have a Japanese Abacus with 17 rods to start the course.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: My child has no prior knowledge of Abacus. Can I get my child enrolled?">
                <strong>A: </strong>
                To join our program, there is no previous knowledge of Abacus required. We start from the very basics and the child will find it very easy and interesting.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What are the contents in the abacus portal?">
                <strong>A: </strong>
                Polymath-Kids Online Brain Train Abacus Portal consist of Abacus lessons, Animated explanations, Anzan practice videos, Unlimited Timed Practice sheets, Audio practice activities and related games.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: How many modules are there?">
                <strong>A: </strong>
                There are 10 modules in the Polymath-Kids Online Brain Train Abacus Portal. Each module consists of 12 lessons.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Does the portal support on mobile phones?">
                <strong>A: </strong>
                The portal is a web-based learning application and not a dedicated mobile app. Yet you can access the portal through mobile or IPad.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Is there any exam/ assessment process?">
                <strong>A: </strong>
                There is an exam after each module. It is required for the student to achieve atleast 80% marks in the exam in order to move to the next module.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Would I be given any certificates?">
                <strong>A: </strong>
                Yes, there will be E-certificates given after each course.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: I need to know the contents of the portal before purchasing the Abacus course. Is there any option available for that?">
                <strong>A: </strong>You can register for a demo class. Our expert instructor will be available to answer any queries and demonstrate the portal features.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: I have already completed 1 module of Abacus, can I start from the 2nd module from the portal?">
                <strong>A: </strong>No, the contents are incorporated in a structured manner, so one has to start from Module 1 itself, attend the exam and then move to the next modules, one-by-one.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Are there any books provided along with the portal?">
                <strong>A: </strong>No. There are no physical books provided.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: In the one to one class, how will be the online assistance conducted?">
                <strong>A: </strong>
                1 on 1 Instructor Led class is through the in-build video conferencing feature of the Portal. The date and the time for the session would be decided mutually.
              </MDBAccordionItem>


              <MDBAccordionItem collapseId={3} headerTitle="Q: How many hours should the child practice to get the mental math skills?">
                <strong>A: </strong>
                Daily practice of at least half an hour is needed to master abacus calculations. Most of our students start doing mental abacus calculations after first two classes.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: If a student successfully clears the exam, can he reset the exam for a better score?">
                <strong>A: </strong>
                Yes, If the student is unable to score 80% marks in the exam, he will be allowed to retake the exam on another day.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Will a student be able to review the lower module at any time? or will the current module be locked out after getting into higher module?">
                <strong>A: </strong>
                Once the student clears the current level exams, the contents of the next module are automatically accessible. As one moves to the higher modules, the lower module content is not required as they move on and practice the higher numbers.
              </MDBAccordionItem>

            </MDBAccordion>
          </MDBContainer>

        </div>

{/* Footer */}
<Footer/>



      </div>
    </>

  );
};

export default MathCourseDetails;
