import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav'; 
import Footer from '../Footer';
import './TeacherSalary.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const TeacherSalary = () => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState('');
    
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [year, setYear] = useState();

    const [courses, setCourses] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [teacherClasses, setTeacherClasses] = useState([]);
    const [demo1Count, setDemo1Count] = useState('');
    const [demo1Rate, setDemo1Rate] = useState('');
    const [demo2Count, setDemo2Count] = useState('');
    const [demo2Rate, setDemo2Rate] = useState('');
    // const [totalSalary, setTotalSalary] = useState();

    let total1;let total2;let totalSalary;
    
    // Function to calculate total
    const calculateTotal = () => {
         total1 = Number(demo1Count) * Number(demo1Rate);
        return total1 ;
    };
    const calculateTotal2 = () => {
         total2 = Number(demo2Count) * Number(demo2Rate);
        return total2 ;
    };
    const calculateTotal3 = () => {
        totalSalary=total1+total2;
        return totalSalary ;
   };
  
    // Function to handle input change and update state
    const handleInputChange = (setter, value) => {
        // Ensure the value is a valid number
        if (!isNaN(value)) {
            setter(value);
        }
    };

   
    const generateSalary = () => {
        if (!selectedTeacher || !selectedMonth || !selectedCurrency || !year) {
            alert('Please fill in all fields');
            return;
        }
        const data = {
            teacher_id: selectedTeacher.teacher_id,
            Currency: selectedCurrency,
            Month: selectedMonth,
            Paid: 0,  
            SalaryAmount: totalSalary, 
            Year: year
        };

        // Make the API call
        axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveTeacherSalary`, data)
            .then(response => {
                console.log('Salary generated successfully:', response.data);
                toast.success('Salary generated successfully'); // Show success toast
                fetchTeacherSalaries(selectedTeacher.teacher_id)

            })
            .catch(error => {
                console.error('Error generating salary:', error);
                toast.error('Error generating salary'); // Show error toast
            });
    };
    const [teacherSalaries, setTeacherSalaries] = useState([]);

    // Function to fetch data from the API
    const fetchTeacherSalaries = async (teacherId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherSalaries/${teacherId}`);
            // 
            // const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllTeacherSalaries`);

            setTeacherSalaries(response.data.teacherSalaries);
        } catch (error) {
            console.error('Error fetching teacher salaries:', error);
        }
    };
    const handleGenerateSalary = () => {
        generateSalary(); // Call generateSalary within an event handler
        // fetchTeacherSalaries(selectedTeacher.teacher_id)
        // fetchTeacherSalaries()
    };

    const fetchAllCourses=()=>
    {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
        .then(response => {
          const coursesData = response.data.courses;
          setCourses(coursesData);
        })
        .catch(error => {
          console.log('Error fetching courses:', error);
        });
    }
    const fetchAllTeachers = (Course_id) => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllTeacherForACourse`, {
          params: {
            Course_id: Course_id // Pass Course_id as a query parameter
          }
        })
        .then(response => {
          const teachersData = response.data.teachers;
        //   console.log("teachersData:", teachersData);
          setTeachers(teachersData);
        })
        .catch(error => {
          console.log('Error fetching teachers:', error);
        });
    };
    const fetchTeacherClasses = (teacherId) => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherTaughtClasses/${teacherId}`)
          .then(response => {
            const data = response.data;
            setTeacherClasses(data.teacherClasses);
            console.log("data.teacherClasses:",data.teacherClasses);
          })
          .catch(error => {
            console.log('Error fetching classes details', error);
          });
    }
    const handleCourseChange = (event) => {
        const { value } = event.target;
        const selectedCourse = courses.find((course) => course.Course_id === parseInt(value));
        setSelectedCourse(selectedCourse);
        fetchAllTeachers(selectedCourse.Course_id)
        // console.log("selectedCourse:",selectedCourse.Course_id)

      };

    const handleTeacherChange = (event) => {
        const { value } = event.target;
        const selectedTeacher = teachers.find((teacher) => teacher.teacher_id === parseInt(value));
        setSelectedTeacher(selectedTeacher);
        fetchTeacherClasses(selectedTeacher.teacher_id)
        // fetchEducationData(selectedTeacher.teacher_id)
        // fetchExperienceData(selectedTeacher.teacher_id)
        // fetchBankDetails(selectedTeacher.teacher_id)
        // fetchAvailability(selectedTeacher.teacher_id)
        // fetchTeacherCourses(selectedTeacher.teacher_id)
        // // console.log("selectedTeacher.teacher_id:",selectedTeacher.teacher_id)

      };
    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];

    const currencies = [
    { code: 'USD', symbol: '$', name: 'United States Dollar' },
    { code: 'EUR', symbol: '€', name: 'Euro' },
    { code: 'GBP', symbol: '£', name: 'British Pound Sterling' },
    { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
    { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
    { code: 'INR', symbol: '₹', name: 'Indian Rupee' },
    { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
    { code: 'CAD', symbol: 'CA$', name: 'Canadian Dollar' },
    { code: 'SGD', symbol: 'S$', name: 'Singapore Dollar' },
    { code: 'CHF', symbol: 'CHF', name: 'Swiss Franc' },
    { code: 'PKR', symbol: '₨', name: 'Pakistani Rupee' },
    { code: 'SAR', symbol: '﷼', name: 'Saudi Riyal' },
    { code: 'AED', symbol: 'د.إ', name: 'UAE Dirham' },
    ];
    useEffect(() => {
        if (selectedTeacher) {
            fetchTeacherSalaries(selectedTeacher.teacher_id);
        }
    }, [selectedTeacher]); // Trigger the effect whenever teacherId changes
  
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
           //  console.log("Response:",response);
          response.json().then(userInfo => {
           //  console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
            fetchAllCourses()
            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
         //  console.log("Uname Admin Register", uname);
         //  console.log("Avatar in: ", avatar);
   
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      // fetchTeacherSalaries()
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
    const onLogin = (usertype) => {
         
        setUserRole(usertype);
      };
    const navigate=useNavigate();
    
    async function onLogout() {
        console.log("inside Onlogout.");
    await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
    }

    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
  return (
    <>
        {/* Navbar */}
    {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}
          </>
        )}
        <div className='edit-bar'>
        <h3 className='student-EditHeading'>
            Teachers Salary
        </h3>
        </div>

        <div className='TeacherFilterSection'>
            <div className='TeacherFilter'>
                <select id='TPcourseDropdown'
                    value={selectedCourse ? selectedCourse.Course_id.toString() : ''} 
                    onChange={handleCourseChange}>
                        <option value=''>Select Course</option>
                        {courses.map((course) => (
                        <option key={course.Course_id} value={course.Course_id}>
                            {course.coursename}
                        </option>
                        ))}
                </select>
                <select
                    id='TPteacherDropdown'
                    value={selectedTeacher ? selectedTeacher.teacher_id.toString() : ''} 
                    onChange={handleTeacherChange}
                    >
                    <option value=''>Select Teacher</option>
                        {teachers.map((teacher) => (
                        <option key={teacher.teacher_id} value={teacher.teacher_id}>
                            {teacher.firstname} {teacher.lastname}
                        </option>
                        ))}
                </select>
            </div>
        </div>
        <div className='Salary-Section'>
        <div className='SalaryCal-Section'>
            <div className='SalaryFilter'>
                <div className='Salary-MetaData'>
                    <select
                        id='TPcourseDropdown'
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        <option value='' disabled>Select a Month</option>
                        {months.map((month, index) => (
                        <option key={index} value={month}>
                            {month}
                        </option>
                        ))}
                    </select>

                    <input
                        type="number"
                        id="TPcourseDropdown"
                        placeholder="Year"
                        style={{ width: '10vw', borderRadius: '1vh' }}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        min={0}
                    />

                    <select
                        id='TPcourseDropdown'
                        value={selectedCurrency.code}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                        <option value='' disabled selected>Select a Currency</option>
                        {currencies.map((curr, index) => (
                        <option key={index} value={curr.code}>
                            {curr.code}({curr.symbol})
                        </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='Salary-Demo-Price'>
                <div className='DemoPrice'>
                    <div>
                        <div className='DemoInputs'>
                            <input
                                className='Demo-input'
                                type="number"
                                placeholder='No. of Demos'
                                value={demo1Count}
                                onChange={(e) => handleInputChange(setDemo1Count, e.target.value)}
                            />
                            <b style={{ margin: "0 2% 0 2%" }}>@</b>
                            <input
                                className='Demo-input'
                                type="number"
                                placeholder='Rate'
                                value={demo1Rate}
                                onChange={(e) => handleInputChange(setDemo1Rate, e.target.value)}
                            />
                            <b style={{ margin: "0 2% 0 2%" }}>=</b>
                            <b>
                            {calculateTotal()}
                            </b>
                        </div>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <b style={{ fontSize: '5vh' }}>+</b>
                        </div>
                        <div className='DemoInputs'>
                            <input
                                className='Demo-input'
                                type="number"
                                placeholder='No. of Demos'
                                value={demo2Count}
                                onChange={(e) => handleInputChange(setDemo2Count, e.target.value)}
                            />
                            <b style={{ margin: "0 2% 0 2%" }}>@</b>
                            <input
                                className='Demo-input'
                                type="number"
                                placeholder='Rate'
                                value={demo2Rate}
                                onChange={(e) => handleInputChange(setDemo2Rate, e.target.value)}
                            />
                            <b style={{ margin: "0 2% 0 2%" }}>=</b>
                            <b>
                            {calculateTotal2()}
                            </b>
                        </div>
                    </div>
                    <div className='Teacher-Classes'>
                        <b>CLASSES</b>
                        {teacherClasses.map((classItem, index) => (
                        <div key={index}>
                            {/* <p>Class Name: {classItem.classname}</p> */}
                            <p>{classItem.ClassID}</p>
                            {/* <p>Class Link: <a href={classItem.ClassLink}>{classItem.ClassLink}</a></p> */}
                            <hr />
                        </div>
                        ))}


                    </div>
                </div>
                        <b style={{fontSize:'8vh',margin:"5%"}}>=</b>
                    <div className='DemoTotal'>
                        <b>
                            {selectedCurrency ? `${selectedCurrency} ${calculateTotal3()}` : '0'}
                        </b>
                    </div>
            </div>
                <div className='GenSalary'>

                <button className='SalaryBtn' onClick={handleGenerateSalary} >Generate Salary</button>
                </div>
        </div>
   
        </div>   
        
        <div className='SAL-Teacher'>
  <h2>Teacher Salaries</h2>
  <table className="salary-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Month</th>
        <th>Year</th>
        <th>Currency</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      {teacherSalaries.map((salary) => (
        <tr key={salary.Teacher_Salary_ID}>
          <td>{salary.firstname} {salary.lastname}</td>
          <td>{salary.Month}</td>
          <td>{salary.Year}</td>
          <td>{salary.Currency}</td>
          <td>{salary.SalaryAmount}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        <Footer/>
    </>
  )
}

export default TeacherSalary
