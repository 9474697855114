import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {Link} from 'react-router-dom'
import Logo from '../CourseImages/Logo.png'
import './Nav.css'


const ViewActivityModal = ({ isOpen, onClose, lessonActivityID }) => {

  const [activityDetails, setActivityDetails] = useState('');
  const [activityName, setActivityName] = useState('');


  useEffect(() => {

    console.log("In UseEffect...")


    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityName/${lessonActivityID}`)
      .then((response) => {
        console.log('Activity Name:', response.data.activityName[0].ActivityName);
        setActivityName(response.data.activityName[0].ActivityName);
      })
      .catch((error) => {
        console.error('Error fetching getActivityDetails:', error);
      });



    console.log("Getting all the Lessons against that Course ID");
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityDetails/${lessonActivityID}`)
      .then((response) => {
        console.log('Activity Details:', response.data.activityDetails);
        setActivityDetails(response.data.activityDetails);
      })
      .catch((error) => {
        console.error('Error fetching getActivityDetails:', error);
      });



  }, []);


    // const selectedCourseData = classes.find((course) => course.Course_id === selectedCourse) || {};
    // const [selectedClass, setSelectedClass] = useState('');  
    // const [selectedClassLink, setSelectedClassLink] = useState('');

    // const handleClassSelection = (classId) => {
    //   const selectedClassItem = selectedCourseData.classes.find((classItem) => classItem.Class_id == classId);
    //   if (selectedClassItem) {setSelectedClassLink(selectedClassItem.ClassLink);}
    //   setSelectedClass(classId);
    // };
  
    // const handleClose = () => {
    // // console.log("selectedCourseData:",selectedCourseData)
    //   onClose();
    // };
  
    return (
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="modal-content">
          <h3>{activityName}</h3>
          {activityDetails ? (
              <>
              {activityDetails.map((activity, index) => (
                // <p>Q{index+1}: {activity.question} <span style={{marginLeft: "15px"}}>A:{activity.correctanswer}</span></p>
                // <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "30px" }}>
                //   <div>
                //     Q{index + 1}: {activity.question}
                //   </div>
                //   <div>
                //     A: {activity.correctanswer}
                //   </div>
                // </div>



                <p style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "30px" }}>
                  <span style={{ textAlign: "left" }}>
                    Q{index + 1}: {activity.question}
                  </span>
                  <span style={{ textAlign: "right" }}>
                    A: {activity.correctanswer}
                  </span>
                </p>
                
              ))}
              </>
            ) : (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="course">
                <option value="">No Lessons available</option>
              </select>
            )}


        </div>
      </div>
    );
  };
  
export default ViewActivityModal;
