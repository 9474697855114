import React from "react";
import { useState, useEffect ,useContext} from "react";
import { Link } from "react-router-dom";

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav' 
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';

import { UserContext } from '../UserContext';


import Footer from '../Footer'
import "./CommunicationCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";

import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CommunicationImages/communicationBackground.png";
import pic2 from "./CommunicationImages/CPS.png"; 
import pic3 from "./CommunicationImages/Declamation.png"; 
import pic4 from "./CommunicationImages/Debate.png"; 
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from "../components/FeeStructure";
import CCD from './CommunicationImages/communicationBackground.png'


const CommunicationCourseDetails = ({selectedCourse}) => {
    const faqData = [
        {
          question: "What is a communication skills course?",
          answer: "A communication skills course is a program designed to help individuals improve their verbal, non-verbal, and written communication abilities. It covers various aspects of effective communication, such as public speaking, active listening, interpersonal skills, and more."
        },
        {
          question: "Who can benefit from a communication skills course?",
          answer: "Communication skills courses are beneficial for anyone looking to enhance their communication abilities."
        },
        {
          question: "How long is the course, and what's the format?",
          answer: "The course duration and format can vary. We offer both one to one and group classes, and the duration will vary according to your skills."
        },
        {
          question: "Do I receive a certificate upon completion of the course?",
          answer: "Yes, participants who successfully complete our communication skills course will receive a certificate of completion, which can be a valuable addition to your resume or professional portfolio."
        },
        {
          question: "What are the benefits of improving communication skills?",
          answer: "Improved communication skills can lead to better career prospects, increased confidence, and overall personal and professional growth. Effective communication is a crucial skill in almost every aspect of life."
        },
        {
          question: "How do I register for a course?",
          answer: "To register for a course, please visit our website and follow the registration instructions provided on the course page. You can also contact our support team for assistance."
        },
        {
          question: "Are there any online resources or materials provided with the course?",
          answer: "Yes, we provide course materials, resources, and access to our online platform for interactive learning. You will receive details on accessing these materials upon enrollment."
        },
        {
          question: "What would be the method of teaching, recorded lectures or live classes?",
          answer: "We provide online live sessions."
        }
      ];
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
          const username = userInfo?.username;
          const _avatar_ = userInfo?.avatar;
  return (
    
    <div  >
        
    {/* Navbar */}
    {username && (
     <>
     {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
     {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
   </>
     )}  
     {!username && (
       <>
       {isGuest && <GuestNav onLogin={onLogin} />}

       </>
     )}
       
   <div className="CommunicationPortalBackground">
      <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}>COMMUNICATION SKILLS PORTAL</p>
        <div className="PageDesc">
        <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            Communication Skills for Kids is a comprehensive and engaging program designed to empower children with essential communication skills. This course is tailored to help kids develop effective verbal, non-verbal, and interpersonal communication abilities, equipping them with the tools they need to express themselves confidently, interact with others harmoniously, and succeed in various aspects of their lives.
          </p>
          <img style={{minWidth:"250px", maxWidth:"450px"}} src={CCD}></img>
          </div>
          <div className="btn-row d-flex btn-row-resp btn-row-resp1">
            {/* <div className="btn-row-resp"> */}
            <div style={{color:"#e2e872", width: "auto", height: "50px" }}>


              <a className="btn-row-resp button " href="#course">Individual Courses</a>
            </div>
            {/* <div style={{ width: "auto", height: "50px" }}>


              <a className="btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>
            </div> */}
            
            <div style={{ width: "auto", height: "50px" }}>

              <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


            </div>
            <div style={{ width: "auto", height: "50px" }}>
            <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
             

            </div>


       
          </div>
        </div>

      </section>
      <div className="overlay"></div>
    </div>
    

{/* Individual courses */}
<div style={{ backgroundColor: '#d8f2fb' }} id="course">
      <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
    Communication Skills Individual courses
      </p>

      <div className="container"  >

        <MDBRow>
          <MDBCol md='4'>
            <div className=" col-xs-6">
              <Link to="/comm_comm_skills">
                <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>
                  <h3 className="ind-course-title ">Communication Skills</h3>
              <div>
                    <img src={pic2} alt="" style={{width:"100px",height:"100px"}}/>
                </div>
                
                   </div>
               
              </Link>
            </div>
            </MDBCol>
           <MDBCol md='4'>
            <div className=" col-xs-6">
              <Link to="#">
                <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>
                  <h3 className="ind-course-title ">Declamation & Oratory Skills (Coming Soon)
                  </h3>
              <div>
                    <img src={pic3} alt="" style={{width:"100px",height:"100px"}}/>
                </div>
                
                   </div>
               
              </Link>
            </div>
            </MDBCol>
           <MDBCol md='4'>
            <div className=" col-xs-6">
              <Link to="#">
                <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>
                  <h3 className="ind-course-title ">Argumentation & Debate (Coming Soon)</h3>
              <div>
                    <img src={pic4} alt="" style={{width:"100px",height:"100px"}}/>
                </div>
                
                   </div>
               
              </Link>
            </div>
          </MDBCol>
        </MDBRow>
      </div>


    </div>


   {/* Fee Structure */}
   {/* <FeeStructure/> */}

   

    {/* Faqs */}
    <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>FAQs</div>
          {faqData.map((faq, index) => (
            <MDBAccordionItem key={index} collapseId={index + 1} headerTitle={`Q: ${faq.question}`}>
              <strong>A: </strong>
              {faq.answer}
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      </MDBContainer>
    </div>

{/* Footer */}
<Footer/>

</div>
  )
}

export default CommunicationCourseDetails
